import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Base/Table";
import { DEFAULT_PAGINATION_SIZE } from "@/lib/const";
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  SortingState,
  ColumnFiltersState,
  VisibilityState,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { Dispatch, SetStateAction, useState } from "react";
import { Spinner } from "@/components/Common/Spinner";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/Base/DropdownMenu";
import { ArrowUpDownIcon, FilterIcon } from "lucide-react";
import { Button } from "@/components/Base/Button";

export interface TableLayoutState {
  pagination?: Omit<PaginationState, "pageIndex">;
  sorting?: SortingState;
  columnFilters?: ColumnFiltersState;
  columnVisibility?: VisibilityState;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  rowCount?: number;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  userDefaults?: TableLayoutState;
  factoryDefaults?: TableLayoutState;
  onLayoutSave?: (state: TableLayoutState) => void;
  onLayoutReset?: () => void;
  layoutSaveStrategy?: "automatic" | "user";
  loading?: boolean;
  sorting?: SortingState;
  setSorting?: Dispatch<SetStateAction<SortingState>>;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  rowCount,
  pagination,
  setPagination,
  userDefaults = {
    pagination: { pageSize: DEFAULT_PAGINATION_SIZE },
    sorting: [],
    columnFilters: [],
    columnVisibility: { id: false },
  },
  factoryDefaults = {
    pagination: { pageSize: DEFAULT_PAGINATION_SIZE },
    sorting: [],
    columnFilters: [],
    columnVisibility: { id: false },
  },
  loading,
  sorting,
  setSorting,
  onLayoutSave,
  onLayoutReset,
  layoutSaveStrategy = "user",
}: DataTableProps<TData, TValue>) {
  const [defaultPagination, setDefaultPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: userDefaults.pagination?.pageSize ?? 10,
  });
  const [defaultSorting, setDefaultSorting] = useState<SortingState>(
    userDefaults.sorting ?? []
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    userDefaults.columnFilters ?? []
  );
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    userDefaults.columnVisibility ?? {}
  );

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination ? setPagination : setDefaultPagination,
    manualPagination: !!pagination,
    getPaginationRowModel: pagination ? undefined : getPaginationRowModel(),
    rowCount: rowCount ?? data.length,
    onSortingChange: setSorting ? setSorting : setDefaultSorting,
    manualSorting: !!sorting,
    getSortedRowModel: sorting ? undefined : getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      pagination: pagination ? pagination : defaultPagination,
      sorting: sorting ? sorting : defaultSorting,
      columnFilters,
      columnVisibility,
    },
    initialState: pagination
      ? {
          pagination: {
            pageIndex: 0, //custom initial page index
            pageSize:
              userDefaults?.pagination?.pageSize ?? DEFAULT_PAGINATION_SIZE, //custom default page size
          },
        }
      : undefined,
  });

  return (
    <div className="w-full">
      <div className="flex items-center py-4">
        <div className="flex-grow" />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-3">
              Columns
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(value)}
                  >
                    <span className="mr-auto">
                      {column.columnDef?.meta?.label ?? column.id}
                    </span>
                    {column.getIsSorted() ? (
                      <ArrowUpDownIcon className="ml-0.5 h-3 w-5" />
                    ) : null}
                    {column.getIsFiltered() ? (
                      <FilterIcon className="ml-0.5 h-3 w-5" />
                    ) : null}
                  </DropdownMenuCheckboxItem>
                );
              })}
            <DropdownMenuSeparator />
            {layoutSaveStrategy === "user" && onLayoutSave && (
              <>
                <DropdownMenuItem
                  className="font-bold"
                  onClick={() =>
                    onLayoutSave({
                      pagination: {
                        pageSize:
                          pagination?.pageSize ?? DEFAULT_PAGINATION_SIZE,
                      },
                      sorting,
                      columnVisibility,
                      columnFilters,
                    })
                  }
                >
                  Save Layout
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="font-bold"
                  onClick={() => {
                    if (setSorting) {
                      setSorting(userDefaults.sorting ?? []);
                    } else {
                      setDefaultSorting(userDefaults.sorting ?? []);
                    }
                    setColumnFilters(userDefaults.columnFilters ?? []);
                    setColumnVisibility(userDefaults.columnVisibility ?? {});
                  }}
                >
                  Restore Last Saved
                </DropdownMenuItem>
              </>
            )}
            {onLayoutReset && (
              <DropdownMenuItem
                className="font-bold"
                onClick={() => {
                  if (setSorting) {
                    setSorting(factoryDefaults.sorting ?? []);
                  } else {
                    setDefaultSorting(factoryDefaults.sorting ?? []);
                  }
                  setColumnFilters(factoryDefaults.columnFilters ?? []);
                  setColumnVisibility(factoryDefaults.columnVisibility ?? {});
                }}
              >
                Restore Factory Default
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {loading ? null : (
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        {loading && (
          <div className="flex flex-row justify-center p-4">
            <Spinner />
          </div>
        )}
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
