import { Button } from "@/components/Base/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Base/Form";
import { Input } from "@/components/Base/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { useStore } from "@/stores/useStore";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { ZodObject, ZodRawShape, z } from "zod";
import { CARD_FIELDS, CardFieldKeys, PatchCardDto, Card } from "@/types/v2";
import { getServiceAdvisorColor } from "@/lib/employees";

const shape: ZodRawShape = {};
CARD_FIELDS.forEach((field) => {
  shape[field.key] = z.string().optional();
});
const formSchema: ZodObject<typeof shape> = z.object({
  cardNumber: z.string().min(1, {
    message: "Repair Order Number must exist",
  }),
  source: z.string().readonly(),
  schemaVersion: z.number().int(),
  serviceWriterId: z.string().optional(),
  technicianId: z.string().optional(),
  totalLaborHours: z
    .object({
      estimatedLabor: z.coerce.number(),
      loggedLabor: z.coerce.number(),
    })
    .optional(),
  promisedTime: z.string().nullable().optional(),
  ...shape,
});

// eslint-disable-next-line no-unused-vars
const defaultValues: { [key in CardFieldKeys]?: string } & Pick<
  Card,
  "totalLaborHours" | "promisedTime"
> = {
  totalLaborHours: {
    estimatedLabor: 0,
    loggedLabor: 0,
  },
  promisedTime: null,
};
CARD_FIELDS.forEach((field) => {
  defaultValues[field.key] = "";
});

export interface CardFormProps<T> {
  cancelButton?: ReactNode;
  submitButton?: ReactNode;
  cardData?: PatchCardDto;
  onSubmit: (values: T) => Promise<void>;
}

export const CardForm = <T,>({
  cancelButton,
  submitButton,
  cardData,
  onSubmit,
}: CardFormProps<T>) => {
  const shop = useStore((s) => s.shop);
  const serviceAdvisors = useStore((s) => s.employees);
  const technicians = useStore((s) => s.employees);

  const formDefaultValues = {
    source: "manual",
    schemaVersion: 2,
    cardNumber: "",
    serviceWriterId: "none",
    technicianId: "none",
    ...defaultValues,
    ...cardData,
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  if (!shop) return null;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(async (data) => await onSubmit(data as T))}
        className="space-y-4"
      >
        <FormField
          control={form.control}
          name="cardNumber"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Repair Order Number</FormLabel>
              <FormControl>
                <Input
                  disabled={form.formState.isSubmitting}
                  placeholder="0001"
                  data-form-type="other"
                  type="text"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    CARD_FIELDS.forEach((cardField) => {
                      if (
                        shop?.cardConfig.cardContents[cardField.key] ==
                        "RO Number"
                      ) {
                        form.setValue(cardField.key, e.target.value, {
                          shouldValidate: true,
                        });
                      }
                    });
                  }}
                  className="text-primary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {CARD_FIELDS.map((cardField) =>
          shop?.cardConfig.cardContents[cardField.key] === "" ? null : (
            <FormField
              key={cardField.key}
              control={form.control}
              name={cardField.key}
              render={({ field }) => (
                <FormItem>
                  <div className="flex flex-row justify-between">
                    <FormLabel>{`${shop?.cardConfig.cardContents[cardField.key] === "" ? "Primary Label" : shop?.cardConfig.cardContents[cardField.key]}`}</FormLabel>
                  </div>
                  <FormControl>
                    <Input
                      placeholder={`Enter the ${shop?.cardConfig.cardContents[cardField.key]}`}
                      data-form-type="other"
                      type="text"
                      {...field}
                      className="text-primary"
                      disabled={
                        shop?.cardConfig.cardContents[cardField.key] ==
                          "RO Number" || form.formState.isSubmitting
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )
        )}

        {/* Add Service Advisor Select */}
        <div className="flex flex-row justify-between">
          {shop?.cardConfig?.cardContents?.manualServiceAdvisorEnabled ===
            undefined ||
          shop?.cardConfig?.cardContents?.manualServiceAdvisorEnabled ? (
            <FormField
              control={form.control}
              name="serviceWriterId" // Make sure this name matches the field in your form
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Service Advisor</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value); // Update form field value
                      }}
                      value={field.value} // Ensure the correct value is displayed
                      disabled={form.formState.isSubmitting}
                    >
                      <SelectTrigger className="w-48">
                        <SelectValue placeholder="Service Advisor" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">No Service Writer</SelectItem>
                        {serviceAdvisors
                          ?.filter((sa) => !sa.hideInShopHero)
                          .map((serviceAdvisor) => (
                            <SelectItem
                              key={serviceAdvisor.id}
                              value={`${serviceAdvisor.id}`}
                            >
                              {/* Circle color next to the name */}
                              <span
                                className="mr-2 inline-block h-2.5 w-2.5 rounded-full"
                                style={{
                                  backgroundColor: getServiceAdvisorColor(
                                    serviceAdvisor.id,
                                    serviceAdvisors || []
                                  ),
                                }}
                              ></span>
                              {serviceAdvisor.firstName}{" "}
                              {serviceAdvisor.lastName}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}

          {/* Add Technician Select */}

          {shop?.cardConfig?.cardContents?.manualTechnicianEnabled ===
            undefined ||
          shop?.cardConfig?.cardContents?.manualTechnicianEnabled ? (
            <FormField
              control={form.control}
              name="technicianId" // Make sure this name matches the field in your form
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Technician</FormLabel>
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value); // Update form field value
                      }}
                      value={field.value} // Ensure the correct value is displayed
                      disabled={form.formState.isSubmitting}
                    >
                      <SelectTrigger className="w-48">
                        <SelectValue placeholder="Technician" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="none">No Technician</SelectItem>
                        {technicians
                          ?.filter((tech) => !tech.hideInShopHero)
                          .map((technician) => (
                            <SelectItem
                              key={technician.id}
                              value={`${technician.id}`}
                            >
                              {technician.firstName} {technician.lastName}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}
        </div>
        <div className="flex flex-row justify-between">
          {shop?.cardConfig?.cardContents?.manualJobHoursEnabled &&
          shop?.cardConfig?.cardContents?.manualCardFormat === "advanced" ? (
            <FormField
              control={form.control}
              name={"totalLaborHours.estimatedLabor"}
              render={({ field }) => (
                <FormItem className={"w-48"}>
                  <div className="flex flex-row justify-between">
                    <FormLabel>Total Labor Hours</FormLabel>
                  </div>
                  <FormControl>
                    <Input
                      type={"number"}
                      placeholder="Enter total hours"
                      {...field}
                      className="text-primary"
                      disabled={form.formState.isSubmitting}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}
          {/* eslint-disable-next-line no-constant-condition */}
          {shop?.cardConfig?.cardContents?.manualPromiseTimeEnabled &&
          shop?.cardConfig?.cardContents?.manualCardFormat === "advanced" &&
          false ? (
            <FormField
              control={form.control}
              name={"promisedTime"}
              render={({ field }) => (
                <FormItem className={"w-48"}>
                  <div className="flex flex-row justify-between">
                    <FormLabel>Promised Time</FormLabel>
                  </div>
                  <FormControl>
                    <Input
                      type={"datetime-local"}
                      placeholder="Enter promised time"
                      {...field}
                      className="text-primary"
                      disabled={form.formState.isSubmitting}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}
        </div>

        <div className="flex flex-row justify-end gap-4">
          {cancelButton ? cancelButton : null}
          {submitButton ? (
            submitButton
          ) : (
            <Button
              variant="primary-alt"
              type="submit"
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? "Saving..." : "Save"}
            </Button>
          )}
        </div>
      </form>
    </Form>
  );
};
