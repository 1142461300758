import { useStore } from "@/stores/useStore";
import { useMemo } from "react";

export const useEmployeeId = () => {
  const user = useStore((s) => s.user);
  const employees = useStore((s) => s.employees);

  const employee = useMemo(
    () => employees?.find((e) => e.shopHeroAuthUid === user?.uid),
    [user, employees]
  );

  const employeeId = useMemo(() => employee?.id, [employee]);

  return { employee, employeeId };
};
