import { TERMS } from "@/lib/terms";

const TermsAndConditionsPage = () => {
  return (
    <div className="mx-auto mb-20 flex max-w-screen-lg flex-col items-center p-5 text-justify">
      <div>
        <h1 className="mb-4 text-center text-2xl">Terms of Service</h1>
        <div dangerouslySetInnerHTML={{ __html: TERMS }} />
      </div>
    </div>
  );
};

export default TermsAndConditionsPage;
