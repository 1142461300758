import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useStore } from "@/stores/useStore";

export const useIsGuest = () => {
  const navigate = useNavigate();
  const auth = useStore((s) => s.authState);

  useEffect(() => {
    switch (auth) {
      case "authenticated":
        navigate("/");
        break;
      case "unverified":
        navigate("/verify");
        break;
      default:
        break;
    }
  }, [navigate, auth]);
};
