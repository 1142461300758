import { useMemo } from "react";
import { useRoles } from "@/hooks/useRoles";
import { useStore } from "@/stores/useStore";
import { useEmployeeId } from "@/hooks/useEmployeeId";
import { layout as layoutRepository } from "@/models/layout";

export const useLayouts = () => {
  const { isSuperAdmin } = useRoles();
  const { employeeId } = useEmployeeId();

  const shop = useStore((s) => s.shop);

  const layouts = useMemo(
    () => layoutRepository.filterForAccess(shop, employeeId, isSuperAdmin),
    [shop, isSuperAdmin, employeeId]
  );

  return { layouts };
};
