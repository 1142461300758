import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import { Employee } from "@/types/v2";
import { Button } from "@/components/Base/Button";
import { employee as employeeActions } from "@/models/employee";
import { captureException } from "@/services/sentry";
import { user as userActions } from "@/models/user";

interface DeleteEmployeeDialogProps {
  shopId: string;
  employee: Employee;
  open: boolean;
  setOpen: (arg: boolean) => void;
}
export const DeleteEmployeeDialog = ({
  employee,
  shopId,
  open,
  setOpen,
}: DeleteEmployeeDialogProps) => {
  const handleDelete = async () => {
    try {
      await userActions.deactivateAuthAccount(employee.email);
      await employeeActions.updateEmployeeRole(shopId, employee.id);
      await employeeActions.delete(shopId, employee.id);
    } catch (error) {
      captureException(error);
    }
    setOpen(false);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>Delete Team Member</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {`You are about to delete ${employee.firstName} ${employee.lastName}. Are you sure you want to continue?`}
        </DialogDescription>
        <DialogFooter>
          <div className="mt-5">
            <Button
              className="mr-5 mt-5"
              variant={"secondary"}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="mt-5"
              variant={"destructive"}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </DialogFooter>

        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
