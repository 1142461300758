import {
  CARD_FIELD_OPTIONS,
  CARD_FIELDS,
  CardFieldKey,
  CardFieldKeys,
  Shop,
} from "@/types/v2";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/Base/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { z, ZodObject, ZodRawShape } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/Base/Button";
import { XIcon } from "lucide-react";
import { Tooltip } from "@/components/Common/Tooltip";

type CardFieldsProps = {
  shop: Shop;
  onSubmit?: (values: FormSchema) => void;
};

const shape: ZodRawShape = {};
CARD_FIELDS.forEach((field) => {
  shape[field.key] = z.string().optional();
});
const formSchema: ZodObject<typeof shape> = z.object({
  ...shape,
});
const defaultValues: { [key in CardFieldKeys]?: CardFieldKey | "" } = {};
CARD_FIELDS.forEach((field) => {
  defaultValues[field.key] = "";
});

type FormSchema = Pick<
  Shop["cardConfig"]["cardContents"],
  "primaryField" | "secondaryField" | "tertiaryField"
>;
export const CardFields = ({ shop, onSubmit }: CardFieldsProps) => {
  const formDefaultValues: FormSchema = {
    ...defaultValues,
    ...shop.cardConfig.cardContents,
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const handleSubmit = (values: z.infer<typeof formSchema>) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleSelectChange = (
    fieldValue: CardFieldKey | "",
    fieldName: keyof FormSchema
  ) => {
    form.setValue(fieldName, fieldValue);
    handleSubmit(form.getValues());
  };

  return (
    <Form {...form}>
      <form className="space-y-4" onSubmit={form.handleSubmit(handleSubmit)}>
        <h1>Card Fields</h1>

        {CARD_FIELDS.map((fieldName) => (
          <FormField
            key={fieldName.key}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{fieldName.label}</FormLabel>
                <FormControl>
                  <div className={"flex flex-row gap-4"}>
                    <Select
                      onValueChange={(value) =>
                        handleSelectChange(
                          value as CardFieldKey | "",
                          field.name as keyof FormSchema
                        )
                      }
                      value={field.value}
                    >
                      <SelectTrigger className={"w-48"}>
                        <SelectValue
                          placeholder={`Choose a ${fieldName.label.toLowerCase()}`}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {CARD_FIELD_OPTIONS.map((name) => (
                          <SelectItem key={name} value={`${name}`}>
                            {name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {field.value !== "" ? (
                      <Tooltip message={"Clear Field Value"}>
                        <Button
                          type="button"
                          onClick={() =>
                            handleSelectChange(
                              "",
                              field.name as keyof FormSchema
                            )
                          }
                          variant={"accent"}
                        >
                          <XIcon />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </div>
                </FormControl>
              </FormItem>
            )}
            name={fieldName.key}
          />
        ))}
      </form>
    </Form>
  );
};
