import { useStore } from "@/stores/useStore";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { Logo } from "@/components/Admin/Logo";
import { EditSaveInput } from "@/components/Common/EditSaveInput";
import { shop } from "@/models/shop";
import { Integrators, Shop } from "@/types/v2";
import { Loading } from "@/components/Page/Loading";
import { Forbidden } from "@/components/Page/Forbidden";
import { useRoles } from "@/hooks/useRoles";
import { Label } from "@/components/Base/Label";
import { EditSaveTime } from "@/components/Common/EditSaveTime";
import { WorkingDay, WorkingDays } from "@/types/v2/ShopConfig";
import {
  DEFAULT_WORKING_HOURS,
  DEFAULT_WORKING_HOURS_END,
  DEFAULT_WORKING_HOURS_START,
} from "@/lib/const";
import { format, parse } from "date-fns";
import { Description } from "@/components/Common/Description";

const CompanyInfoPage = () => {
  const { isSuperAdmin, isAdmin, isOffice, isInitialized } = useRoles();
  useIsAuthenticated();
  const shopData = useStore((s) => s.shop);
  const shopLogoUrl = useStore((s) => s.logoUrl);
  const shopFirestoreId = useStore((s) => s.shopId);

  const handleSave = (key: keyof Shop, newName: string) => {
    if (!shopData?.id) return;
    shop.update(shopData.id, { [key]: newName });
  };

  const handleSaveWorkingHours = (
    key: keyof Omit<WorkingDay, "isClosed">,
    value: string
  ) => {
    if (!shopData?.shopId) return;

    const updatedWorkingDays = {
      ...DEFAULT_WORKING_HOURS,
      ...shopData.shopConfig?.workingHours,
    };

    for (const day in updatedWorkingDays) {
      if (Object.prototype.hasOwnProperty.call(updatedWorkingDays, day)) {
        updatedWorkingDays[day as WorkingDays][key] = value;
      }
    }

    shop.update(shopData.shopId, {
      shopConfig: { workingHours: updatedWorkingDays },
    });
  };

  if (!isInitialized) return <Loading />;
  if (isInitialized && !(isSuperAdmin || isAdmin || isOffice))
    return <Forbidden />;

  if (!shopData || !shopFirestoreId) {
    return (
      <div className="mx-auto max-w-screen-xl flex-grow p-5">
        <h1 className="text-2xl">Shop Info</h1>
        <div className="flex flex-row items-center justify-between p-4">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className="mx-auto max-w-screen-xl flex-grow p-5">
      <h1 className="text-2xl">Shop Info</h1>
      <div className="flex flex-row items-start justify-between gap-2 p-4">
        <div className="flex flex-col gap-2 rounded-xl border p-4">
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"shopName"}>
              Shop Name:
            </Label>
            <EditSaveInput
              name={"shopName"}
              containerClassName="pl-4"
              value={shopData?.name}
              onSave={(value) => handleSave("name", value as string)}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"shopEmail"}>
              Shop Email:
            </Label>
            <EditSaveInput
              name={"shopEmail"}
              containerClassName="pl-4"
              value={shopData?.email}
              onSave={(value) => handleSave("email", value as string)}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"shopPhone"}>
              Shop Phone:
            </Label>
            <EditSaveInput
              name={"shopPhone"}
              containerClassName="pl-4"
              value={shopData?.phone}
              onSave={(value) => handleSave("phone", value as string)}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"shopWebsite"}>
              Shop Website:
            </Label>
            <EditSaveInput
              name={"shopWebsite"}
              containerClassName="pl-4"
              value={shopData?.website}
              onSave={(value) => handleSave("website", value as string)}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"}>Shop ID:</Label>
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.shopId}
              onSave={() => {}}
              disabled={true}
            />
          </div>
          {shopData?.integration === Integrators.shopware && (
            <div className="flex flex-col gap-1">
              <div className="flex flex-row items-center justify-between">
                <Label className={"w-28"} htmlFor={"integrationSubdomain"}>
                  Shop-Ware Subdomain:
                </Label>
                <EditSaveInput
                  name={"integrationSubdomain"}
                  containerClassName="pl-4"
                  value={shopData?.integrationSubdomain}
                  onSave={(value) =>
                    handleSave("integrationSubdomain", value as string)
                  }
                  aria-describedby="integrationSubdomainDescription"
                />
              </div>

              <Description
                id="integrationSubdomainDescription"
                className="pl-8"
              >
                Shop-Ware uses a subdomain in the urls for work orders. This
                often will be similar to your shop name.
              </Description>
            </div>
          )}
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"}>Account Creation Date:</Label>
            <EditSaveInput
              containerClassName="pl-4"
              value={shopData?.createdOn}
              onSave={() => {}}
              disabled={true}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"}>Shop Logo:</Label>
            <Logo shopId={shopFirestoreId} logoUrl={shopLogoUrl} canBeChanged />
          </div>
        </div>

        <div className="flex flex-col gap-2 rounded-xl border p-4">
          <Label className={"text-lg"}>Working Hours</Label>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"workingHoursStart"}>
              Start:
            </Label>
            <EditSaveTime
              value={parse(
                shopData?.shopConfig?.workingHours.Monday.startTime ??
                  DEFAULT_WORKING_HOURS_START,
                "HH:mm",
                new Date()
              )}
              onSave={(value) =>
                handleSaveWorkingHours(
                  "startTime",
                  format(value ?? DEFAULT_WORKING_HOURS_START, "HH:mm")
                )
              }
              options={{ hour12: true }}
            />
          </div>
          <div className="flex flex-row items-center justify-between">
            <Label className={"w-28"} htmlFor={"workingHoursEnd"}>
              End:
            </Label>
            <EditSaveTime
              value={parse(
                shopData?.shopConfig?.workingHours.Monday.endTime ??
                  DEFAULT_WORKING_HOURS_END,
                "HH:mm",
                new Date()
              )}
              onSave={(value) =>
                handleSaveWorkingHours(
                  "endTime",
                  format(value ?? DEFAULT_WORKING_HOURS_END, "HH:mm")
                )
              }
              options={{ hour12: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInfoPage;
