import { forwardRef, useMemo, useState } from "react";
import { useStore } from "@/stores/useStore";
import { Dialog, DialogTrigger, DialogContent } from "@/components/Base/Dialog";
import { Button } from "@/components/Base/Button";
import colorDisplayIcon from "../../assets/colorDisplay.png";
import { DialogTitle } from "@radix-ui/react-dialog";
import { SmallColorDialog } from "@/components/Colors/SmallColorDialog";
import { CardColor } from "@/types/v2";
import { useNavigate } from "react-router-dom";

const StaticColorDialog = forwardRef<HTMLDivElement>(() => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const shopInfo = useStore((s) => s.shop);

  const cardColors = useMemo(() => {
    return shopInfo?.cardConfig?.cardColors ?? [];
  }, [shopInfo]);

  const [selectedColor, setSelectedColor] = useState<CardColor | null>(null);

  const handleColorClick = (colorItem: CardColor) => {
    setSelectedColor(colorItem);
  };

  const handleCloseSmallDialog = () => {
    setSelectedColor(null);
  };

  const handleGoToSettings = () => {
    setOpen(false);
    navigate(`${shopInfo?.shopId}/cardConfig`);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button
          className="w-[46px] p-0 hover:bg-transparent focus:ring-0 active:bg-transparent"
          variant="ghost"
          onClick={() => setOpen(true)}
        >
          <img
            className={"m-0 w-full p-0"}
            src={colorDisplayIcon}
            alt="color display"
          />
        </Button>
      </DialogTrigger>
      <DialogTitle className="hidden">Card Color Legend</DialogTitle>
      <DialogContent className="w-[706px] max-w-full p-0">
        <div className="flex h-[60px] w-full items-center justify-center rounded-t-md bg-[#2D67B2]">
          <span className="text-2xl font-bold text-white">
            Card Color Legend
          </span>
        </div>

        <div className="h-[210px] w-full p-[30px]">
          {cardColors.length === 0 ? (
            <div className="flex h-full w-full flex-col items-center justify-center gap-2">
              <span className="text-sm font-medium text-gray-500">
                No colors available
              </span>
              <Button onClick={handleGoToSettings}>Customize Colors</Button>
            </div>
          ) : (
            <div className="grid grid-cols-4 gap-[15px]">
              {cardColors.map((colorItem, index) => (
                <button
                  key={index}
                  className="flex h-[40px] w-[150px] cursor-pointer items-center justify-center rounded-sm border-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2"
                  style={{
                    backgroundColor: colorItem.color,
                    borderColor: "#35383B",
                  }}
                  onClick={() => handleColorClick(colorItem)} // Pass the clicked color to the handler
                >
                  <span className="text-sm font-medium text-black">
                    {colorItem.title}
                  </span>
                </button>
              ))}
            </div>
          )}
        </div>

        {selectedColor && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-30"
            onClick={handleCloseSmallDialog} // Close when clicking outside
          >
            <div
              onClick={(e) => e.stopPropagation()} // Prevent dialog from closing when clicking inside
            >
              <SmallColorDialog {...selectedColor} />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
});

StaticColorDialog.displayName = "StaticColorDialog";

export { StaticColorDialog };
