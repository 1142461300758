import { Button } from "@/components/Base/Button";
import { PlusIcon, TrashIcon } from "lucide-react";
import { Tooltip } from "@/components/Common/Tooltip";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Input } from "@/components/Base/Input";
import { Option } from "@/types/v2/UI";
import { Select } from "@/components/Common/Select";

export interface TriggerProps {
  label: string;
  canAdd?: boolean;
  canRemove?: boolean;
  addTooltip?: string;
  removeTooltip?: string;
  onAdd?: () => void;
  onRemove?: () => void;
  valuePlaceholder?: string;
  valueValue?: string;
  valueOptions?: Option[];
  onValueChange?: (value: string) => void;
  addComponent?: ReactNode;
  disabled?: boolean;
}

export const Action = ({
  label,
  canAdd = true,
  canRemove = true,
  addTooltip = "Add Another",
  removeTooltip = "Remove",
  onAdd,
  onRemove,
  valuePlaceholder,
  valueValue,
  valueOptions,
  onValueChange,
  addComponent,
  disabled,
}: TriggerProps) => {
  const [localState, setLocalState] = useState<{ value: string }>({
    value: valueValue ?? "",
  });

  useEffect(() => {
    setLocalState((prevState) => ({
      ...prevState,
      value: valueValue ?? "",
    }));
  }, [valueValue]);

  const onValueChangeWrapper = useCallback(
    (value: string) => {
      setLocalState((prevState) => ({
        ...prevState,
        value: value,
      }));
      onValueChange?.(value);
    },
    [onValueChange, setLocalState]
  );

  return (
    <div className={"flex flex-row items-center gap-2"}>
      <div
        className={
          "flex flex-grow flex-row items-center justify-center gap-2 text-base text-primary"
        }
      >
        <p className={"text-center"}>{label.split("%{value}")[0]}</p>
        <div>
          {onValueChange ? (
            valueOptions && valueOptions.length > 0 ? (
              <Select
                selectTriggerProps={{
                  variant: "minimal",
                }}
                disabled={disabled}
                onValueChange={onValueChangeWrapper}
                value={localState.value}
                placeholder={valuePlaceholder}
                options={valueOptions}
              />
            ) : (
              <Input
                disabled={disabled}
                value={localState.value}
                variant={"minimal"}
                placeholder={valuePlaceholder}
                onChange={(e) => onValueChangeWrapper?.(e.target.value)}
              />
            )
          ) : valueValue ? (
            <span>
              {valueOptions && valueOptions.length > 0
                ? valueOptions.find((o) => o.value === valueValue)?.label
                : valueValue}
            </span>
          ) : null}
        </div>
        <span>{label.split("%{value}")[1]}</span>
      </div>
      <div className={"flex flex-row gap-2"}>
        {canAdd && !addComponent && (
          <Tooltip message={addTooltip}>
            <Button size="icon" variant="ghost" onClick={onAdd}>
              <PlusIcon />
            </Button>
          </Tooltip>
        )}
        {canAdd && addComponent && addComponent}
        {canRemove && (
          <Tooltip message={removeTooltip}>
            <Button size="icon" variant="destructive-alt" onClick={onRemove}>
              <TrashIcon />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
