import { useEffect, useState } from "react";
import { Input, InputProps } from "../Base/Input";
import { EditSaveButtons } from "./EditSaveButtons";
import { cn } from "@/lib/utils";
import { ClassNameValue } from "tailwind-merge";

interface EditSaveInputProps extends InputProps {
  onSave: (arg: string | number | readonly string[] | undefined) => void;
  containerClassName?: ClassNameValue;
  disabled?: boolean;
}

export const EditSaveInput = ({
  value,
  onSave,
  containerClassName,
  disabled,
  ...props
}: EditSaveInputProps) => {
  const [newValue, setNewValue] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setNewValue(value);
  }, [value]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(e.target.value);
  };

  const handleEdit = () => setIsEditing(true);
  const handleDiscard = () => {
    setIsEditing(false);
    setNewValue(value);
  };

  const handleSave = () => {
    onSave(newValue);
    setIsEditing(false);
  };

  return (
    <div
      className={cn(
        "flex flex-grow flex-row justify-between align-middle",
        containerClassName
      )}
    >
      {isEditing ? (
        <Input
          value={newValue}
          onChange={handleChange}
          {...props}
          className="text-slate-500"
        />
      ) : (
        <div className="flex h-10 w-full items-center rounded-md px-3 py-2 text-sm text-primary-foreground">
          {newValue}
        </div>
      )}
      <EditSaveButtons
        containerClassName={cn("w-48 justify-end", disabled && "hidden")}
        isEditing={isEditing}
        handleDiscard={handleDiscard}
        handleEdit={handleEdit}
        handleSave={handleSave}
      />
    </div>
  );
};
