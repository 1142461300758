import { useStore } from "@/stores/useStore";
import { Button } from "@/components/Base/Button";
import { Link, useParams } from "react-router-dom";
import { LayoutsTable } from "@/components/Layout/Layouts.table";
import { Layouts } from "@/types/v2";
import { shop as shopRepository } from "@/models/shop";

export const LayoutsPage = () => {
  const { shopId } = useParams();
  const layouts = useStore((s) => s.shop?.layouts ?? []);

  const handleLayoutsUpdate = async (layouts: Layouts) => {
    if (!shopId) return;
    await shopRepository.update(shopId, { layouts: layouts });
    return;
  };

  return (
    <div className="overflow-auto p-4">
      <div className="flex flex-row items-center justify-between">
        <Button variant="secondary">
          <Link to={`/${shopId}/layoutBuilder`}>Layout Builder</Link>
        </Button>
      </div>
      <div className={"mx-auto max-w-screen-xl"}>
        <LayoutsTable layouts={layouts} onUpdate={handleLayoutsUpdate} />
      </div>
    </div>
  );
};
