import { CardContents, Shop } from "@/types/v2";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/Base/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { z, ZodObject, ZodRawShape } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Switch } from "@/components/Base/Switch";
import { Fragment } from "react";

const shape: ZodRawShape = {
  manualCardFormat: z.string().optional(),
  jobHoursEnabled: z.boolean().optional(),
  promiseTimeEnabled: z.boolean().optional(),
  technicianEnabled: z.boolean().optional(),
  serviceAdvisorEnabled: z.boolean().optional(),
};

const formSchema: ZodObject<typeof shape> = z.object({
  ...shape,
});

const defaultValues: FormSchema = {
  manualCardFormat: "basic",
  manualJobHoursEnabled: false,
  manualPromiseTimeEnabled: false,
  manualTechnicianEnabled: true,
  manualServiceAdvisorEnabled: true,
};

type FormSchema = Pick<
  CardContents,
  | "manualCardFormat"
  | "manualJobHoursEnabled"
  | "manualTechnicianEnabled"
  | "manualServiceAdvisorEnabled"
  | "manualPromiseTimeEnabled"
>;

type ManualCardOptionsProps = {
  shop: Shop;
  onSubmit?: (values: FormSchema) => void;
};
export const ManualCardOptions = ({
  shop,
  onSubmit,
}: ManualCardOptionsProps) => {
  const formDefaultValues: FormSchema = {
    ...defaultValues,
    ...shop.cardConfig.cardContents,
  };

  const { watch, ...form } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues,
  });

  const handleSubmit = (values: z.infer<typeof formSchema>) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  const handleSelectChange = (
    fieldValue: "basic" | "advanced",
    fieldName: keyof FormSchema
  ) => {
    form.setValue(fieldName, fieldValue);
    handleSubmit(form.getValues());
  };

  const handleSwitchChange = (
    fieldValue: boolean,
    fieldName: keyof FormSchema
  ) => {
    form.setValue(fieldName, fieldValue);
    handleSubmit(form.getValues());
  };

  const manualFormFormat = watch("manualCardFormat", "basic");

  return (
    <Form {...form} watch={watch}>
      <form
        className="min-w-64 max-w-96 space-y-4"
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <h1 className={"font-medium"}>Manual Card Options</h1>
        <FormField
          name={"manualCardFormat"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Manual Card Format</FormLabel>
              <FormControl>
                <Select
                  onValueChange={(value) =>
                    handleSelectChange(
                      value as "basic" | "advanced",
                      field.name as keyof FormSchema
                    )
                  }
                  defaultValue={"basic"}
                  value={field.value}
                >
                  <SelectTrigger className={"w-64"}>
                    <SelectValue placeholder={"Choose a format option"} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={"basic"}>Basic</SelectItem>
                    <SelectItem value={"advanced"}>Advanced</SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          name={"manualServiceAdvisorEnabled"}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between gap-2 rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Service Advisor</FormLabel>
                <FormDescription>
                  Allow selecting from a list of service advisors on the
                  creation of cards.
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value ?? true}
                  onCheckedChange={(value) =>
                    handleSwitchChange(value, field.name as keyof FormSchema)
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          name={"manualTechnicianEnabled"}
          render={({ field }) => (
            <FormItem className="flex flex-row items-center justify-between gap-2 rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Technician</FormLabel>
                <FormDescription>
                  Allow selecting from a list of technicians on the creation of
                  cards.
                </FormDescription>
              </div>
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={(value) =>
                    handleSwitchChange(value, field.name as keyof FormSchema)
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
        {manualFormFormat === "advanced" && (
          <Fragment>
            <h2 className={"font-medium"}>Advanced Options</h2>
            <FormField
              name={"manualJobHoursEnabled"}
              render={({ field }) => (
                <FormItem className="flex flex-row items-center justify-between gap-2 rounded-lg border p-4">
                  <div className="space-y-0.5">
                    <FormLabel className="text-base">Job Hours</FormLabel>
                    <FormDescription>
                      Allow entering job hours on the creation of cards.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={(value) =>
                        handleSwitchChange(
                          value,
                          field.name as keyof FormSchema
                        )
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </Fragment>
        )}
      </form>
    </Form>
  );
};
