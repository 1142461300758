import { useIsGuest } from "@/hooks/useIsGuest";
import { LoginForm } from "@/components/Auth/Login.form";

const LoginPage = () => {
  useIsGuest();

  return (
    <div className="m-auto flex flex-col gap-4 bg-primary text-primary-foreground">
      <LoginForm />
    </div>
  );
};

export default LoginPage;
