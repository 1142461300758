import { Card, Container, Employee, Layouts } from "@/types/v2";
import { DataTable } from "@/components/Base/Datatable";
import { containerColumns } from "@/components/Layout/Containers.columns";
import { Button } from "@/components/Base/Button";
import { PencilIcon, TrashIcon } from "lucide-react";
import { ChangeEvent, Fragment, ReactNode, useEffect, useState } from "react";
import { ContainerDialog } from "@/components/Layout/ContainerDialog";
import { ContainerFormValues } from "@/components/Layout/ContainerForm";
import { useConfirmDialog } from "@/components/Common/ConfirmDialog";
import { Tooltip } from "@/components/Common/Tooltip";
import { Input } from "@/components/Base/Input";
import Fuse from "fuse.js";

interface ContainerTableProps {
  containers: Container[];
  employees: Employee[];
  cards: Card[];
  layouts: Layouts;
  labels: string[];
  onEdit?: (container: Container) => Promise<void>;
  onDelete?: (container: Container) => Promise<void>;
  showLinkedLayouts?: boolean;
  showCardCount?: boolean;
  showCompletedHours?: boolean;
  showTotalHours?: boolean;
}
export const ContainersTable = ({
  containers,
  employees,
  cards,
  layouts,
  labels,
  onEdit,
  onDelete,
  showLinkedLayouts = true,
  showCardCount = false,
  showCompletedHours = false,
  showTotalHours = false,
}: ContainerTableProps) => {
  const [activeContainer, setActiveContainer] = useState<Container>();
  const [containerDialogOpen, setContainerDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [searchedContainers, setSearchedContainers] = useState<Container[]>([]);

  const { confirm, Dialog } = useConfirmDialog();

  const handleEdit = (container: Container) => {
    setActiveContainer(container);
    setContainerDialogOpen(true);
  };

  const handleDelete = async (container: Container) => {
    setActiveContainer(container);
    setIsSubmitting(true);
    try {
      await confirm({
        title: "Are you sure?",
        description: "This will delete this container. Do you wish to proceed?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      setIsSubmitting(false);
      return;
    }

    if (onDelete) await onDelete(container);
    setActiveContainer(undefined);
    setIsSubmitting(false);
  };

  const actions = (container: Container) => {
    const actions: ReactNode[] = [];

    if (onEdit)
      actions.push(
        <Tooltip key={"update"} message={"Update this container."}>
          <Button
            onClick={() => handleEdit(container)}
            variant="ghost"
            id="delete_container"
            size="smIcon"
          >
            <PencilIcon size={20} />
          </Button>
        </Tooltip>
      );

    if (onDelete)
      actions.push(
        <Tooltip
          key={"delete"}
          message={
            container.id === "dispatch"
              ? "The dispatch container cannot be deleted"
              : container.tags.length > 0
                ? "Containers with cards cannot be deleted. Move the cards out of the container to delete"
                : "Delete this container. Note this cannot be undone."
          }
        >
          <Button
            onClick={() => handleDelete(container)}
            variant="destructive-alt"
            id="delete_container"
            size="smIcon"
            disabled={container.id === "dispatch" || container.tags.length > 0}
          >
            <TrashIcon size={20} />
          </Button>
        </Tooltip>
      );

    return actions;
  };

  const convertContainerData = (
    container: Container | undefined
  ): ContainerFormValues | undefined => {
    if (!container) return undefined;
    const id = container?.id ?? "";
    let linkedSourceLabel = container?.linkedSourceLabel ?? [];
    let linkedTechnicianId = container?.linkedTechnicianId ?? [];
    if (typeof linkedSourceLabel === "string") {
      linkedSourceLabel = [linkedSourceLabel];
    }
    if (
      typeof linkedTechnicianId === "string" ||
      typeof linkedTechnicianId === "number"
    ) {
      linkedTechnicianId = [`${linkedTechnicianId}`];
    }

    return {
      ...container,
      linkedSourceLabel,
      linkedTechnicianId,
      id,
    };
  };

  const handleSubmitEdit = async (container: Partial<Container>) => {
    setIsSubmitting(true);
    if (onEdit) await onEdit(container as Container);
    setIsSubmitting(false);
    setActiveContainer(undefined);
    setContainerDialogOpen(false);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  useEffect(() => {
    if (searchString === "") {
      setSearchedContainers(containers);
    } else {
      const fuse = new Fuse(containers, { keys: ["id", "displayName"] });
      const results = fuse.search(searchString);
      setSearchedContainers(results.map((result) => result.item));
    }
  }, [containers, searchString]);

  return (
    <Fragment>
      {actions.length > 0 && <Dialog />}
      <ContainerDialog
        containerData={convertContainerData(activeContainer)}
        open={containerDialogOpen}
        employees={employees}
        onOpenChange={setContainerDialogOpen}
        onSubmit={handleSubmitEdit}
        labels={labels}
        externalTrigger
        loading={isSubmitting}
        containers={containers}
      />
      <div className="flex items-center justify-between">
        <Input
          className={"my-2 w-48 text-primary"}
          placeholder="Search containers"
          value={searchString}
          onChange={handleSearchChange}
        />
      </div>
      <DataTable
        data={searchedContainers}
        columns={containerColumns({
          actions: actions,
          loading: false,
          employees,
          layouts,
          cards,
        })}
        userDefaults={{
          pagination: { pageSize: 10 },
          columnVisibility: {
            id: false,
            linkedLayouts: showLinkedLayouts,
            totalCards: showCardCount,
            totalHours: showTotalHours,
            completedHours: showCompletedHours,
          },
        }}
        factoryDefaults={{
          pagination: { pageSize: 10 },
          columnVisibility: {
            id: false,
            linkedLayouts: showLinkedLayouts,
            totalCards: showCardCount,
            totalHours: showTotalHours,
            completedHours: showCompletedHours,
          },
        }}
      />
    </Fragment>
  );
};
