import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Base/Dialog";
import { Button } from "@/components/Base/Button";

/**
 * A custom hook that provides a confirmation dialog mechanism for user interactions.
 * It allows you to show a confirmation dialog with customizable options for title, description,
 * confirm button text, and cancel button text. The dialog resolves or rejects a promise
 * based on user interaction with the confirm or cancel buttons.
 * @returns {object} An object containing the `confirm` function and `Dialog` component.
 * - `confirm`: A function that shows the confirmation dialog and returns a promise that resolves when the confirm button is clicked and rejects when the cancel button is clicked.
 * - `Dialog`: A React component representing the confirmation dialog, which needs to be included in your component tree.
 */
export function useConfirmDialog() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<
    Omit<ConfirmDialogProps, "open" | "setOpen">
  >({
    title: "",
    description: "",
    confirmButtonText: "Confirm",
    cancelButtonText: "Cancel",
  });

  const confirm = ({
    title,
    description,
    confirmButtonText,
    cancelButtonText,
  }: Omit<ConfirmDialogProps, "open" | "setOpen">) => {
    return new Promise<void>((resolve, reject) => {
      setDialogProps({
        title,
        description,
        confirmButtonText,
        cancelButtonText,
        onConfirm: () => {
          resolve(); // Resolve the promise when "Confirm" is clicked
          setDialogOpen(false); // Close the dialog
        },
        onCancel: () => {
          reject(); // Reject the promise when "Cancel" is clicked
          setDialogOpen(false); // Close the dialog
        },
      });
      setDialogOpen(true); // Open the dialog
    });
  };

  const Dialog = () => (
    <ConfirmDialog
      open={isDialogOpen}
      setOpen={setDialogOpen}
      {...dialogProps} // Pass all necessary props dynamically
    />
  );

  return { confirm, Dialog };
}

export interface ConfirmDialogProps {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const ConfirmDialog = ({
  title = "Are you sure?",
  description,
  onConfirm,
  onCancel,
  confirmButtonText = "Yes",
  cancelButtonText = "Cancel",
  open = false,
  setOpen,
}: ConfirmDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button type={"button"} onClick={onCancel} variant={"secondary"}>
            {cancelButtonText}
          </Button>
          <Button type={"submit"} onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
