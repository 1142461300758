import { Layout, Layouts, Shop } from "@/types/v2";
import { shop } from "@/models/shop";
import { arrayUnion, updateDoc } from "firebase/firestore";
import clone from "just-clone";
import { store } from "@/stores/useStore";

export const layout = {
  generateBaseLayout: (): Layout[] => {
    return [
      { i: "dispatch", x: 0, y: 0, w: 96, h: 2, moved: false, static: false },
    ];
  },
  saveLayouts: async (shopId: string, layouts: Layouts) => {
    return await updateDoc(shop.ref(shopId), { layouts });
  },
  deleteLayout: async (shopId: string, layoutName: string) => {
    const layouts = clone(store.getState().shop?.layouts || []);

    const layoutIndex = layouts.findIndex((l) =>
      Object.keys(l).includes(layoutName)
    );

    layouts.splice(layoutIndex, 1);

    await updateDoc(shop.ref(shopId), { layouts });
    return;
  },
  updateLayout: async (
    shopId: string,
    layoutName: string,
    layoutData: Layout[]
  ) => {
    const layouts = clone(store.getState().shop?.layouts || []);

    const layoutIndex = layouts.findIndex((l) =>
      Object.keys(l).includes(layoutName)
    );

    if (layoutIndex === -1) {
      await layout.addLayout(shopId, layoutName, layoutData);
      return { created: true, updated: false };
    }

    layouts[layoutIndex] = { [layoutName]: layoutData };

    await updateDoc(shop.ref(shopId), { layouts });

    return { created: false, updated: true };
  },
  addLayout: async (shopId: string, layoutName: string, layout: Layout[]) => {
    await updateDoc(shop.ref(shopId), {
      layouts: arrayUnion({ [layoutName]: layout }),
    });
  },
  addContainerToLayout: (containerId: string, layoutData: Layout[]) => {
    const layoutCopy = layoutData.slice();
    const newLayout: Layout = {
      i: containerId,
      x: 0,
      y: 0,
      w: 16,
      h: 6,
      moved: false,
      static: false,
    };
    return layoutCopy.concat(newLayout);
  },
  removeContainerFromLayout: (containerId: string, layoutData: Layout[]) => {
    const layoutCopy = layoutData.slice();
    return layoutCopy.filter((item) => item.i !== containerId);
  },
  removeContainerFromAllLayouts: (containerId: string) => {
    const layouts = clone(store.getState().shop?.layouts || []);
    for (let i = 0; i < layouts.length; i++) {
      const layoutData = layouts[i];
      const keys = Object.keys(layoutData);
      for (let j = 0; j < keys.length; j++) {
        const layoutName = keys[j];
        Object.assign(
          layoutData[layoutName],
          layout.removeContainerFromLayout(containerId, layoutData[layoutName])
        );
      }
      layouts[i] = layoutData;
    }
    return layouts;
  },
  findLayoutsWithContainer: (
    containerId: string,
    layouts: Layouts
  ): Layouts => {
    const matching: Layouts = [];
    for (let i = 0; i < layouts.length; i++) {
      const layoutData = layouts[i];
      const keys = Object.keys(layoutData);
      for (let j = 0; j < keys.length; j++) {
        const layoutName = keys[j];
        const layout = layoutData[layoutName];
        const containerIndex = layout.findIndex((l) => l.i === containerId);
        if (containerIndex !== -1) {
          matching.push(layoutData);
        }
      }
    }
    return matching;
  },
  moveLayoutUp: (layoutName: string) => {
    const layouts = clone(store.getState().shop?.layouts || []);
    const layoutIndex = layouts.findIndex((l) =>
      Object.keys(l).includes(layoutName)
    );
    if (layoutIndex === -1) return;
    const layout = layouts[layoutIndex];
    layouts.splice(layoutIndex, 1);
    layouts.splice(layoutIndex - 1, 0, layout);
    return layouts;
  },
  moveLayoutDown: (layoutName: string) => {
    const layouts = clone(store.getState().shop?.layouts || []);
    const layoutIndex = layouts.findIndex((l) =>
      Object.keys(l).includes(layoutName)
    );
    if (layoutIndex === -1) return;
    const layout = layouts[layoutIndex];
    layouts.splice(layoutIndex, 1);
    layouts.splice(layoutIndex + 1, 0, layout);
    return layouts;
  },
  filterForAccess: (
    shop: undefined | Shop,
    employeeId: string | undefined,
    isSuperAdmin: boolean | undefined
  ) => {
    if (!shop) return [];

    // Check if layoutAccess and userId are available
    if (!shop?.layoutAccess || !employeeId) {
      return shop?.layouts; // If no access data or user ID, return all layouts
    }

    if (isSuperAdmin) {
      return shop?.layouts;
    }

    const layoutAccessArray = shop.layoutAccess;

    // Filter layouts based on user access
    return shop.layouts.filter((layout) => {
      const layoutName = Object.keys(layout)[0]; // Get layout name
      const accessEntry = layoutAccessArray.find(
        (access) => access.layoutName === layoutName
      );

      // If no accessEntry or allowedList is empty, allow access
      if (!accessEntry || accessEntry.allowedList.length === 0) {
        return true;
      }

      // Allow access if userId is in the allowedList
      return accessEntry.allowedList.includes(employeeId);
    });
  },
};
