import { cn } from "@/lib/utils";
import { forwardRef } from "react";
import { CardColor } from "@/types/v2";
import { EditColorDialog } from "@/components/Colors/EditColorDialog";

export interface ColorProps {
  color: CardColor;
  onEdit?: (color: CardColor) => Promise<void>; // Callback for the edit button
}

export interface ColorListProps {
  colors: CardColor[]; // List of colors with their props
  className?: string; // Optional additional classes
  onEdit?: (color: CardColor) => Promise<void>; // Callback for the edit button
}

const ColorItem = forwardRef<HTMLDivElement, ColorProps>(
  ({ color, onEdit }, ref) => (
    <div
      ref={ref}
      className="flex items-center justify-between rounded-md px-4 py-1"
    >
      <div className="flex items-center space-x-4">
        <div
          className="flex h-[40px] w-[150px] items-center justify-center rounded-sm"
          style={{ backgroundColor: color.color }}
        >
          {color.title && (
            <span className={cn("text-[0.9rem] font-medium text-black")}>
              {color.title}
            </span>
          )}
        </div>
      </div>
      <EditColorDialog
        color={color.color}
        existingDescription={color.description}
        existingTitle={color.title}
        onSave={(title, description) => {
          if (onEdit) {
            onEdit({
              ...color,
              title: title,
              description: description,
            });
          }
        }}
      />
    </div>
  )
);
ColorItem.displayName = "ColorItem";

const ColorList = forwardRef<HTMLDivElement, ColorListProps>(
  ({ colors, className, onEdit }, ref) => (
    <div ref={ref} className={cn("flex flex-col space-y-2", className)}>
      {colors.map((color) => (
        <ColorItem key={color.id} color={color} onEdit={onEdit} />
      ))}
    </div>
  )
);
ColorList.displayName = "ColorList";

export { ColorList, ColorItem };
