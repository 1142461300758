import { ContainersTable } from "@/components/Layout/Containers.table";
import { useStore } from "@/stores/useStore";

export const ContainersPage = () => {
  const containers = useStore((s) => s.shop?.containers ?? []);
  const employees = useStore((s) => s.employees ?? []);
  const labels = useStore((s) => s.shop?.customLabels ?? []);
  const layouts = useStore((s) => s.shop?.layouts ?? []);
  const cards = useStore((s) => s.cards);

  return (
    <div className="overflow-auto p-4">
      <ContainersTable
        containers={containers}
        employees={employees}
        layouts={layouts}
        labels={labels}
        cards={cards}
        showCompletedHours={true}
        showCardCount={true}
        showTotalHours={true}
        showLinkedLayouts={false}
      />
    </div>
  );
};
