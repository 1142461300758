import React, { useState, useEffect, useContext, createContext } from "react";
import { cn } from "@/lib/utils";

interface State {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const formatTime = (timeInSeconds: number): State => {
  const days = Math.floor(timeInSeconds / (3600 * 24));
  const hours = Math.floor((timeInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return { days, hours, minutes, seconds };
};

interface TimeDisplayModeContextType {
  displayMode: number;
  setDisplayMode: React.Dispatch<React.SetStateAction<number>>;
}

const TimeDisplayModeContext = createContext<
  TimeDisplayModeContextType | undefined
>(undefined);

export const TimeDisplayModeProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [displayMode, setDisplayMode] = useState<number>(() => {
    const savedMode = localStorage.getItem("timeDisplayMode");
    return savedMode ? parseInt(savedMode, 10) : 2;
  });

  useEffect(() => {
    localStorage.setItem("timeDisplayMode", displayMode.toString());
  }, [displayMode]);

  return (
    <TimeDisplayModeContext.Provider value={{ displayMode, setDisplayMode }}>
      {children}
    </TimeDisplayModeContext.Provider>
  );
};

const useTimeDisplayMode = (): TimeDisplayModeContextType => {
  const context = useContext(TimeDisplayModeContext);
  if (!context) {
    throw new Error(
      "useTimeDisplayMode must be used within a DisplayModeProvider"
    );
  }
  return context;
};

const TimeDisplay = ({
  size,
  timeInSeconds,
  disabled,
}: {
  size?: "xs" | "sm" | "base";
  timeInSeconds?: number;
  disabled?: boolean;
}) => {
  const [time, setTime] = useState<State>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { displayMode, setDisplayMode } = useTimeDisplayMode();

  useEffect(() => {
    if (timeInSeconds !== undefined) {
      setTime(formatTime(timeInSeconds));
      return;
    }
    const interval = setInterval(() => {
      const now = new Date();
      const midnight = new Date();
      midnight.setHours(0, 0, 0, 0);

      const timeLeft = (now.getTime() - midnight.getTime()) / 1000;
      setTime(formatTime(timeLeft));
    }, 1000);

    return () => clearInterval(interval);
  }, [timeInSeconds]);

  const toggleDisplayMode = () => {
    if (disabled) return;
    setDisplayMode((prevMode) => (prevMode + 1) % 4);
  };

  return (
    <div
      onClick={toggleDisplayMode}
      className={cn(
        "cursor-pointer text-nowrap text-center",
        size === "sm" && "text-sm",
        size === "xs" && "text-xs"
      )}
    >
      {displayMode === 0 && (
        <div>
          <span>{time.days}d </span>
          <span>{time.hours}h </span>
          <span>{time.minutes}m </span>
          <span>{time.seconds}s</span>
        </div>
      )}
      {displayMode === 1 && (
        <div>
          <span>{time.days}d </span>
          <span>{time.hours}h </span>
          <span>{time.minutes}m</span>
        </div>
      )}
      {displayMode === 2 && (
        <div>
          <span>{time.hours}h </span>
          <span>{time.minutes}m</span>
        </div>
      )}
      {displayMode === 3 && (
        <div>
          <span>{time.hours}h</span>
        </div>
      )}
    </div>
  );
};

export default TimeDisplay;
