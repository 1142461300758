import { useEffect, useState } from "react";

// DISPLAY COMPONENTS
import { DnDContainer } from "./DnDContainer";
import { useStore } from "@/stores/useStore";
import { Layout } from "@/types/v2";
import { Loading } from "@/components/Page/Loading";
import { useLayouts } from "@/hooks/useLayouts";

export const Dashboard = () => {
  const logout = useStore((s) => s.logout);
  const [loaded, setLoaded] = useState(false);
  const {
    dashboardServiceWriterId,
    dashboardTechnicianId,
    dashboardLayoutId: selectedLayoutId,
    dashboardSearchString,
    syncWithSource,
  } = useStore();

  const { layouts: layoutsData } = useLayouts();

  // LAYOUT MANAGEMENT
  const [selectedLayout, setSelectedLayout] = useState<Layout[]>();
  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setTicker((t) => t + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (layoutsData) {
      new Promise((r) => setTimeout(r, 1000)).then(() => setLoaded(true));
    }
  }, [layoutsData]);

  // SUBSCRIBE TO FIRESTORE SHOP QUERY TO RETRIEVE CONTAINERS AND LAYOUTS INFO
  // Did this on App.jsx

  useEffect(() => {
    if (selectedLayoutId && layoutsData && layoutsData.length > 0) {
      const layout =
        layoutsData.find(
          (layout) => Object.keys(layout)[0] === selectedLayoutId
        ) ?? layoutsData[0];
      if (!layout) return setSelectedLayout(undefined);
      const selectedLayout = layout[selectedLayoutId as keyof typeof layout];
      setSelectedLayout(selectedLayout);
    }
  }, [layoutsData, selectedLayoutId]);

  const handleSignOut = () => {
    logout();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      syncWithSource();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [syncWithSource]);

  if (layoutsData === undefined) {
    return (
      <>
        <div>
          Loading Layouts. If this action takes too long. Click the button below
          and refresh the page.
        </div>
        <button
          style={{
            marginLeft: 8,
            width: 180,
            padding: 8,
            backgroundColor: "#244C91",
            color: "#ffffff",
            borderRadius: 10,
          }}
          onClick={handleSignOut}
        >
          Logout
        </button>
      </>
    );
  }

  if (!loaded) return <Loading />;

  return (
    <>
      {selectedLayout ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(96, 1fr)",
            gridTemplateRows: "repeat(12,1fr)",
            gap: ".5rem",
            height: "calc(100vh - 92px)",
            overflow: "auto",
          }}
        >
          <DnDContainer
            selectedLayout={selectedLayout}
            serviceAdvisorId={dashboardServiceWriterId}
            technicianId={dashboardTechnicianId}
            searchString={dashboardSearchString}
            ticker={ticker}
          />
        </div>
      ) : (
        <div className="mt-20 flex flex-col items-center justify-center text-3xl">
          <p>Please select a layout to display</p>
        </div>
      )}
    </>
  );
};
