import React from "react";
import { BasePage } from "@/components/Page/BasePage";
import { useStore } from "@/stores/useStore";
import {
  RouterProvider as BaseRouterProvider,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { Spinner } from "@/components/Common/Spinner";
import { GlobalConfigPage } from "@/pages/(superAdmin)/GlobalConfig.page";
import { ShopConfigPage } from "@/pages/(superAdmin)/(shop)/ShopConfig.page";
import DebugCardCreatePage from "@/pages/(superAdmin)/(debug)/CreateCard.page";
import { AuthState } from "@/types/v1";
import {
  ActivityFeedPage,
  AutomationsPage,
  CardConfigPage,
  CompanyInfoPage,
  DebugPage,
  EmployeesPage,
  ErrorPage,
  IntegrationsPage,
  IsAuthenticatedRedirect,
  LayoutBuilderPage,
  LoginPage,
  ProtectedRoute,
  ResetPasswordPage,
  ShopDashboardPage,
  ShopLayoutPage,
  SignupPage,
  TermsAndConditionsPage,
  VerifyPage,
} from "@/pages";
import { MessageBoardLayoutPage } from "@/pages/(shop)/messageBoard/_layout.page";
import { MessageBoardRootPage } from "@/pages/(shop)/messageBoard/MessageBoardRoot.page";
import { MessageBoardPage } from "@/pages/(shop)/messageBoard/MessageBoard.page";
import { LayoutContainersPage } from "@/pages/(shop)/dashboard/LayoutContainers.page";
import { LayoutsPage } from "@/pages/(shop)/dashboard/Layouts.page";
import { ContainersPage } from "@/pages/(shop)/dashboard/Containers.page";

const router = ({ authState }: { authState: AuthState }) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        errorElement={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <ErrorPage />
            </React.Suspense>
          </BasePage>
        }
        element={
          <BasePage>
            <React.Suspense fallback={<Spinner />}>
              <Outlet />
            </React.Suspense>
          </BasePage>
        }
      >
        <Route
          path=""
          element={
            <ProtectedRoute>
              <ShopLayoutPage />
            </ProtectedRoute>
          }
        />
        <Route path="/verify" element={<VerifyPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/resetpassword" element={<ResetPasswordPage />} />
        <Route path="/terms" element={<TermsAndConditionsPage />} />

        <Route
          path="/:shopId"
          element={
            <ProtectedRoute>
              <ShopLayoutPage />
            </ProtectedRoute>
          }
        >
          <Route path="" element={<ShopDashboardPage />} />
          <Route path="layoutBuilder" element={<LayoutBuilderPage />} />
          <Route path="cardConfig" element={<CardConfigPage />} />
          <Route path="containers" element={<Outlet />}>
            <Route path="" element={<ContainersPage />} />
          </Route>
          <Route path="layouts" element={<LayoutsPage />} />
          <Route
            path="layoutBuilder/containers"
            element={<LayoutContainersPage />}
          />
          <Route path="automations" element={<AutomationsPage />} />
          <Route path="employees" element={<EmployeesPage />} />
          <Route path="integrations" element={<IntegrationsPage />} />
          <Route path="companyInfo" element={<CompanyInfoPage />} />
          <Route path="shopInfo" element={<CompanyInfoPage />} />
          <Route path="activityFeed" element={<ActivityFeedPage />} />
          <Route path="messageBoard" element={<MessageBoardLayoutPage />}>
            <Route path="" element={<MessageBoardRootPage />} />
            <Route path=":conversationId" element={<MessageBoardPage />} />
          </Route>
          <Route path="features" element={<ShopConfigPage />} />
        </Route>

        {authState === "authenticated" && (
          <>
            <Route path="/debug" element={<Outlet />}>
              <Route path="" element={<DebugPage />} />
              <Route path="cards" element={<Outlet />}>
                <Route path="create" element={<DebugCardCreatePage />} />
              </Route>
            </Route>
            <Route path="features" element={<Outlet />}>
              <Route path="global" element={<GlobalConfigPage />} />
            </Route>
          </>
        )}
        <Route path="*" element={<IsAuthenticatedRedirect />} />
      </Route>
    )
  );
export const RouterProvider = () => {
  const authState = useStore((s) => s.authState);

  return (
    <BaseRouterProvider
      router={router({
        authState,
      })}
    />
  );
};
