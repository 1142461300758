import { Button, ButtonProps } from "@/components/Base/Button";
import { forwardRef, ReactNode } from "react";
import {
  AppWindowMacIcon,
  CalendarClockIcon,
  ClockIcon,
  HourglassIcon,
  PanelTopIcon,
  UserRoundIcon,
} from "lucide-react";
import { cn } from "@/lib/utils";
import { getIntegratorImage } from "@/lib/integrators";
import { ClassNameValue } from "tailwind-merge";
import { BuilderButtonIcon, Icons } from "@/types/v2/Automation";
import { Tooltip } from "@/components/Common/Tooltip";

export interface BuilderButtonProps extends Omit<ButtonProps, "children"> {
  label: ReactNode;
  icon: BuilderButtonIcon;
  tooltip?: string;
}

const ButtonIcon = ({
  icon,
  className,
}: {
  icon: BuilderButtonIcon;
  className?: ClassNameValue;
}) => {
  const baseClass = "w-6 h-6";
  switch (icon) {
    case Icons.card:
      return <PanelTopIcon className={cn(baseClass, className)} />;
    case Icons.dueDate:
      return <CalendarClockIcon className={cn(baseClass, className)} />;
    case Icons.container:
      return <AppWindowMacIcon className={cn(baseClass, className)} />;
    case Icons.employee:
      return <UserRoundIcon className={cn(baseClass, className)} />;
    case Icons.clock:
      return <ClockIcon className={cn(baseClass, className)} />;
    case Icons.hourglass:
      return <HourglassIcon className={cn(baseClass, className)} />;
    case Icons.shopware:
      return (
        <img
          src={getIntegratorImage(Icons.shopware)}
          className={cn(baseClass, "h-auto", className)}
          alt={"shopware logo"}
        />
      );
    case Icons.tekmetric:
      return (
        <img
          src={getIntegratorImage(Icons.tekmetric)}
          className={cn(baseClass, "h-auto", className)}
          alt={"tekmetric logo"}
        />
      );
    case Icons.manual:
      return (
        <img
          src={getIntegratorImage(Icons.manual)}
          className={cn(baseClass, "h-auto", className)}
          alt={"manual logo"}
        />
      );
    default:
      return null;
  }
};

export const BuilderButton = forwardRef<HTMLButtonElement, BuilderButtonProps>(
  (
    { icon, label, type = "button", variant = "ghost", tooltip, ...props },
    ref
  ) => {
    const buttonContent = (
      <Button
        ref={ref}
        {...props}
        type={type}
        variant={variant}
        className={
          "justify-start text-primary hover:bg-secondary hover:text-primary"
        }
      >
        <ButtonIcon icon={icon} className={"mr-2 text-accent"} />
        {label}
      </Button>
    );

    return tooltip ? (
      <Tooltip message={tooltip}>{buttonContent}</Tooltip>
    ) : (
      buttonContent
    );
  }
);

BuilderButton.displayName = "BuilderButton";
