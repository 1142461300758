import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import GridLayout from "./GridLayout";
import {
  layout as layoutModel,
  layout as layoutRepository,
} from "@/models/layout";
import { useStore } from "@/stores/useStore";
import { Container, Layout, LayoutAccess } from "@/types/v2";
import { shop } from "@/models/shop";
import RGL from "react-grid-layout";
import clone from "just-clone";
import { Button } from "@/components/Base/Button";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/Base/Select";
import { LayoutAccessDialog } from "@/components/Dialogs/LayoutAccessDialog";
import { NewLayoutDialog } from "@/components/Dialogs/NewLayoutDialog";
import { showErrorToast, showSuccessToast } from "@/lib/toast";
import {
  Trash2,
  PlusIcon,
  AppWindowMacIcon,
  CheckIcon,
  SaveIcon,
  LayoutTemplateIcon,
} from "lucide-react";
import { Tooltip } from "@/components/Common/Tooltip";
import { container as containerRepository } from "@/models/container";
import { Link, useSearchParams } from "react-router-dom";
import { Label } from "@/components/Base/Label";
import { ContainerDialog } from "@/components/Layout/ContainerDialog";
import { useConfirmDialog } from "@/components/Common/ConfirmDialog";

export const LayoutCreator = () => {
  const { confirm, Dialog } = useConfirmDialog();

  const shopData = useStore((s) => s.shop);
  const employees = useStore((s) => s.employees ?? []);
  const labels = useStore((s) => s.shop?.customLabels ?? []);
  const layouts = useStore((s) => s.shop?.layouts ?? []);

  const [searchParams, setSearchParams] = useSearchParams();

  const [layout, setLayout] = useState<Layout[] | RGL.Layout[]>(
    layoutModel.generateBaseLayout()
  );
  const shopId = useStore((s) => s.shopId);

  const [newLayoutName, setNewLayoutName] = useState(
    searchParams.get("layout") || ""
  );

  const [layoutAccess, setLayoutAccess] = useState<LayoutAccess>();

  const [addContainerOpen, setAddContainerOpen] = useState(false);
  const [containerToAdd, setContainerToAdd] = useState<string>();
  const currentLayoutContainers = useMemo(() => {
    return layout.map((container) => container.i);
  }, [layout]);

  const layoutAccessArray = shopData?.layoutAccess;

  useEffect(() => {
    const layoutParam = searchParams.get("layout");

    const layoutNames = layouts.map((layout) => Object.keys(layout)[0]);
    if (layoutParam && layoutParam !== newLayoutName) {
      if (layoutNames.includes(layoutParam)) {
        setNewLayoutName(layoutParam);
      } else {
        setNewLayoutName(layoutNames[0] || "");
      }
    }
  }, [searchParams, newLayoutName, layouts]);

  const updateLayoutParam = useCallback(
    (layoutId: string | null) => {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        if (layoutId) {
          newParams.set("layout", layoutId);
        } else {
          newParams.delete("layout");
        }
        return newParams;
      });
    },
    [setSearchParams]
  );

  const getContainerName = (id: string | undefined) => {
    const container = shopData?.containers.find((c) => c.id === id);
    return container?.displayName ?? container?.id ?? "N/A";
  };

  const handleAddContainerToLayout = async (id: string) => {
    const newLayout = layoutRepository.addContainerToLayout(
      id,
      layout.map((l) => toLayout(l))
    );
    setLayout(newLayout);
    setAddContainerOpen(false);
  };

  const removeItem = (id: string) => {
    const layoutCopy = layout.slice();
    const newLayout = layoutCopy.filter((item) => item.i !== id);
    setLayout(newLayout);
  };

  const toLayout = (item: RGL.Layout): Layout => {
    const validEntries = Object.entries(item).filter(
      ([, value]) => value !== undefined
    );
    return Object.fromEntries(validEntries) as Layout;
  };

  const onLayoutChange = (layout: RGL.Layout[]) => {
    setLayout(layout.map((l) => toLayout(l)));
  };

  const handleSelectContainer = (containerId: string) => {
    setContainerToAdd(containerId);
  };

  const handleSelectLayout = (layoutName: string) => {
    if (layoutName === "placeholder") return; // Ignore placeholder selection
    updateLayoutParam(layoutName);
  };

  const updateLayout = useCallback(
    (layoutName: string, layoutAccessArr: LayoutAccess[] | undefined) => {
      if (layoutName !== "") {
        if (layoutName === "placeholder") return; // Ignore placeholder selection
        const layoutIndex = layouts.findIndex(
          (layout) => Object.keys(layout)[0] === layoutName
        );
        if (layoutIndex < 0) return;
        const newLayoutObj = layouts[layoutIndex];
        const keys = Object.keys(newLayoutObj);
        const layout = newLayoutObj[keys[0]];
        setLayout(layout);
        setLayoutAccess(
          layoutAccessArr?.find((access) => access.layoutName === layoutName)
        );
      }
    },
    [layouts]
  );

  useEffect(() => {
    updateLayout(newLayoutName, layoutAccessArray);
  }, [layoutAccessArray, newLayoutName, updateLayout]);

  const handleLayoutDelete = async (layoutToDelete: string) => {
    if (!shopId) return;

    try {
      await confirm({
        title: "Are you sure?",
        description:
          "This will remove the layout and cannot be undone. Are you sure you want to delete this layout?",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return;
    }
    const layoutNames = layouts.map((layout) => Object.keys(layout)[0]);
    const defaultName =
      layoutNames.filter((c) => c !== layoutToDelete)[0] || "";

    try {
      await layoutRepository.deleteLayout(shopId, layoutToDelete);
      showSuccessToast(
        "Layout Deleted",
        "The layout has been deleted successfully."
      );
      updateLayoutParam(defaultName);
      setNewLayoutName(defaultName);
    } catch (error) {
      console.log(error);
      showErrorToast(
        "Error Deleting Layout",
        "There was an error deleting the layout. Please try again."
      );
      return;
    }
  };

  const saveLayout = async (layoutToSave: string) => {
    if (!shopId) return;

    try {
      const { created, updated } = await layoutRepository.updateLayout(
        shopId,
        layoutToSave,
        layout.map((l) => toLayout(l))
      );

      if (created) {
        showSuccessToast(
          "Layout Created",
          "The layout has been created successfully."
        );
      } else if (updated) {
        showSuccessToast(
          "Layout Updated",
          "The layout has been updated successfully."
        );
      }
    } catch (error) {
      console.log(error);
      showErrorToast(
        "Error Saving Layout",
        "There was an error saving the layout. Please try again."
      );
    }
    return;
  };

  const regenerateLayoutAccessArray = (
    layoutName: string,
    updatedAllowedList: string[]
  ) => {
    const updatedLayoutAccessArray = clone(layoutAccessArray || []);
    const layoutIndex = updatedLayoutAccessArray.findIndex(
      (access) => access.layoutName === layoutName
    );

    if (layoutIndex >= 0) {
      updatedLayoutAccessArray[layoutIndex].allowedList = updatedAllowedList;
    } else {
      updatedLayoutAccessArray.push({
        layoutName,
        allowedList: updatedAllowedList,
      });
    }

    return updatedLayoutAccessArray;
  };

  const handleLayoutAccessUpdate = (
    layoutName: string,
    updatedAllowedList: string[]
  ) => {
    if (!shopId) return;

    const newLayoutAccessArray = regenerateLayoutAccessArray(
      layoutName,
      updatedAllowedList
    );

    // Update the shop document with the new array
    shop
      .updateLayoutAccess(shopId, newLayoutAccessArray)
      .then(() => {
        showSuccessToast(
          "Layout Access Updated",
          "The layout access has been updated successfully."
        );
      })
      .catch(() => {
        showErrorToast(
          "Error Updating Layout Access",
          "There was an error updating the layout access. Please try again."
        );
      });
  };

  const handleCreateContainer = async (container: Partial<Container>) => {
    if (!container.displayName) return;
    try {
      const newContainer = await containerRepository.createContainer(
        container.displayName,
        container.linkedTechnicianId,
        container.linkedSourceLabel
      );
      if (!newContainer) {
        showErrorToast(
          "Error Creating Container",
          "There was an error creating the container. Please try again."
        );
        return;
      }
      await handleAddContainerToLayout(newContainer.id);
      showSuccessToast(
        "Container Created",
        "The container has been created and added to the layout."
      );
    } catch (error) {
      console.log(error);
      showErrorToast(
        "Error Creating Container",
        "There was an error creating the container. Please try again."
      );
    }
  };

  return (
    <div className="p-2">
      <Dialog />
      <p>
        Having trouble with your layout?{" "}
        <a
          className="text-red underline"
          href="https://www.shopherodashboard.com/support-request"
          target="_blank"
        >
          Contact
        </a>{" "}
        a Layout Hero.
      </p>
      <div className="mt-4 flex w-full flex-row items-end justify-between gap-4 px-8">
        {/* Layout Managmenet */}
        <div className="flex flex-row items-end gap-2">
          <div className="mr-8">
            <NewLayoutDialog
              newLayout={true}
              layoutName={""}
              existingLayoutNames={layouts.map(Object.keys).flat()}
              onConfirm={(theNewLayoutName: string) => {
                console.log("theNewLayoutName", theNewLayoutName);
                saveLayout(theNewLayoutName);
                handleSelectLayout(theNewLayoutName);
              }}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Layout</Label>
            <Select
              onValueChange={handleSelectLayout}
              value={newLayoutName || "placeholder"} // Use "placeholder" if no layout is selected
            >
              <SelectTrigger className="h-12 w-60 text-primary">
                <SelectValue placeholder="Select Existing Layout" />
              </SelectTrigger>
              <SelectContent className="rounded-md border border-black shadow-lg">
                <SelectItem
                  value="placeholder"
                  disabled
                  className="text-gray-400"
                >
                  Select Existing Layout
                </SelectItem>
                {layouts.map((obj) => {
                  const name = Object.keys(obj)[0];
                  return (
                    <SelectItem
                      key={name}
                      value={name} // Use the name as the value
                      className="text-primary hover:bg-blue-500 hover:text-white"
                    >
                      {name} {/* Display the name */}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </div>

          <Button
            disabled={newLayoutName?.length === 0}
            onClick={() => handleLayoutDelete(newLayoutName)}
            size="lgIcon"
            variant={"destructive"}
            className={"disabled:bg-zinc-700"}
          >
            <Trash2 />
          </Button>
          <NewLayoutDialog
            newLayout={false}
            layoutName={newLayoutName}
            existingLayoutNames={layouts.map(Object.keys).flat()}
            onConfirm={(theNewLayoutName: string) => {
              console.log("theNewLayoutName", theNewLayoutName);
              saveLayout(theNewLayoutName);
              handleSelectLayout(theNewLayoutName);
            }}
          />
          <LayoutAccessDialog
            layoutAccess={layoutAccess}
            onConfirm={(updatedAllowedList) => {
              handleLayoutAccessUpdate(newLayoutName, updatedAllowedList);
            }}
            disabled={newLayoutName.length === 0}
          />
          {newLayoutName && (
            <Tooltip message={`Save ${newLayoutName}`}>
              <Button
                disabled={newLayoutName?.length === 0}
                onClick={() => saveLayout(newLayoutName)}
                variant={"success"}
                size={"lgIcon"}
              >
                <SaveIcon />
              </Button>
            </Tooltip>
          )}
        </div>

        <div className={"flex-grow"} />
        <Link to={`/${shopId}/layouts`}>
          <Button variant={"accent"} size={"xl"}>
            <LayoutTemplateIcon className={"mr-2"} />
            Layout Order
          </Button>
        </Link>
        <Link to={`/${shopId}/layoutBuilder/containers`}>
          <Button variant={"accent"} size={"xl"}>
            <AppWindowMacIcon className={"mr-2"} />
            Manage Containers
          </Button>
        </Link>
      </div>
      {newLayoutName && (
        <div className="m-2.5 mx-4 mt-4 flex flex-col gap-4 rounded-xl border border-primary-foreground p-5">
          <div className="flex flex-row items-center justify-between gap-2">
            <div className="flex-1 text-2xl">
              {newLayoutName !== "" ? newLayoutName : undefined}
            </div>
            <div className={"flex flex-row items-center gap-2"}>
              {addContainerOpen ? (
                <Fragment>
                  <ContainerDialog
                    employees={employees}
                    labels={labels}
                    onSubmit={handleCreateContainer}
                    triggerIcon={<PlusIcon className={"mr-2"} />}
                    triggerVariant={"accent"}
                    containers={shopData?.containers}
                  />
                  <Select
                    value={containerToAdd ?? "placeholder"}
                    onValueChange={handleSelectContainer}
                  >
                    <SelectTrigger className="h-12 w-60 text-primary">
                      <SelectValue placeholder="Select Existing Layout" />
                    </SelectTrigger>
                    <SelectContent className="rounded-md border border-black shadow-lg">
                      <SelectItem
                        value="placeholder"
                        disabled
                        className="text-gray-400"
                      >
                        Select Container
                      </SelectItem>
                      {shopData?.containers
                        .filter((c) => !currentLayoutContainers.includes(c.id))
                        .map((container) => {
                          return (
                            <SelectItem
                              key={container.id}
                              value={container.id} // Use the name as the value
                              className="text-primary hover:bg-blue-500 hover:text-white"
                            >
                              {getContainerName(container.id)}
                            </SelectItem>
                          );
                        })}
                    </SelectContent>
                  </Select>

                  <Tooltip message={`Add ${getContainerName(containerToAdd)}`}>
                    <Button
                      onClick={() =>
                        handleAddContainerToLayout(containerToAdd ?? "")
                      }
                      disabled={!containerToAdd}
                    >
                      <CheckIcon />
                    </Button>
                  </Tooltip>
                </Fragment>
              ) : (
                <Tooltip message={"Add a container"}>
                  <Button onClick={() => setAddContainerOpen(true)}>
                    <PlusIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex w-full flex-col"></div>
          <div className="text-black">
            <GridLayout
              layout={layout}
              onLayoutChange={onLayoutChange}
              removeItem={removeItem}
            />
          </div>
        </div>
      )}
    </div>
  );
};
