import shortUUID from "short-uuid";
import { firestore } from "@/services/firestore";
import { addCreationData, addUpdateData } from "@/lib/database";
import { FirestoreDataConverter, WhereFilterOp } from "firebase/firestore";
import { BaseFirestore } from "@/types/v2";

export const generic = {
  get: async <T>(
    id: string,
    path: string,
    docConverter?: FirestoreDataConverter<T>
  ) => {
    return await firestore.getDocument<T>(`${path}/${id}`, docConverter);
  },
  list: async <T>(
    path: string,
    docConverter?: FirestoreDataConverter<T>,
    limitResults: number = 1000,
    orderByField?: string,
    orderDirection: "asc" | "desc" = "asc"
  ) => {
    return firestore.queryDocuments<T>(
      path,
      "_deleted",
      "!=",
      true,
      docConverter,
      limitResults,
      orderByField,
      orderDirection
    );
  },
  create: async <T extends { id: string }>(
    data: Partial<T>,
    path: string,
    docConverter?: FirestoreDataConverter<T>
  ) => {
    if (!data.id) {
      data.id = shortUUID.generate();
      path += data.id;
    }
    const wCreation = addCreationData(data, "client");
    return (await firestore.createDocument<Partial<T>>(
      `${path}`,
      {
        ...wCreation,
      },
      docConverter
    )) as unknown as T;
  },
  update: async <T extends Partial<BaseFirestore>>(
    data: Partial<T>,
    path: string,
    docConverter?: FirestoreDataConverter<T>
  ) => {
    if (data.createdAt) delete data.createdAt;
    if (data.createdBy) delete data.createdBy;
    const wUpdate = addUpdateData(data);
    await firestore.updateDocument<Partial<T>>(
      `${path}`,
      {
        ...wUpdate,
      },
      docConverter
    );
    return (await firestore.getDocument<T>(`${path}`)) as T;
  },
  delete: async (id: string, path: string, hardDelete?: boolean) => {
    if (hardDelete) {
      return await firestore.deleteDocument(`${path}/${id}`);
    }
    return await firestore.updateDocument(`${path}/${id}`, { _deleted: true });
  },
  subscribeDocument: <T extends { id: string }>(
    id: string,
    path: string,
    callback: (doc: T | undefined) => void
  ) => {
    return firestore.subscribeDocument<T>(`${path}/${id}`, callback);
  },
  subscribeCollection: <T extends { id: string }>(
    path: string,
    callback: (docs: T[]) => void,
    searchParam?: string,
    opsString?: WhereFilterOp,
    equality?: unknown
  ) => {
    return firestore.subscribeCollectionWQuery<T>(
      path,
      callback,
      searchParam,
      opsString,
      equality
    );
  },
};
