import { AppVersion } from "@/components/Common/AppVersion";

export const Footer = () => {
  return (
    <footer className="flex h-4 flex-row justify-between px-2 text-xs">
      <div>ShopHero Dashboard</div>
      <div className="flex-grow"></div>
      <AppVersion />
    </footer>
  );
};
