import { Automation } from "@/types/v2/Automation";
import { Label } from "@/components/Base/Label";
import { useState } from "react";
import { Input } from "@/components/Base/Input";
import { AutomationDisplay } from "@/components/Automations/AutomationDisplay";
import { Button } from "@/components/Base/Button";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  PencilIcon,
  SaveIcon,
  TrashIcon,
} from "lucide-react";
import { AutomationRepository } from "@/models/automation";
import { ConfirmDialog } from "@/components/Dialogs/ConfirmDialog";

interface AutomationItemProps {
  shopId: string;
  automation: Automation;
  canMoveUp?: boolean;
  canMoveDown?: boolean;
}
export const AutomationItem = ({
  shopId,
  automation,
  canMoveUp,
  canMoveDown,
}: AutomationItemProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [localState, setLocalState] = useState(automation);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await AutomationRepository.update(shopId, automation.id, localState);
    } catch (error) {
      console.error(error);
    }
    setIsEditing(false);
    setIsSaving(false);
  };

  const handleEdit = (newAutomation: Automation) => {
    setLocalState(newAutomation);
  };

  const handleDelete = async () => {
    setIsEditing(false);
    setIsSaving(true);
    try {
      await AutomationRepository.delete(shopId, automation.id);
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const handleMoveUp = async () => {
    setIsSaving(true);
    try {
      await AutomationRepository.moveUp(shopId, automation.id);
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const handleMoveDown = async () => {
    setIsSaving(true);
    try {
      await AutomationRepository.moveDown(shopId, automation.id);
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  return (
    <div className="grid grid-cols-12 items-start justify-between gap-4">
      <ConfirmDialog
        open={confirmDeleteOpen}
        onConfirm={handleDelete}
        setOpen={setConfirmDeleteOpen}
        message={`You are about to delete the automation "${automation.name}". This action cannot be undone. Are you sure you want to continue?`}
      />
      <div className="col-span-2 flex flex-col items-start gap-2">
        <Label>Name</Label>
        <Input
          className="text-primary"
          value={localState.name}
          onChange={(e) =>
            setLocalState({ ...localState, name: e.target.value })
          }
          disabled={!isEditing}
        />
      </div>
      <div className="col-span-8 flex flex-col items-start gap-2">
        <Label>Automation</Label>
        <AutomationDisplay
          automation={automation}
          isEditing={isEditing}
          onEdit={handleEdit}
        />
      </div>
      <div className="col-span-2 flex flex-col items-start gap-2">
        <Label>Actions</Label>
        <div className="flex flex-row items-center justify-end gap-2">
          <Button
            variant={"ghost-alt"}
            onClick={handleMoveDown}
            size={"icon"}
            disabled={isSaving || isEditing || !canMoveDown}
          >
            <ArrowDownIcon />
          </Button>
          <Button
            variant={"ghost-alt"}
            onClick={handleMoveUp}
            size={"icon"}
            disabled={isSaving || isEditing || !canMoveUp}
          >
            <ArrowUpIcon />
          </Button>
          <Button
            variant={isEditing ? "accent" : "ghost"}
            onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
            size={"icon"}
            disabled={isSaving}
          >
            {isEditing ? <SaveIcon /> : <PencilIcon />}
          </Button>
          <Button
            variant="destructive"
            onClick={() => setConfirmDeleteOpen(true)}
            size={"icon"}
            disabled={isSaving}
          >
            <TrashIcon />
          </Button>
        </div>
      </div>
    </div>
  );
};
