import { DashboardLayout, Layouts } from "@/types/v2";
import { DataTable } from "@/components/Base/Datatable";
import { Fragment } from "react";
import { layoutColumns } from "@/components/Layout/Layouts.columns";
import { Tooltip } from "@/components/Common/Tooltip";
import { Button } from "@/components/Base/Button";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { layout as layoutRepository } from "@/models/layout";

interface LayoutsTableProps {
  layouts: Layouts;
  onUpdate?: (layout: Layouts) => Promise<void>;
}
export const LayoutsTable = ({ layouts, onUpdate }: LayoutsTableProps) => {
  const handleMoveUp = (layoutId: string) => {
    const layouts = layoutRepository.moveLayoutUp(layoutId);
    if (!layouts) return;
    if (onUpdate) {
      return onUpdate(layouts);
    }
  };

  const handleMoveDown = (layoutId: string) => {
    const layouts = layoutRepository.moveLayoutDown(layoutId);
    if (!layouts) return;
    if (onUpdate) {
      return onUpdate(layouts);
    }
  };

  const actions = (layout: DashboardLayout) => {
    return [
      <Tooltip key={"update"} message={"Move layout down."}>
        <Button
          onClick={() => handleMoveDown(Object.keys(layout)[0])}
          variant="accent"
          id="move_layout_down"
          size="smIcon"
          disabled={
            Object.keys(layouts[layouts.length - 1])[0] ===
            Object.keys(layout)[0]
          }
        >
          <ArrowDownIcon size={20} />
        </Button>
      </Tooltip>,
      <Tooltip key={"update"} message={"Move layout up."}>
        <Button
          onClick={() => handleMoveUp(Object.keys(layout)[0])}
          variant="accent"
          id="move_layout_up"
          size="smIcon"
          disabled={Object.keys(layouts[0])[0] === Object.keys(layout)[0]}
        >
          <ArrowUpIcon size={20} />
        </Button>
      </Tooltip>,
    ];
  };

  return (
    <Fragment>
      <DataTable
        data={layouts}
        columns={layoutColumns({
          actions: actions,
          loading: false,
          layouts,
        })}
        factoryDefaults={{
          pagination: { pageSize: 10 },
        }}
      />
    </Fragment>
  );
};
