import { useEffect } from "react";
import { FlagsProvider, ReactQueryProvider, RouterProvider } from "./providers";
import { useStore } from "./stores/useStore";
import { Spinner } from "./components/Common/Spinner";
import { TooltipProvider } from "./providers/Tooltip.provider";
import { TimeDisplayModeProvider } from "@/components/Common/TimeDisplay";
import { DeltaDisplayModeProvider } from "@/components/Common/DeltaDisplay";

export const App = () => {
  const store = useStore();
  useEffect(() => {
    store.authListener();
  }, []);

  if (!store.initialized) {
    return (
      <div className="flex h-screen flex-col overscroll-none">
        <div className="relative flex flex-grow overflow-hidden border-2 bg-white">
          <main className="flex flex-1 flex-grow flex-col overflow-x-auto overflow-y-auto bg-primary text-primary-foreground">
            <div className="m-auto">
              <Spinner />
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <ReactQueryProvider>
      <FlagsProvider>
        <TooltipProvider>
          <TimeDisplayModeProvider>
            <DeltaDisplayModeProvider>
              <RouterProvider />
            </DeltaDisplayModeProvider>
          </TimeDisplayModeProvider>
        </TooltipProvider>
      </FlagsProvider>
    </ReactQueryProvider>
  );
};
