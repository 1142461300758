import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../../Base/Tooltip";
import { Clock4Icon, TagIcon, UserIcon } from "lucide-react";
import { useStore } from "@/stores/useStore";
import { formatLabels, formatTechnicianNames } from "@/lib/cards";
import { Container as ContainerType } from "@/types/v2";
import { Card } from "@/components/Dashboard/components/Card/Card";
import { useRoles } from "@/hooks/useRoles";
import { useFeature } from "flagged";
import TimeDisplay from "@/components/Common/TimeDisplay";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { firestoreToDateTime } from "@/lib/utils";
import { format, parse, setHours } from "date-fns";
import { TimeInContainerMetric } from "@/types/v2/TimeInContainer";
import DeltaDisplay, {
  useDeltaDisplayMode,
} from "@/components/Common/DeltaDisplay";
import { WorkingDays } from "@/types/v2/ShopConfig";
import {
  DEFAULT_WORKING_HOURS_END,
  DEFAULT_WORKING_HOURS_START,
} from "@/lib/const";
import { container as containerRepository } from "@/models/container";
import { FEATURE_FLAG } from "@/models/featureFlags";

interface ContainerProps extends ContainerType {
  direction: "row" | "column";
  gridArea: string;
  ticker: number;
  draggingId?: string;
}
export const Container = (props: ContainerProps) => {
  const { id, tags: items } = props;
  const { isViewOnly } = useRoles();

  const isManualJobHours = useStore(
    (s) => s.shop?.cardConfig?.cardContents?.manualJobHoursEnabled ?? false
  );
  const isManualCardOnly = useFeature(FEATURE_FLAG.isManualCardOnlyEnabled);
  const employees = useStore((s) => s.employees);
  const metrics = useStore((s) => s.timeInContainerMetrics);
  const shop = useStore((s) => s.shop);
  const cards = useStore((s) => s.cards);
  const container = shop?.containers.find((c) => c.id === id);
  const technicianLinkingEnabled = useFeature("isTechnicianLinkEnabled");
  const labelLinkingEnabled = useFeature("isROLabelLinkEnabled");
  const todayMetrics = metrics
    .find((metric) => metric.id === format(new Date(), "yyyy-MM-dd"))
    ?.timeInContainer.find((met) => met.containerId === id);
  const [averageTimeInContainer, setAverageTimeInContainer] = useState(0);

  const { displayMode: deltaDisplayMode } = useDeltaDisplayMode();
  const currentMetrics = useMemo(
    () =>
      metrics
        .filter((metric) => metric.id === format(new Date(), "yyyy-MM-dd"))
        .map(
          (metric): TimeInContainerMetric => ({
            ...metric,
            timeInContainer: metric.timeInContainer.filter(
              (met) => met.containerId === id
            ),
          })
        ),
    [metrics, id]
  );
  const weekMetrics = useMemo(
    () =>
      metrics
        .filter((metric) => metric.id !== format(new Date(), "yyyy-MM-dd"))
        .map(
          (metric): TimeInContainerMetric => ({
            ...metric,
            timeInContainer: metric.timeInContainer.filter(
              (met) => met.containerId === id
            ),
          })
        ),
    [metrics, id]
  );
  const [weekAverage, setWeekAverage] = useState(0);

  const calculateTimeInContainer = (
    metric?: TimeInContainerMetric,
    cards?: string[]
  ) => {
    // We are going to default to Monday start time if it exists
    const dayStartTime = parseInt(
      (
        shop?.shopConfig?.workingHours[WorkingDays.Monday].startTime ??
        DEFAULT_WORKING_HOURS_START
      ).split(":")[0],
      10
    );
    const dayEndTime = parseInt(
      (
        shop?.shopConfig?.workingHours[WorkingDays.Monday].endTime ??
        DEFAULT_WORKING_HOURS_END
      ).split(":")[0],
      10
    );

    const dayStart = setHours(
      metric
        ? parse(metric.id, "yyyy-MM-dd", new Date())
        : new Date().setHours(0, 0, 0, 0),
      dayStartTime
    );
    const workDayEnd = setHours(
      metric ? parse(metric.id, "yyyy-MM-dd", new Date()) : new Date(),
      dayEndTime
    );
    const now = new Date();
    const timerEnd = workDayEnd > now ? now : workDayEnd;

    const timers =
      metric?.timeInContainer.flatMap((m) =>
        m.cardHistory.map((history) => {
          const enteredAt = firestoreToDateTime(history.enteredAt) as Date;
          const timerEnteredAt = enteredAt > dayStart ? enteredAt : dayStart;
          const exitedAt = firestoreToDateTime(history.exitedAt);
          const timerExitedAt = exitedAt
            ? exitedAt > timerEnd
              ? timerEnd
              : exitedAt
            : timerEnd;

          return {
            ...history,
            enteredAt: timerEnteredAt,
            exitedAt: timerExitedAt,
          };
        })
      ) ?? [];

    // These are cards that were in the container when today started
    const holdOverCards = (
      cards?.filter((card) => !timers.map((t) => t.cardId).includes(card)) ?? []
    ).map((card) => ({
      cardId: card,
      enteredAt: dayStart,
      exitedAt: timerEnd,
    }));

    const durations = [...timers, ...holdOverCards].map(
      (timer) =>
        (timer.exitedAt?.getTime() ?? timer.enteredAt.getTime()) -
        (timer.enteredAt.getTime() ?? timer.enteredAt.getTime())
    );
    const total = durations.reduce((acc, curr) => acc + curr, 0) / 1000;
    return total / durations.length;
  };

  const getWeeklyTime = useCallback(() => {
    const averages = weekMetrics.map((metric) => {
      if (!metric) return 0;
      const time = calculateTimeInContainer(metric);
      return isNaN(time) ? 0 : time;
    });

    setWeekAverage(
      averages.reduce((acc, curr) => acc + curr, 0) /
        Math.max(averages.length, 1)
    );
  }, [weekMetrics]);

  const getCurrentTime = useCallback(() => {
    const averages = currentMetrics.map((metric) => {
      if (!metric) return 0;
      const time = calculateTimeInContainer(metric, items);

      return isNaN(time) ? 0 : time;
    });
    // Unique case when there is no metric yet
    if (averages.length === 0 && items.length > 0) {
      const time = calculateTimeInContainer(undefined, items);
      setAverageTimeInContainer(time);
      return;
    }
    setAverageTimeInContainer(
      averages.reduce((acc, curr) => acc + curr, 0) /
        Math.max(averages.length, 1)
    );
  }, [currentMetrics, items]);

  useEffect(() => {
    const interval = setInterval(() => {
      getWeeklyTime();
      getCurrentTime();
    }, 1000);

    return () => clearInterval(interval);
  }, [todayMetrics, items, getWeeklyTime, getCurrentTime]);

  const { setNodeRef } = useDroppable({
    id,
  });

  const hasLinkedLabel = useMemo(() => {
    if (!props.linkedSourceLabel) return false;
    if (typeof props.linkedSourceLabel === "string")
      return (
        props.linkedSourceLabel.length > 0 && props.linkedSourceLabel !== " "
      );
    return props.linkedSourceLabel && props.linkedSourceLabel.length > 0;
  }, [props.linkedSourceLabel]);

  const hasLinkedTechnician = useMemo(() => {
    if (!props.linkedTechnicianId) return false;
    if (typeof props.linkedTechnicianId === "string")
      return (
        props.linkedTechnicianId.length > 0 && props.linkedTechnicianId !== " "
      );
    if (Array.isArray(props.linkedTechnicianId))
      return props.linkedTechnicianId.length > 0;
    return `${props.linkedTechnicianId}`?.length > 0;
  }, [props.linkedTechnicianId]);

  const containerHours = container
    ? containerRepository.calculateContainerHours(container, cards)
    : { totalApprovedHours: 0, totalCompletedHours: 0 };

  return (
    <div className="relative flex h-full w-full flex-col">
      <div className="sticky top-0 z-10 flex w-full flex-row justify-start p-1">
        <p className="flex-grow items-center align-middle">
          {props.displayName}
          <span className={"ml-2 text-nowrap text-sm text-gray-400"}>
            ({items.length})
          </span>
        </p>
        <div className="text-gray-400">
          {props.linkedSourceLabel && hasLinkedLabel && labelLinkingEnabled && (
            <Tooltip>
              <TooltipTrigger>
                <TagIcon className="h-4 w-4" />
              </TooltipTrigger>
              <TooltipContent>
                {`Linked to ${formatLabels(props.linkedSourceLabel)}`}
              </TooltipContent>
            </Tooltip>
          )}

          {props.linkedTechnicianId &&
            hasLinkedTechnician &&
            employees &&
            technicianLinkingEnabled && (
              <Tooltip>
                <TooltipTrigger>
                  <UserIcon className="h-4 w-4" />
                </TooltipTrigger>
                <TooltipContent>
                  {`Linked to ${formatTechnicianNames(props.linkedTechnicianId, employees)}`}
                </TooltipContent>
              </Tooltip>
            )}
        </div>
      </div>
      <div className="flex-grow overflow-auto">
        <SortableContext
          disabled={isViewOnly}
          id={id}
          items={items}
          strategy={
            props.direction === "row"
              ? horizontalListSortingStrategy
              : verticalListSortingStrategy
          }
        >
          <div
            ref={setNodeRef}
            className="flex flex-row flex-wrap overflow-auto"
          >
            {props.tags.map((tag) => {
              return (
                <Card
                  key={tag}
                  cardId={tag}
                  ticker={props.ticker}
                  tentative={tag === props.draggingId}
                />
              );
            })}
          </div>
        </SortableContext>
      </div>

      <div className="flex w-full flex-row items-center justify-start gap-2 p-1 text-xs text-gray-400">
        {isManualCardOnly ? (
          isManualJobHours ? (
            <Fragment>
              <Clock4Icon size={14} className="h-4 w-4" />
              <Tooltip>
                <TooltipTrigger className={"text-nowrap"}>
                  {Math.round(containerHours.totalApprovedHours * 10) / 10}
                </TooltipTrigger>
                <TooltipContent>Total hours in container</TooltipContent>
              </Tooltip>
            </Fragment>
          ) : null
        ) : (
          <Fragment>
            <Clock4Icon size={14} className="h-4 w-4" />
            <Tooltip>
              <TooltipTrigger className={"text-nowrap"}>
                {Math.round(containerHours.totalCompletedHours * 10) / 10} /{" "}
                {Math.round(containerHours.totalApprovedHours * 10) / 10}
              </TooltipTrigger>
              <TooltipContent>
                Total completed / approved hours in container
              </TooltipContent>
            </Tooltip>
          </Fragment>
        )}
        <div className="flex-grow" />

        {(averageTimeInContainer > 0 || weekAverage > 0) && (
          <div className={"flex flex-row gap-4"}>
            <Tooltip>
              <TooltipTrigger>
                <TimeDisplay
                  size={"xs"}
                  timeInSeconds={Math.max(averageTimeInContainer, 0)}
                />
              </TooltipTrigger>
              <TooltipContent>Average time in container today</TooltipContent>
            </Tooltip>

            <Tooltip>
              <TooltipTrigger>
                <DeltaDisplay
                  delta={Math.max(
                    Math.round((averageTimeInContainer - weekAverage) / 60),
                    0
                  )}
                  units={"m"}
                  size={"xs"}
                >
                  <TimeDisplay
                    size={"xs"}
                    timeInSeconds={weekAverage}
                    disabled
                  />
                </DeltaDisplay>
              </TooltipTrigger>
              <TooltipContent>
                {deltaDisplayMode === 0
                  ? "Average time in container the past week"
                  : "Change in average time compared to the past week"}
              </TooltipContent>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
