import { Spinner } from "@/components/Common/Spinner";

export const Loading = () => {
  return (
    <div
      className="flex flex-grow flex-col items-center justify-center gap-4"
      id="loading"
    >
      <Spinner size="lg" />
    </div>
  );
};
