import { AlertCircleIcon } from "lucide-react";

interface ErrorProps {
  error: string;
}
export const Error = ({ error }: ErrorProps) => {
  return (
    <div className="flex flex-row text-base italic text-destructive">
      <div>
        <AlertCircleIcon className="h-6 w-6" />
      </div>
      <div className="ml-2">{error}</div>
    </div>
  );
};
