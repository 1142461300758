import { AlertCircleIcon } from "lucide-react";
import { cn } from "@/lib/utils.ts";

interface ErrorProps {
  error: string;
  className?: string;
}
export const Error = ({ error, className }: ErrorProps) => {
  return (
    <div
      className={cn(
        "flex flex-row items-center text-base italic text-destructive",
        className
      )}
    >
      <AlertCircleIcon size={16} />
      <div className="ml-2 text-sm">{error}</div>
    </div>
  );
};
