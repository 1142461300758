import {
  CARD_FIELD_OPTIONS,
  Container,
  Layouts,
  Layout,
  Integrators,
  CardColor,
  ShopConfig,
  WorkingDays,
  BuilderTrigger,
  BuilderAction,
  Option,
  HexColor,
} from "../types/v2";

export const DEFAULT_CARD_PRIMARY_FIELD = CARD_FIELD_OPTIONS[0];
export const DEFAULT_CARD_SECONDARY_FIELD = CARD_FIELD_OPTIONS[2];
export const DEFAULT_CARD_TERTIARY_FIELD = CARD_FIELD_OPTIONS[5];

export const APP_ANALYTICS_DELAY_MINS = 60;

export const TEKMETRIC_STATUSES = [
  { value: 1, label: "Estimate" },
  { value: 2, label: "Work-In-Progress" },
  { value: 3, label: "Complete" },
  { value: 4, label: "Saved for later" },
  { value: 8, label: "Accounts Receivable" },
];

const SHOPWARE_STATUSES = [
  { value: "estimate", label: "Estimate" },
  { value: "in_progress", label: "In Progress" },
];

export const STATUS_OPTIONS = {
  [Integrators.tekmetric]: TEKMETRIC_STATUSES,
  [Integrators.shopware]: SHOPWARE_STATUSES,
};

export const DEFAULT_WORKING_HOURS_START = "08:00";
export const DEFAULT_WORKING_HOURS_END = "18:00";
export const DEFAULT_CUSTOMER_UPDATE_TIME = "10:00";

export const DEFAULT_WORKING_HOURS: ShopConfig["workingHours"] = {
  [WorkingDays.Monday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: false,
  },
  [WorkingDays.Tuesday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: false,
  },
  [WorkingDays.Wednesday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: false,
  },
  [WorkingDays.Thursday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: false,
  },
  [WorkingDays.Friday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: false,
  },
  [WorkingDays.Saturday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: true,
  },
  [WorkingDays.Sunday]: {
    startTime: DEFAULT_WORKING_HOURS_START,
    endTime: DEFAULT_WORKING_HOURS_END,
    isClosed: true,
  },
};

export const DEFAULT_TIMER_VALUE = "15";
export const DEFAULT_REFRESH_TIME_IN_SECONDS = 30;

export const DEFAULT_CONTAINER_NAME = "dispatch";
export const DEFAULT_CONTAINERS: Container[] = [
  { id: DEFAULT_CONTAINER_NAME, tags: [] },
];
const DEFAULT_LAYOUT: Layout = {
  h: 48,
  i: DEFAULT_CONTAINER_NAME,
  moved: false,
  static: false,
  w: 6,
  x: 0,
  y: 0,
};

export const DEFAULT_LAYOUTS: Layouts = [{ "Main Layout": [DEFAULT_LAYOUT] }];
export const DEFAULT_STATUSES = {
  [Integrators.tekmetric]: [STATUS_OPTIONS[Integrators.tekmetric][1].value].map(
    (s) => `${s}`
  ),
  [Integrators.shopware]: [STATUS_OPTIONS[Integrators.shopware][1].value],
};

export const DEFAULT_CARD_LABEL_WRAP = "no";
export const DEFAULT_CARD_TIMER_UNITS = "minutes";

export const COLOR_PICKER_COLORS: {
  hex: HexColor;
  label: string;
  name: string;
}[] = [
  {
    hex: "#FF7B7B",
    label: "cpRed",
    name: "Red",
  },
  {
    hex: "#FFD18D",
    label: "cpLightOrange",
    name: "Light Orange",
  },
  {
    hex: "#CCFF1D",
    label: "cpYellow",
    name: "Yellow",
  },
  {
    hex: "#65FF60",
    label: "cpGreen",
    name: "Green",
  },
  {
    hex: "#A8B4FF",
    label: "cpPurple",
    name: "Purple",
  },
  {
    hex: "#C880FF",
    label: "cpVibrantPurple",
    name: "Vibrant Purple",
  },
  {
    hex: "#47FFFF",
    label: "cpCyan",
    name: "Cyan",
  },
  {
    hex: "#FF85D0",
    label: "cpPink",
    name: "Pink",
  },
  {
    hex: "#FFD700",
    label: "cpGold",
    name: "Gold",
  },
  {
    hex: "#FFA500",
    label: "cpOrange",
    name: "Orange",
  },
  {
    hex: "#A9A9A9",
    label: "cpDarkGrey",
    name: "Dark Grey",
  },
  {
    hex: "#E2E2E2",
    label: "cpLightGrey",
    name: "Light Grey",
  },
];

export const DEFAULT_COLORS: CardColor[] = COLOR_PICKER_COLORS.map(
  (color, index) => ({
    id: `defaultColor-${index}`,
    color: color.hex,
    title: color.name,
    description: "",
  })
);

export const DEFAULT_TIMER_RUNNING_UNDER = "#FFFF00";
export const DEFAULT_TIMER_RUNNING_OVER = "#FF0000";

export const DEFAULT_CARD_COLOR = "#E2E2E2";
export const PROMISE_TIME_PAST_COLOR = "#FF7B7B";
export const DEFAULT_CONTAINER_ID = "dispatch";

export const DEFAULT_PAGINATION_SIZE = 25;

export const AUTOMATION_TRIGGER_OPTIONS: BuilderTrigger[] = [
  {
    // When a card is created
    id: "trigger-001",
    triggerObject: "card",
    triggerEventType: "onCreate",
    triggerField: null,
    triggerFieldChange: null,
    triggerCondition: null,
    triggerValueEditable: false,
    triggerValue: null,
    buttonOptions: {
      text: "When a card is created",
      icon: "card",
    },
    conditionalTriggers: [
      {
        id: "trigger-001-1",
        triggerObject: "card",
        triggerEventType: "onCreate",
        triggerField: "isWaiter",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: false,
        triggerValue: true,
        buttonOptions: {
          text: "And is a waiter",
          icon: "clock",
        },
      },
      {
        id: "trigger-001-2",
        triggerObject: "card",
        triggerEventType: "onCreate",
        triggerField: "currentContainer",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueOptionsDynamic: "containers",
        triggerValueEditable: true,
        triggerValue: null,
        triggerPlaceholder: "Dispatch",
        buttonOptions: {
          text: "And Container Name is",
          icon: "card",
        },
      },
      {
        id: "trigger-001-4",
        triggerObject: "card",
        triggerEventType: "onCreate",
        triggerField: "technicianId",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: true,
        triggerValue: null,
        triggerValueOptionsDynamic: "technicians",
        triggerValueOptions: [],
        triggerPlaceholder: "John Doe",
        buttonOptions: {
          text: "And technician is",
          icon: "employee",
        },
      },
      {
        id: "trigger-001-5",
        triggerObject: "card",
        triggerEventType: "onCreate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isGreaterThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is greater than",
          icon: "hourglass",
        },
      },
      {
        id: "trigger-001-6",
        triggerObject: "card",
        triggerEventType: "onCreate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isLessThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is less than",
          icon: "hourglass",
        },
      },
    ],
  },
  {
    // When card is moved into a container
    id: "trigger-002",
    triggerObject: "card",
    triggerEventType: "onUpdate",
    triggerField: "currentContainer",
    triggerFieldChange: "changedTo",
    triggerCondition: null,
    triggerValueEditable: false,
    triggerValue: null,
    buttonOptions: {
      text: "When a card is moved into a container",
      icon: "card",
    },
    conditionalTriggers: [
      {
        id: "trigger-002-1",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "currentContainer",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueOptionsDynamic: "containers",
        triggerValueEditable: true,
        triggerValue: null,
        triggerPlaceholder: "Dispatch",
        buttonOptions: {
          text: "And Container Name is",
          icon: "card",
        },
      },
      {
        id: "trigger-002-2",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "color",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: true,
        triggerValue: null,
        triggerValueOptionsDynamic: "colors",
        triggerValueOptions: DEFAULT_COLORS.map((c) => ({
          value: c.color,
          label: c.title,
        })),
        triggerPlaceholder: "Blue",
        buttonOptions: {
          text: "And Card Color is set to",
          icon: "card",
        },
      },
      {
        id: "trigger-002-3",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "technicianId",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: true,
        triggerValue: null,
        triggerValueOptionsDynamic: "technicians",
        triggerValueOptions: [],
        triggerPlaceholder: "John Doe",
        buttonOptions: {
          text: "And technician is",
          icon: "employee",
        },
      },
      {
        id: "trigger-002-4",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "isWaiter",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: false,
        triggerValue: true,
        triggerPlaceholder: "is",
        buttonOptions: {
          text: "And is a waiter",
          icon: "clock",
        },
      },
      {
        id: "trigger-002-5",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isGreaterThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is greater than",
          icon: "hourglass",
        },
      },
      {
        id: "trigger-002-6",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isLessThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is less than",
          icon: "hourglass",
        },
      },
    ],
  },
  {
    // When card is moved into a container
    id: "trigger-003",
    triggerObject: "card",
    triggerEventType: "onUpdate",
    triggerField: "currentContainer",
    triggerFieldChange: "changedFrom",
    triggerCondition: null,
    triggerValueEditable: false,
    triggerValue: null,
    buttonOptions: {
      text: "When a card is moved out of a container",
      icon: "card",
    },
    conditionalTriggers: [
      {
        id: "trigger-003-1",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "currentContainer",
        triggerFieldChange: "changedFrom",
        triggerCondition: "is",
        triggerValueOptionsDynamic: "containers",
        triggerValueEditable: true,
        triggerValue: null,
        triggerPlaceholder: "Dispatch",
        buttonOptions: {
          text: "And Container Name was",
          icon: "card",
        },
      },
      {
        id: "trigger-003-2",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "color",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: true,
        triggerValue: null,
        triggerValueOptionsDynamic: "colors",
        triggerValueOptions: DEFAULT_COLORS.map((c) => ({
          value: c.color,
          label: c.title,
        })),
        triggerPlaceholder: "Blue",
        buttonOptions: {
          text: "And Card Color is set to",
          icon: "card",
        },
      },
      {
        id: "trigger-003-3",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "technicianId",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: true,
        triggerValue: null,
        triggerValueOptionsDynamic: "technicians",
        triggerValueOptions: [],
        triggerPlaceholder: "John Doe",
        buttonOptions: {
          text: "And technician is",
          icon: "employee",
        },
      },
      {
        id: "trigger-003-4",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "isWaiter",
        triggerFieldChange: null,
        triggerCondition: "is",
        triggerValueEditable: false,
        triggerValue: true,
        triggerPlaceholder: "is",
        buttonOptions: {
          text: "And is a waiter",
          icon: "clock",
        },
      },
      {
        id: "trigger-003-5",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isGreaterThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is greater than",
          icon: "hourglass",
        },
      },
      {
        id: "trigger-003-6",
        triggerObject: "card",
        triggerEventType: "onUpdate",
        triggerField: "totalLaborHours.estimatedLabor",
        triggerFieldChange: null,
        triggerCondition: "isLessThan",
        triggerValueEditable: true,
        triggerValue: 5,
        triggerPlaceholder: "5",
        buttonOptions: {
          text: "And estimated labor is less than",
          icon: "hourglass",
        },
      },
    ],
  },
];

export const DEFAULT_EXPIRATION_TIMES: Option[] = [
  { value: "1", label: "1 minute" },
  { value: "5", label: "5 minutes" },
  { value: "10", label: "10 minutes" },
  { value: "15", label: "15 minutes" },
  { value: "20", label: "20 minutes" },
  { value: "25", label: "25 minutes" },
  { value: "30", label: "30 minutes" },
  { value: "60", label: "1 hour" },
  { value: "120", label: "2 hours" },
];

export const AUTOMATION_ACTION_OPTIONS: BuilderAction[] = [
  {
    // Action-001 has been removed
    id: "action-002",
    actionObject: "card",
    actionEventType: "update",
    actionField: "automationAction",
    actionValue: "wiggle",
    actionValueEditable: false,
    buttonOptions: {
      tooltip: "Wiggles the card.",
      text: "Wiggle Card",
      icon: "card",
    },
    subsequentActions: [
      {
        id: "action-002-01",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionExpireAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the wiggle action for the specified time.",
          text: "For X minutes",
          selectedText: "For %{value} minutes",
          icon: "card",
        },
      },
      {
        id: "action-002-02",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionStartAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the wiggle action after the specified time.",
          text: "After X minutes",
          selectedText: "After %{value} minutes",
          icon: "card",
        },
      },
    ],
  },
  {
    id: "action-003",
    actionObject: "card",
    actionEventType: "update",
    actionField: "automationAction",
    actionValue: "color",
    actionValueEditable: false,
    actionPlaceholder: "Color",
    buttonOptions: {
      tooltip:
        /* eslint-disable-next-line */
        'Sets a temporary color on the card. If you want to set the color without the ability to reset, choose the "Change card color" action.',
      text: "Set card color",
      icon: "card",
    },
    subsequentActions: [
      {
        id: "action-003-01",
        required: true,
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionData",
        actionValue: null,
        actionValueEditable: true,
        actionValueOptionsDynamic: "colors",
        actionValueOptions: DEFAULT_COLORS.map((c) => ({
          value: c.color,
          label: c.title,
        })),
        actionPlaceholder: "red",
        buttonOptions: {
          text: "To ",
          selectedText: "to ",
          icon: "card",
        },
      },
      {
        id: "action-003-02",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionExpireAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the color change action for the specified time.",
          text: "For X minutes",
          selectedText: "For %{value} minutes",
          icon: "card",
        },
      },
      {
        id: "action-003-03",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionStartAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the color change action after the specified time.",
          text: "After X minutes",
          selectedText: "After %{value} minutes",
          icon: "card",
        },
      },
    ],
  },
  {
    id: "action-004",
    actionObject: "card",
    actionEventType: "update",
    actionField: "automationAction",
    actionValue: "colorAndWiggle",
    actionValueEditable: false,
    actionPlaceholder: "Color",
    buttonOptions: {
      tooltip:
        "Wiggles the card and sets the color. Useful if you want to wiggle a card and set a temporary color at the same time.",
      text: "Wiggle and set card color",
      icon: "card",
    },
    subsequentActions: [
      {
        id: "action-004-01",
        required: true,
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionData",
        actionValue: null,
        actionValueEditable: true,
        actionValueOptionsDynamic: "colors",
        actionValueOptions: DEFAULT_COLORS.map((c) => ({
          value: c.color,
          label: c.title,
        })),
        actionPlaceholder: "red",
        buttonOptions: {
          text: "To ",
          selectedText: "to ",
          icon: "card",
        },
      },
      {
        id: "action-004-02",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionExpireAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the wiggle and color action for the specified time.",
          text: "For X minutes",
          selectedText: "For %{value} minutes",
          icon: "card",
        },
      },
      {
        id: "action-004-03",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionStartAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip:
            "Apply the wiggle and color action after the specified time.",
          text: "After X minutes",
          selectedText: "After %{value} minutes",
          icon: "card",
        },
      },
    ],
  },
  {
    id: "action-005",
    actionObject: "card",
    actionEventType: "reset",
    actionField: null,
    actionValue: null,
    actionValueEditable: false,
    buttonOptions: {
      tooltip:
        "Resets all applied automation actions. Useful if you want to reset wiggle and color changes when moving into a specific container.",
      text: "Reset automated changes",
      icon: "card",
    },
  },
  {
    id: "action-006",
    actionObject: "card",
    actionEventType: "update",
    actionField: "automationAction",
    actionValue: "flash",
    actionValueEditable: false,
    actionPlaceholder: "Color",
    buttonOptions: {
      tooltip: "Flashes the card.",
      text: "Flash color",
      icon: "card",
    },
    subsequentActions: [
      {
        id: "action-006-01",
        required: true,
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionData",
        actionValue: null,
        actionValueEditable: true,
        actionValueOptionsDynamic: "colors",
        actionValueOptions: DEFAULT_COLORS.map((c) => ({
          value: c.color,
          label: c.title,
        })),
        actionPlaceholder: "red",
        buttonOptions: {
          text: "To ",
          selectedText: "to ",
          icon: "card",
        },
      },
      {
        id: "action-006-02",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionExpireAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the flash action for the specified time.",
          text: "For X minutes",
          selectedText: "For %{value} minutes",
          icon: "card",
        },
      },
      {
        id: "action-006-03",
        actionObject: "card",
        actionEventType: "update",
        actionField: "automationActionStartAt",
        actionValue: null,
        actionValueEditable: true,
        actionPlaceholder: "5",
        buttonOptions: {
          tooltip: "Apply the flash action after the specified time.",
          text: "After X minutes",
          selectedText: "After %{value} minutes",
          icon: "card",
        },
      },
    ],
  },
  {
    id: "action-007",
    actionObject: "card",
    actionEventType: "update",
    actionField: "color",
    actionValue: null,
    actionValueEditable: true,
    actionValueOptionsDynamic: "colors",
    actionValueOptions: DEFAULT_COLORS.map((c) => ({
      value: c.color,
      label: c.title,
    })),
    actionPlaceholder: "red",
    buttonOptions: {
      tooltip:
        "Change the card color without being able to reset the change. Useful if you want to combine timed actions with color.",
      text: "Change card color to",
      icon: "card",
    },
  },
];
