import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Base/Form";
import { Input } from "@/components/Base/Input";
import { Link } from "react-router-dom";
import { Button } from "@/components/Base/Button";
import shdbLogo from "@/assets/ShdbLogo.png";
import { useStore } from "@/stores/useStore";
import { cn } from "@/lib/utils";

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "Email is required" })
    .email({ message: "Invalid email" }),
  password: z
    .string()
    .min(8, { message: "Password should be of minimum 8 characters length" }),
});

const initialValues = {
  email: "",
  password: "",
};

export const LoginForm = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues,
  });

  const { authError, login } = useStore();

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    return login(values.email, values.password);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="flex min-h-full items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-md space-y-8 rounded-xl border-2 border-white bg-secondary/10 p-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={shdbLogo}
                alt="Shop Hero Logo"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-foreground">
                Login to Get Started
              </h2>
            </div>
            <div className="mt-6 flex flex-col justify-center gap-4"></div>
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      className={"text-primary"}
                      placeholder={"Email"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              name={"email"}
              control={form.control}
            />
            <FormField
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      className={"text-primary"}
                      type={"password"}
                      placeholder={"Password"}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
              name={"password"}
              control={form.control}
            />

            <div className={cn("flex w-96 pb-4", !authError && "invisible")}>
              <div className="w-128 text-destructive">{authError}</div>
            </div>

            <div className={"flex flex-row justify-between"}>
              <Link to="/resetpassword">
                <Button type={"button"}>Forgot Password</Button>
              </Link>
              <Button variant={"accent"} type={"submit"}>
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
};
