import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
} from "firebase/auth";
import { app } from "./app";
import { config } from "@/config";
import { AuthRole } from "@/types/v1";
import { AuthCustomClaims } from "@/types/v2";
import { httpsCallables } from "@/models/httpsCallables";

const auth = getAuth(app);

if (config.emulator) {
  connectAuthEmulator(auth, "http://localhost:9099");
}

const authActions = {
  getUserClaims: async (shopId?: string) => {
    const user = auth.currentUser;
    if (user) {
      let claims = await user.getIdTokenResult(true);
      // Migrate for v3.1.0
      console.log("user", claims.claims as AuthCustomClaims);
      if (
        !(claims.claims as AuthCustomClaims).user &&
        !(claims.claims as AuthCustomClaims).superAdmin
      ) {
        // Migrate User claims
        await httpsCallables.updateUserClaims({
          userId: user.uid,
          userShopId: shopId,
        });
        await user.getIdToken(true);
        claims = await user.getIdTokenResult();
      }
      if (
        shopId &&
        !(claims.claims as AuthCustomClaims).superAdmin &&
        claims.claims.shops &&
        !claims.claims.shops[shopId as keyof AuthCustomClaims["shops"]]
      ) {
        console.log("migrate");
        // Migrate User claims
        await httpsCallables.updateUserClaims({
          userId: user.uid,
          userShopId: shopId,
        });
        await user.getIdToken(true);
        claims = await user.getIdTokenResult();
      }
      return claims.claims as AuthCustomClaims;
    }
    return;
  },
  getUserClaimRole: async () => {
    const claims = await authActions.getUserClaims();
    if (claims?.role) {
      return claims.role as AuthRole;
    }
    if (claims) {
      return "user" as AuthRole;
    }
    return;
  },
  createUserWithEmailAndPassword: async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password);
  },
};

export { auth, authActions };
