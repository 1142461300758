import { CardForm } from "@/components/Dashboard/components/Card/CardForm";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { useRoles } from "@/hooks/useRoles";
import { Forbidden } from "@/components/Page/Forbidden";
import { Loading } from "@/components/Page/Loading";

const DebugCardCreatePage = () => {
  const { isSuperAdmin, isInitialized } = useRoles();
  useIsAuthenticated();

  if (!isInitialized) return <Loading />;
  if (isInitialized && !isSuperAdmin) return <Forbidden />;

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Card Form</h1>
      <div className="flex flex-wrap gap-2">
        <CardForm
          onSubmit={(values) => new Promise(() => console.log(values))}
        />
      </div>
    </div>
  );
};

export default DebugCardCreatePage;
