import {
  PropsWithChildren,
  isValidElement,
  cloneElement,
  ReactNode,
} from "react";
import { TooltipTriggerProps } from "@radix-ui/react-tooltip";
import { TooltipProvider } from "@/providers/Tooltip.provider";
import {
  TooltipTrigger,
  Tooltip as TooltipRoot,
  TooltipContent,
} from "@/components/Base/Tooltip";

export type TooltipProps = PropsWithChildren & {
  message?: string;
  asChild?: boolean;
  tooltipTriggerProps?: TooltipTriggerProps;
  delayDuration?: number;
  disableHoverableContent?: boolean;
  className?: string;
};
export const Tooltip = ({
  message,
  children,
  asChild = true,
  tooltipTriggerProps,
  delayDuration,
  disableHoverableContent,
  className,
}: TooltipProps) => {
  const wrapDisabledElement = (child: ReactNode) => {
    // Check if the child is a valid React element
    if (isValidElement(child)) {
      const isDisabledButton =
        (child.type === "button" || child.props?.disabled !== undefined) &&
        child.props?.disabled === true;

      // If the child is a disabled button, wrap it with a non-interactive element (span)
      if (isDisabledButton) {
        return <div>{cloneElement(child)}</div>;
      }
    }

    // Return original child if no wrapping is needed
    return child;
  };

  return (
    <TooltipProvider
      disableHoverableContent={disableHoverableContent}
      delayDuration={delayDuration ?? 700}
    >
      <TooltipRoot>
        <TooltipTrigger asChild={asChild} {...tooltipTriggerProps}>
          {wrapDisabledElement(children)}
        </TooltipTrigger>
        <TooltipContent className={className}>{message}</TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
};
