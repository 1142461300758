import { ColumnDef } from "@tanstack/react-table";
import { Fragment, ReactNode } from "react";
import { Card, Container, Employee, Layouts } from "@/types/v2";
import { HeaderSortable } from "@/components/Base/HeaderSortable";
import { getEmployeeName } from "@/lib/employees";
import { layout as layoutRepository } from "@/models/layout";
import { container as containerRepository } from "@/models/container";

export type DatatableContainer = Container & {};

interface ColumnProps {
  actions: (container: Container) => ReactNode[];
  loading?: boolean;
  employees?: Employee[];
  layouts?: Layouts;
  cards?: Card[];
}

const createActionsColumn = ({
  actions,
}: ColumnProps): ColumnDef<DatatableContainer> => ({
  accessorKey: "actions",
  header: "",
  cell: ({ row }) => {
    const item = row.original;
    return (
      <div className="flex gap-2">
        {actions(item).map((action, index) => (
          <Fragment key={`action-${item.id}-${index}`}>{action}</Fragment>
        ))}
      </div>
    );
  },
});

const containerColumns = (props?: ColumnProps) => {
  const col: ColumnDef<DatatableContainer>[] = [
    {
      accessorKey: "id",
      header: "ID",
    },
    {
      accessorKey: "displayName",
      header: ({ column }) => <HeaderSortable column={column} title={"Name"} />,

      sortingFn: (rowA, rowB) => {
        const aName = rowA.original.displayName ?? rowA.original.id;
        const bName = rowB.original.displayName ?? rowB.original.id;
        return aName.localeCompare(bName);
      },
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            <div
              className="text-base"
              id="displayName"
            >{`${item.displayName ?? item.id}`}</div>
          </div>
        );
      },
    },
    {
      accessorKey: "linkedTechnicianId",
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Linked Technicians"} />
      ),
      sortingFn: (rowA, rowB) => {
        let aIds = rowA.original.linkedTechnicianId ?? [];
        let bIds = rowB.original.linkedTechnicianId ?? [];
        if (typeof aIds === "string") {
          aIds = [aIds];
        }
        if (typeof bIds === "string") {
          bIds = [bIds];
        }
        if (typeof aIds === "number") {
          aIds = [`${aIds}`];
        }
        if (typeof bIds === "number") {
          bIds = [`${bIds}`];
        }

        const aId = aIds[0];
        const bId = bIds[0];
        const aTechName = getEmployeeName(aId, props?.employees ?? []);
        const bTechName = getEmployeeName(bId, props?.employees ?? []);

        return aTechName.localeCompare(bTechName);
      },
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            {typeof item.linkedTechnicianId === "string" ||
            typeof item.linkedTechnicianId === "number"
              ? getEmployeeName(
                  `${item.linkedTechnicianId}`,
                  props?.employees ?? []
                )
              : item.linkedTechnicianId?.map((id) => (
                  <p key={id}>{getEmployeeName(id, props?.employees ?? [])}</p>
                ))}
          </div>
        );
      },
    },
    {
      accessorKey: "linkedSourceLabel",
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Linked Labels"} />
      ),
      sortingFn: (rowA, rowB) => {
        const aLabels = rowA.original.linkedSourceLabel ?? [];
        const bLabels = rowB.original.linkedSourceLabel ?? [];

        const aLabel = aLabels[0] ?? "";
        const bLabel = bLabels[0] ?? "";
        return aLabel.localeCompare(bLabel);
      },
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            {typeof item.linkedSourceLabel === "string"
              ? item.linkedSourceLabel
              : item.linkedSourceLabel?.map((label) => (
                  <p key={label}>{label}</p>
                ))}
          </div>
        );
      },
    },

    {
      id: "linkedLayouts",
      accessorFn: (originalRow) => {
        return layoutRepository
          .findLayoutsWithContainer(originalRow.id, props?.layouts ?? [])
          .map((layout) => Object.keys(layout)[0])
          .join(", ");
      },
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Layouts Used In"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            {layoutRepository
              .findLayoutsWithContainer(item.id, props?.layouts ?? [])
              .map((layout) => Object.keys(layout))
              .join(", ")}
          </div>
        );
      },
    },
    {
      id: "totalCards",
      accessorFn: (originalRow) => {
        return originalRow.tags.length;
      },
      header: ({ column }) => (
        <HeaderSortable column={column} title={"# Cards"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return <div>{item.tags.length}</div>;
      },
    },
    {
      id: "totalHours",
      accessorFn: (originalRow) => {
        return props?.cards
          ? containerRepository.calculateContainerHours(
              originalRow,
              props?.cards
            ).totalApprovedHours
          : 0;
      },
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Total Hours"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            {props?.cards
              ? containerRepository.calculateContainerHours(item, props?.cards)
                  .totalApprovedHours
              : 0}
          </div>
        );
      },
    },
    {
      id: "completedHours",
      accessorFn: (originalRow) => {
        return props?.cards
          ? containerRepository.calculateContainerHours(
              originalRow,
              props?.cards
            ).totalCompletedHours
          : 0;
      },
      header: ({ column }) => (
        <HeaderSortable column={column} title={"Completed Hours"} />
      ),
      cell: ({ row }) => {
        const item = row.original;
        return (
          <div>
            {props?.cards
              ? containerRepository.calculateContainerHours(item, props?.cards)
                  .totalCompletedHours
              : 0}
          </div>
        );
      },
    },
  ];

  if (props) {
    col.push(createActionsColumn(props));
  }
  return col;
};

export { containerColumns };
