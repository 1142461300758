import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { Button } from "@/components/Base/Button";
import { Input } from "@/components/Base/Input";
import { useState, useEffect, useMemo } from "react";
import { Tooltip } from "@/components/Common/Tooltip";
import { Copy, PlusIcon } from "lucide-react";

interface NewLayoutDialogProps {
  newLayout: boolean;
  layoutName?: string;
  existingLayoutNames: string[];
  onConfirm: (updatedLayoutName: string) => void;
}

export const NewLayoutDialog = ({
  newLayout,
  layoutName,
  existingLayoutNames,
  onConfirm,
}: NewLayoutDialogProps) => {
  const [newLayoutName, setNewLayoutName] = useState(layoutName);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpenChange = () => setOpen(!open);

  const handleLayoutNameChange = (name: string) => {
    setNewLayoutName(name);
  };

  useEffect(() => {
    setNewLayoutName(newLayout ? "" : layoutName);
  }, [newLayout, layoutName]);

  const handleConfirm = () => {
    if (!newLayoutName) return;

    handleOpenChange();
    onConfirm(newLayoutName.trim());
  };

  const saveDisabled = useMemo(() => {
    if (!newLayoutName || !newLayoutName.trim()) {
      setMessage("Layout name cannot be empty");
      return true;
    }

    if (existingLayoutNames.includes(newLayoutName)) {
      setMessage("Layout name already exists");
      return true;
    }

    setMessage("");
    return false;
  }, [newLayoutName, existingLayoutNames]);

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {newLayout ? (
          <Tooltip message={"Create a new layout"}>
            <Button
              onClick={handleOpenChange}
              variant={"accent"}
              size={"lgIcon"}
            >
              <PlusIcon />
            </Button>
          </Tooltip>
        ) : (
          <Tooltip message="Make a copy of this layout">
            <Button
              disabled={newLayoutName?.length === 0}
              onClick={handleOpenChange}
              size="lgIcon"
              className={"disabled:bg-zinc-700"}
            >
              <Copy />
            </Button>
          </Tooltip>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {newLayout
              ? "Create New Layout"
              : `Duplicating Layout "${layoutName}"`}
          </DialogTitle>
          <DialogDescription>Enter a name for the new layout</DialogDescription>
        </DialogHeader>
        <div>
          <Input
            value={newLayoutName}
            onChange={(e) => handleLayoutNameChange(e.target.value)} // Update state with input value
            placeholder="Enter layout name"
          />
          <div className="flex flex-row text-red">{message}</div>
        </div>
        <DialogFooter>
          <Button
            onClick={handleOpenChange}
            variant={"destructive"}
            className="mr-2 bg-zinc-700"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={saveDisabled} // Disable if input is empty or just spaces
            className="bg-[#244C91]"
          >
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
