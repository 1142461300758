import {
  cn,
  convertHoursToMinutes,
  convertMinutesToHours,
  getTextColor,
} from "@/lib/utils";
import { Card } from "@/types/v2";
import { Feature, useFeature } from "flagged";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CardTimer } from "./CardTimer";
import { PlayIcon, StarIcon, StarOffIcon } from "lucide-react";
import { card } from "@/models/cards";
import { JobHourDisplay } from "./JobHourDisplay";
import { ColorPicker } from "../ColorPicker/ColorPicker";
import {
  DEFAULT_TIMER_RUNNING_OVER,
  DEFAULT_TIMER_RUNNING_UNDER,
} from "@/lib/const";
import { Input } from "@/components/Base/Input";
import { FEATURE_FLAG } from "@/models/featureFlags";
import { NotesPopover } from "./NotesPopover";
import { httpsCallables } from "@/models/httpsCallables";
import { useStore } from "@/stores/useStore";

interface CardFooterProps {
  cardData: Card;
  shopId: string;
  startTime: number | "stop" | null;
  ticker: number;
  bgColor: string;
  disabled?: boolean;
}
export const CardFooter = ({
  cardData,
  startTime,
  shopId,
  ticker,
  bgColor,
  disabled,
}: CardFooterProps) => {
  const shopDefaultTimer = useStore((s) => s.defaultTimer);
  const timerUnits = useStore((s) => s.shop?.cardConfig?.timerUnits);
  const [loading, setLoading] = useState(false);
  const isCardColorEnabled = useFeature(FEATURE_FLAG.isCardColorEnabled);
  const isCardColorSyncEnabled = useFeature(
    FEATURE_FLAG.isCardColorSyncEnabled
  );

  // Color Change function
  const onColorChange = (newColor: string) => {
    card.update(shopId, cardData.id, {
      color: newColor,
      bgColor: newColor,
      _isSoftMigrated: cardData._isSoftMigrated,
    });
  };

  const timerValue = useMemo<string>(
    () => cardData.timer?.timeLimitInMinutes ?? shopDefaultTimer,
    [cardData, shopDefaultTimer]
  );

  const [timerViewValue, setTimerViewValue] = useState(
    timerUnits === "hours" ? convertMinutesToHours(timerValue) : timerValue
  );

  useEffect(() => {
    setTimerViewValue(
      timerUnits === "hours" ? convertMinutesToHours(timerValue) : timerValue
    );
  }, [timerUnits, timerValue]);

  const handleUpdateTimerValue = (value: string) => {
    if (timerUnits === "minutes") {
      return setTimerViewValue(value);
    }
    return setTimerViewValue(value);
  };

  const handleTimerManualChange = () => {
    setLoading(true);
    const newValue =
      timerUnits === "hours"
        ? convertHoursToMinutes(timerViewValue)
        : timerViewValue;
    card
      .update(shopId, cardData.id, {
        timer: { ...cardData.timer, timeLimitInMinutes: newValue },
        _isSoftMigrated: cardData._isSoftMigrated,
      })
      .finally(() => setLoading(false));
  };

  const handleStartTimer = () => {
    setLoading(true);

    const timerData = {
      timeStarted: Date.now(),
      minutesEntered: cardData.timer?.timeLimitInMinutes ?? shopDefaultTimer,
      id: cardData.id,
    };
    return httpsCallables
      .updateShopTimers(shopId, cardData.id, timerData)
      .finally(() => setLoading(false));
  };

  const handleStopTimer = () => {
    setLoading(true);
    return httpsCallables
      .updateShopTimers(shopId, cardData.id)
      .finally(() => setLoading(false));
  };

  const handleStarToggle = () => {
    card.markImportant(
      shopId,
      cardData.id,
      cardData.isStarred ? !cardData.isStarred : true
    );
  };

  const getTimerColor = useCallback(
    (time: null | number | "stop") => {
      if (time !== null && time !== "stop") {
        if (Date.now() - time > parseFloat(timerValue) * 60 * 1000) {
          return DEFAULT_TIMER_RUNNING_OVER;
        }
        return DEFAULT_TIMER_RUNNING_UNDER;
      }
      return "transparent";
    },
    [timerValue]
  );

  return (
    <div
      className={cn(
        "flex w-full flex-row items-center justify-between rounded-b-md px-1"
      )}
      style={{
        backgroundColor: getTimerColor(startTime),
        borderTopColor:
          getTextColor(getTimerColor(startTime)) ?? getTextColor(bgColor),
        borderTopWidth: "2px",
        color: getTextColor(getTimerColor(startTime)) ?? getTextColor(bgColor),
      }}
    >
      <div className="flex h-7 max-w-12 flex-row justify-center align-middle">
        {startTime !== null && startTime !== "stop" ? (
          <CardTimer
            key={ticker}
            timeRemaining={Date.now() - startTime}
            stopTimer={handleStopTimer}
            loading={loading}
          />
        ) : (
          <div className="flex w-full items-center justify-between self-center p-0 pb-[0.5px]">
            {loading ? (
              <div className="h-2 w-2 self-center rounded-full bg-white p-0"></div>
            ) : (
              <button
                disabled={disabled}
                className={cn(
                  "flex flex-row self-center bg-none",
                  disabled && "opacity-50"
                )}
                onClick={handleStartTimer}
              >
                <PlayIcon size={14} />
              </button>
            )}
            <Input
              disabled={disabled}
              className="my-0 h-5 rounded-s border-none bg-transparent p-0 focus:bg-white focus:outline-none active:bg-white"
              value={timerViewValue}
              onChange={(e) => handleUpdateTimerValue(e.target.value)}
              onBlur={() => handleTimerManualChange()}
            />
          </div>
        )}
      </div>
      {!isCardColorEnabled && (
        <button
          onClick={() => handleStarToggle()}
          disabled={disabled}
          type="button"
        >
          {cardData.isStarred ? (
            <StarIcon size={14} />
          ) : (
            <StarOffIcon size={14} />
          )}
        </button>
      )}

      <Feature name={FEATURE_FLAG.isCardLaborEnabled}>
        <JobHourDisplay
          totalLaborHours={{
            loggedLabor: card.calculateCompletedHours(cardData),
            estimatedLabor: card.calculateApprovedHours(cardData),
          }}
        />
      </Feature>
      <Feature name={FEATURE_FLAG.isCardNotesEnabled}>
        <NotesPopover cardData={cardData} disabled={disabled} />
      </Feature>
      {!isCardColorSyncEnabled && (
        <Feature name={FEATURE_FLAG.isCardColorEnabled}>
          <ColorPicker
            color={bgColor}
            onColorChange={onColorChange}
            disabled={disabled}
          />
        </Feature>
      )}
    </div>
  );
};
