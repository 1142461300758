import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cn, getTextColor } from "@/lib/utils";
import { useFeature } from "flagged";
import { Card as CardType } from "@/types/v2";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "@/stores/useStore";
import { CardFooter } from "@/components/Dashboard/components/Card/CardFooter";
import { CardHeader } from "@/components/Dashboard/components/Card/Card.Header";
import { CardMain } from "@/components/Dashboard/components/Card/Card.Main";
import { getCardSection } from "@/lib/cards";
import { useRoles } from "@/hooks/useRoles";
import { CardSidebar } from "@/components/Dashboard/components/Card/Card.Sidebar";
import { useCardAnimations } from "@/hooks/useCardAnimations";

interface CardProps {
  cardId: string;
  ticker: number;
  isDragging?: boolean;
  tentative?: boolean;
}

export const Card = ({ cardId, ticker, isDragging, tentative }: CardProps) => {
  const shopId = useStore((s) => s.shopId);
  const shop = useStore((s) => s.shop);
  const timers = useStore((s) => s.timers);
  const employees = useStore((s) => s.employees);
  const timer = useMemo(
    () => (timers ? timers[cardId] : undefined),
    [cardId, timers]
  );
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: cardId });
  const { isViewOnly } = useRoles();

  const [startTime, setStartTime] = useState<"stop" | number | null>(null);

  const isCardColorEnabled = useFeature("isCardColorEnabled") as boolean;
  const isCardColorSyncEnabled = useFeature(
    "isCardColorSyncEnabled"
  ) as boolean;
  const isPromiseTimeEnabled = useFeature("isPromiseTimeEnabled") as boolean;

  const [cardData, setCardData] = useState<CardType>();

  const { cards } = useStore();

  useEffect(() => {
    if (!timer) {
      if (typeof startTime === "number") setStartTime(null);
    }
    if (timer) {
      if (!startTime || startTime === "stop") {
        setStartTime(timer.timeStarted);
      }
    }
  }, [startTime, timer]);

  useEffect(() => {
    if (!shopId) return;
    setCardData(cards.find((card) => card.id === cardId));
  }, [cardId, shopId, cards]);

  const { animationName, getBackgroundColor, automationAction } =
    useCardAnimations({
      cardData: cardData,
      isCardColorSyncEnabled: isCardColorSyncEnabled ?? false,
      isCardColorEnabled: isCardColorEnabled ?? false,
      isPromiseTimeEnabled: isPromiseTimeEnabled ?? false,
      isDragging: isDragging ?? false,
      startTime,
    });

  if (!shopId || !cardData || !shop) return null;

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "mb-1 ml-1 mr-1 flex min-w-[150px] max-w-[150px] flex-1 cursor-auto flex-col items-start rounded-md text-sm shadow-2xl transition-all",
        isDragging && "rotate-6 scale-90 border opacity-75 shadow-2xl",
        tentative && "border opacity-75 shadow-2xl",
        automationAction("wiggle") && "animate-wiggle"
      )}
      style={{
        backgroundColor: getBackgroundColor(),
        color: getTextColor(getBackgroundColor()),
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div
        className={cn(
          "flex w-full flex-grow flex-col rounded-md",
          automationAction("flash") &&
            `animate-${animationName} 2s infinite ease-in-out`
        )}
      >
        <div className="relative flex w-full flex-grow flex-col">
          <div className="flex w-full flex-grow-0 flex-col overflow-hidden">
            <CardHeader
              disabled={isViewOnly}
              cardData={cardData}
              cardId={cardId}
              primaryLabel={getCardSection(cardData, shop, "primaryField")}
              dndAttributes={attributes}
              dndListeners={listeners}
            />
            <CardMain
              cardData={cardData}
              cardId={cardId}
              shop={shop}
              shopId={shopId}
              ticker={ticker}
              dndAttributes={attributes}
              dndListeners={listeners}
            />
            <CardSidebar
              shopId={shopId}
              cardId={cardId}
              cardData={cardData}
              employees={employees}
              disabled={isViewOnly}
            />
          </div>
          {/* PRIMARY LABEL */}

          {/* Center of button */}
        </div>
        <div className="flex-grow" />

        <CardFooter
          disabled={isViewOnly}
          ticker={ticker}
          cardData={cardData}
          shopId={shopId}
          startTime={startTime}
          bgColor={getBackgroundColor(true)}
        />
      </div>
    </div>
  );
};
