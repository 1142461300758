import { useStore } from "@/stores/useStore";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { LayoutAccess } from "@/types/v2";
import { Button } from "@/components/Base/Button";
import { CheckboxInput } from "@/components/Base/CheckboxInput";
import { useState, useEffect, useMemo } from "react";
import { Tooltip } from "@/components/Common/Tooltip";
import { ShieldBan } from "lucide-react";

interface LayoutAccessDialogProps {
  layoutAccess?: LayoutAccess;
  onConfirm?: (updatedAllowedList: string[]) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const LayoutAccessDialog = ({
  layoutAccess,
  onConfirm,
  loading,
  disabled,
}: LayoutAccessDialogProps) => {
  const employeesBase = useStore((s) => s.employees);
  const employees = useMemo(
    () => employeesBase?.filter((e) => !e.hideInShopHero) ?? [],
    [employeesBase]
  );

  const [open, setOpen] = useState(false);

  // Local state for allowed list
  const [localAllowedList, setLocalAllowedList] = useState<string[]>([]);

  useEffect(() => {
    // Initialize localAllowedList based on layoutAccess.allowedList or default to all employees
    if (layoutAccess?.allowedList && layoutAccess.allowedList.length > 0) {
      setLocalAllowedList(layoutAccess.allowedList);
    } else {
      setLocalAllowedList(employees?.map((employee) => employee.id) || []);
    }
  }, [layoutAccess, employees]);

  const handleOpenChange = () => setOpen(!open);

  const handleCheckboxChange = (id: string) => {
    setLocalAllowedList(
      (prev) =>
        prev.includes(id)
          ? prev.filter((item) => item !== id) // Remove email if already in the list
          : [...prev, id] // Add email if not in the list
    );
  };

  const handleConfirm = () => {
    // Pass the updated allowed list back to the parent
    if (onConfirm) {
      onConfirm(localAllowedList);
    }
    handleOpenChange();
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Tooltip message="Configure layout access">
          <Button
            disabled={disabled}
            onClick={handleOpenChange}
            size="lgIcon"
            className={"disabled:bg-zinc-700"}
          >
            <ShieldBan />
          </Button>
        </Tooltip>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select the users that can view this layout</DialogTitle>
        </DialogHeader>
        <div>
          {employees.map((employee) => (
            <CheckboxInput
              key={employee.id}
              label={employee.firstName + " " + employee.lastName}
              value={employee.id}
              checked={localAllowedList.includes(employee.id)}
              onClick={() => handleCheckboxChange(employee.id)}
              className={"mb-2 border-black"}
            />
          ))}
        </div>
        <DialogFooter className="sm:justify-between">
          <Button
            onClick={handleOpenChange}
            variant={"primary-alt"}
            disabled={loading}
            className={"bg-zinc-300"}
          >
            Cancel
          </Button>
          <Button onClick={handleConfirm} disabled={loading} variant={"accent"}>
            Save
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
