import { store } from "@/stores/useStore";
import clone from "just-clone";
import { shop } from "./shop";
import { Card, Container } from "@/types/v2";
import shortUUID from "short-uuid";
import { layout as layoutRepository } from "@/models/layout";
import { card as cardRepository } from "@/models/cards";

export const container = {
  updateContainer: (id: string, container: Partial<Container>) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);
    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    // We can only update displayName, linkedTechnicianId, and linkedSourceLabel
    containers[containerIndex].displayName =
      container.displayName ?? containers[containerIndex].displayName;
    containers[containerIndex].linkedTechnicianId =
      container.linkedTechnicianId ??
      containers[containerIndex].linkedTechnicianId;
    containers[containerIndex].linkedSourceLabel =
      container.linkedSourceLabel ??
      containers[containerIndex].linkedSourceLabel;
    return shop.update(shopId, { containers: containers });
  },
  updateContainerName: (id: string, displayName: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].displayName = displayName;
    shop.update(shopId, { containers: containers });
  },

  updateContainerTechnician: (id: string, technicianId: string | string[]) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].linkedTechnicianId = technicianId;
    shop.update(shopId, { containers: containers });
  },
  deleteContainerTechnician: (id: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    delete containers[containerIndex].linkedTechnicianId;
    shop.update(shopId, { containers: containers });
  },

  updateContainerLabel: (id: string, label: string | string[]) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers[containerIndex].linkedSourceLabel = label;
    shop.update(shopId, { containers: containers });
  },
  deleteContainerLabel: (id: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const containers = clone(store.getState().containers);

    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    delete containers[containerIndex].linkedSourceLabel;
    shop.update(shopId, { containers: containers });
  },
  createContainer: async (
    displayName: string,
    linkedTechnicianId: Container["linkedTechnicianId"] = [],
    linkedSourceLabel: Container["linkedSourceLabel"] = []
  ) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;
    const id = shortUUID.generate();
    const container: Container = {
      id,
      tags: [],
      displayName,
      linkedTechnicianId,
      linkedSourceLabel,
    };

    const containers = clone(store.getState().containers);
    containers.push(container);

    await shop.update(shopId, { containers: containers });
    return container;
  },
  deleteContainer: async (id: string) => {
    const shopId = store.getState().shopId;
    if (!shopId) return;

    const layouts = layoutRepository.removeContainerFromAllLayouts(id);
    await layoutRepository.saveLayouts(shopId, layouts);

    const containers = clone(store.getState().containers);
    const containerIndex = containers.findIndex((c) => c.id === id);
    if (containerIndex === -1) return;
    containers.splice(containerIndex, 1);
    await shop.update(shopId, { containers: containers });
    return;
  },
  calculateContainerHours: (container: Container, cards: Card[]) => {
    const cardsInContainer = cards.filter((c) => container.tags.includes(c.id));
    const approvedHours = cardsInContainer.map((card) =>
      cardRepository.calculateApprovedHours(card)
    );
    const totalApprovedHours = approvedHours.reduce((a, b) => a + b, 0);
    const completedHours = cardsInContainer.map((card) =>
      cardRepository.calculateCompletedHours(card)
    );
    const totalCompletedHours = completedHours.reduce((a, b) => a + b, 0);
    return {
      totalApprovedHours,
      totalCompletedHours,
    };
  },
};
