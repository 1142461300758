import { Button } from "@/components/Base/Button";
import { Input } from "@/components/Base/Input";
import { Heading } from "@/components/Common/Heading";
import { cn } from "@/lib/utils";
import { useStore } from "@/stores/useStore";
import { useState } from "react";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");

  const { authError, resetPassword } = useStore();

  const handlePasswordReset = () => {
    resetPassword(email);
  };

  return (
    <div className="m-auto flex flex-col gap-4 bg-primary text-primary-foreground">
      <Heading>Reset Your Password</Heading>
      <div>
        <label htmlFor="email">Email:</label>
        <Input
          className="text-primary"
          type="text"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className={cn("flex w-96 pb-4", !authError && "invisible")}>
        <div className="w-128 text-destructive">{authError}</div>
      </div>

      <Button variant="secondary" type="button" onClick={handlePasswordReset}>
        Request Password Reset
      </Button>
    </div>
  );
};

export default ResetPasswordPage;
