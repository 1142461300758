import {
  Avatar as AvatarBase,
  AvatarFallback,
  AvatarImage,
} from "@/components/Base/Avatar";
import * as React from "react";
import { cn, getTextColor } from "@/lib/utils";
import { forwardRef } from "react";
import { ClassNameValue } from "tailwind-merge";

export type AvatarProps = React.ComponentPropsWithoutRef<typeof AvatarBase> & {
  src?: string;
  fallback?: string;
  fallbackClassName?: ClassNameValue;
  bgColor?: string;
};

const Avatar = forwardRef<React.ElementRef<typeof AvatarBase>, AvatarProps>(
  ({ className, src, fallback, fallbackClassName, bgColor, ...props }, ref) => (
    <AvatarBase
      ref={ref}
      className={cn(!bgColor && "bg-primary", className)}
      {...props}
      style={{ backgroundColor: bgColor }}
    >
      <AvatarImage src={src} />
      <AvatarFallback
        className={cn(
          !bgColor && "bg-primary text-primary-foreground",
          "border border-primary",
          fallbackClassName
        )}
        style={{
          backgroundColor: bgColor,
          color: bgColor ? getTextColor(bgColor) : undefined,
        }}
      >
        {fallback}
      </AvatarFallback>
    </AvatarBase>
  )
);
Avatar.displayName = AvatarBase.displayName;

export { Avatar };
