import { Button } from "@/components/Base/Button";
import { SendIcon } from "lucide-react";
import { ReactNode } from "react";
import Tiptap from "@/components/Base/TipTap";

export interface NewMessageInputProps {
  disabled?: boolean;
  onSend?: () => void;
  sendIcon?: ReactNode;
  sendText?: string;
  onDiscard?: () => void;
  discardIcon?: ReactNode;
  discardText?: string;
  value?: string;
  onChange?: (value: string) => void;
}
export const NewMessageInput = ({
  disabled,
  onSend,
  value,
  onChange,
  sendIcon = <SendIcon size={18} className={"mr-0 sm:mr-2"} />,
  sendText = "Send",
  discardIcon,
  discardText = "Cancel",
  onDiscard,
}: NewMessageInputProps) => {
  return (
    <div className={"relative w-full"}>
      <Tiptap
        hideUndoRedo={true}
        onUpdate={(editor) => onChange?.(editor.editor.getHTML())}
        value={value}
        slotAfter={
          <div className={"my-2 flex w-full flex-row items-end justify-end"}>
            {onDiscard && (
              <Button
                size={"sm"}
                variant={"secondary"}
                disabled={disabled}
                onClick={onDiscard}
              >
                {discardIcon}
                <span className={"hidden sm:inline-block"}>{discardText}</span>
              </Button>
            )}
            <Button
              size={"sm"}
              variant={"accent"}
              disabled={disabled}
              onClick={onSend}
            >
              {sendIcon}
              <span className={"hidden sm:inline-block"}>{sendText}</span>
            </Button>
          </div>
        }
      />
    </div>
  );
};
