import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/Base/Dialog";
import { ReactNode, useEffect, useState } from "react";
import {
  ContainerForm,
  ContainerFormProps,
  ContainerFormValues,
} from "@/components/Layout/ContainerForm";
import { Button, ButtonProps } from "@/components/Base/Button";

export interface ContainerDialogProps<T>
  extends React.ComponentProps<typeof Dialog>,
    ContainerFormProps<T> {
  trigger?: ReactNode;
  externalTrigger?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  triggerIcon?: ReactNode;
  triggerVariant?: ButtonProps["variant"];
}
export const ContainerDialog = <T extends ContainerFormValues>({
  containerData,
  trigger,
  externalTrigger,
  open,
  onOpenChange,
  triggerIcon,
  triggerVariant,
  onSubmit,
  onCancel,
  ...props
}: ContainerDialogProps<T>) => {
  const [isOpen, setIsOpen] = useState(open ?? false);

  // Sync local state with the controlled `open` prop
  useEffect(() => {
    if (typeof open === "boolean") {
      setIsOpen(open);
    }
  }, [open]);

  // Handle the dialog's open state changes
  const handleOpenChange = (newOpen: boolean) => {
    setIsOpen(newOpen);
    onOpenChange?.(newOpen); // Notify parent if `onOpenChange` is provided
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    handleOpenChange(false);
  };

  const handleSubmit = async (values: T) => {
    if (onSubmit) {
      await onSubmit(values);
    }
    handleOpenChange(false);
  };

  return (
    <Dialog onOpenChange={handleOpenChange} open={isOpen}>
      {trigger && !externalTrigger ? (
        // Use custom trigger if provided
        <DialogTrigger asChild>{trigger}</DialogTrigger>
      ) : (
        // Default trigger button
        !externalTrigger && (
          <DialogTrigger asChild={true}>
            <Button variant={triggerVariant}>
              {triggerIcon}
              {containerData ? "Edit Container" : "Create Container"}
            </Button>
          </DialogTrigger>
        )
      )}
      <DialogContent className="m-0 flex max-h-screen flex-col gap-1 p-4">
        <DialogHeader>
          <DialogTitle>
            {containerData ? "Edit Container" : "Create Container"}
          </DialogTitle>
          <DialogDescription>
            {containerData
              ? `Edit container ${containerData.displayName ?? containerData.id}`
              : "Enter the container name to create a new container"}
          </DialogDescription>
          <ContainerForm
            {...props}
            containerData={containerData}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
