import {
  Automation,
  Trigger as TriggerModel,
  Action as ActionModel,
} from "@/types/v2/Automation";
import { Trigger } from "@/components/Automations/Trigger";
import { Action } from "@/components/Automations/Action";
import {
  AUTOMATION_ACTION_OPTIONS,
  AUTOMATION_TRIGGER_OPTIONS,
} from "@/lib/const";
import { Fragment } from "react";
import { flattenTriggers } from "@/lib/flattenTriggers";
import { useDynamicOptions } from "@/hooks/useDynamicOptions";
import { flattenActions } from "@/lib/flattenActions";

export interface AutomationDisplayProps {
  automation: Automation;
  isEditing?: boolean;
  onEdit?: (automation: Automation) => void;
}
export const AutomationDisplay = ({
  automation,
  isEditing,
  onEdit,
}: AutomationDisplayProps) => {
  const { generateOptions } = useDynamicOptions();
  const handleTriggerChange = (trigger: TriggerModel, index: number) => {
    const oldAutomation = { ...automation };
    const oldTriggers = [...oldAutomation.triggers];
    oldTriggers[index] = trigger;
    if (onEdit) {
      onEdit({ ...oldAutomation, triggers: oldTriggers });
    }
  };

  const handleActionChange = (action: ActionModel, index: number) => {
    const oldAutomation = { ...automation };
    const oldActions = [...oldAutomation.actions];
    oldActions[index] = action;
    if (onEdit) {
      onEdit({ ...oldAutomation, actions: oldActions });
    }
  };

  const renderTrigger = (trigger: TriggerModel, index: number) => {
    const templateTrigger = flattenTriggers(AUTOMATION_TRIGGER_OPTIONS).find(
      (t) => t.id === trigger.templateId
    );
    console.log(
      generateOptions(
        templateTrigger?.triggerValueOptions,
        templateTrigger?.triggerValueOptionsDynamic
      )
    );
    return (
      <Trigger
        label={
          templateTrigger?.buttonOptions.selectedText ??
          templateTrigger?.buttonOptions.text ??
          ""
        }
        valueValue={
          trigger.triggerValue ? `${trigger.triggerValue}` : undefined
        }
        canRemove={false}
        canAdd={false}
        disabled={!isEditing}
        valueOptions={generateOptions(
          templateTrigger?.triggerValueOptions,
          templateTrigger?.triggerValueOptionsDynamic
        )}
        onValueChange={
          trigger.triggerValue
            ? (value) =>
                handleTriggerChange({ ...trigger, triggerValue: value }, index)
            : undefined
        }
      />
    );
  };

  const renderAction = (action: ActionModel, index: number) => {
    const templateAction = flattenActions(AUTOMATION_ACTION_OPTIONS).find(
      (t) => t.id === action.templateId
    );
    console.log(
      generateOptions(
        templateAction?.actionValueOptions,
        templateAction?.actionValueOptionsDynamic
      )
    );
    return (
      <Action
        label={
          templateAction?.buttonOptions.selectedText ??
          templateAction?.buttonOptions.text ??
          ""
        }
        valueValue={
          templateAction?.actionValueEditable && action.actionValue
            ? `${action.actionValue}`
            : undefined
        }
        valueOptions={generateOptions(
          templateAction?.actionValueOptions,
          templateAction?.actionValueOptionsDynamic
        )}
        canRemove={false}
        canAdd={false}
        disabled={!isEditing}
        onValueChange={
          action.actionValue && templateAction?.actionValueEditable
            ? (value) =>
                handleActionChange({ ...action, actionValue: value }, index)
            : undefined
        }
      />
    );
  };
  return (
    <div
      className={
        "flex w-full flex-row flex-wrap items-center gap-2 rounded-lg bg-primary-foreground px-4 py-2"
      }
    >
      {automation.triggers.map((trigger, index) => (
        <Fragment key={`${trigger.templateId}-${index}`}>
          <div key={`${trigger.templateId}-${index}`}>
            {renderTrigger(trigger, index)}
          </div>
          {index + 1 < automation.triggers.length && (
            <span className={"mr-2 font-mono text-sm text-primary/80"}>
              {automation.triggersConditional}
            </span>
          )}
        </Fragment>
      ))}
      <div
        className={
          "mr-2 flex flex-row items-center font-mono text-sm text-primary/80"
        }
      >
        THEN
      </div>
      {automation.actions.map((action, index) => (
        <Fragment key={`${action.templateId}-${index}`}>
          <div key={`${action.templateId}-${index}`}>
            {renderAction(action, index)}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
