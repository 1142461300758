import { Card } from "@/types/v1";
import { ClockIcon } from "lucide-react";
import { useStore } from "@/stores/useStore";
import { useFeature } from "flagged";
import { FEATURE_FLAG } from "@/models/featureFlags";

type JobHourDisplayProps = Pick<Card, "totalLaborHours">;
export const JobHourDisplay = ({ totalLaborHours }: JobHourDisplayProps) => {
  const isManualJobHours = useStore(
    (s) => s.shop?.cardConfig?.cardContents?.manualJobHoursEnabled ?? false
  );
  const isManualCardOnly = useFeature(FEATURE_FLAG.isManualCardOnlyEnabled);

  if (!isManualJobHours && isManualCardOnly) return null;

  return (
    <div className="flex flex-row items-center justify-center">
      <ClockIcon size={14} />
      {isManualCardOnly ? (
        <p className="ml-1 text-xs">{`${Math.round((totalLaborHours?.estimatedLabor ?? 0) * 10) / 10}`}</p>
      ) : (
        <p className="text-xs">{`${
          Math.round((totalLaborHours?.loggedLabor ?? 0) * 10) / 10
        }/${Math.round((totalLaborHours?.estimatedLabor ?? 0) * 10) / 10}`}</p>
      )}
    </div>
  );
};
