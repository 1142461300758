import { Content, EditorProvider, useCurrentEditor } from "@tiptap/react";
import { Button } from "@/components/Base/Button";
import {
  BoldIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  ListIcon,
  ListOrderedIcon,
  RedoIcon,
  StrikethroughIcon,
  UndoIcon,
} from "lucide-react";
import { Separator } from "@/components/Base/Separator";
import * as React from "react";
import { StarterKit } from "@tiptap/starter-kit";
import { cn } from "@/lib/utils";
import { ClassNameValue } from "tailwind-merge";
import { useEffect } from "react";

// define your extension array
const extensions = [StarterKit];

const content = "";

const MenuBar = ({ hideUndoRedo }: { hideUndoRedo?: boolean }) => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  const iconClassName: ClassNameValue = "h-5 w-5 sm:h-6 sm:w-6";

  return (
    <div className="flex flex-row items-center justify-between gap-1 rounded-t-md border bg-secondary p-2 sm:gap-4">
      <div className="flex flex-row items-center justify-between sm:gap-1">
        <Button
          size={"icon"}
          className={iconClassName}
          variant={editor.isActive("bold") ? "default" : "ghost"}
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
        >
          <BoldIcon size={16} />
        </Button>
        <Button
          size={"icon"}
          className={iconClassName}
          variant={editor.isActive("italic") ? "default" : "ghost"}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
        >
          <ItalicIcon size={16} />
        </Button>
        <Button
          size={"icon"}
          className={iconClassName}
          variant={editor.isActive("strike") ? "default" : "ghost"}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
        >
          <StrikethroughIcon size={16} />
        </Button>
      </div>
      <Separator orientation="vertical" className="h-6 bg-primary" />
      <div className="flex flex-row items-center justify-between gap-1">
        <Button
          size={"icon"}
          className={iconClassName}
          variant={
            editor.isActive("heading", { level: 1 }) ? "default" : "ghost"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          <Heading1Icon size={16} />
        </Button>
        <Button
          size={"icon"}
          className={iconClassName}
          variant={
            editor.isActive("heading", { level: 2 }) ? "default" : "ghost"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          <Heading2Icon size={16} />
        </Button>
        <Button
          size={"icon"}
          className={iconClassName}
          variant={
            editor.isActive("heading", { level: 3 }) ? "default" : "ghost"
          }
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          <Heading3Icon size={16} />
        </Button>
      </div>
      <Separator orientation="vertical" className="h-6 bg-primary" />
      <div className="flex flex-row items-center justify-between gap-1">
        <Button
          size={"icon"}
          className={iconClassName}
          variant={editor.isActive("bulletList") ? "default" : "ghost"}
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <ListIcon size={16} />
        </Button>
        <Button
          size={"icon"}
          className={iconClassName}
          variant={editor.isActive("orderedList") ? "default" : "ghost"}
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <ListOrderedIcon size={16} />
        </Button>
      </div>
      <div className="flex-grow"></div>
      {hideUndoRedo ? null : (
        <div className="hidden flex-row items-center justify-between gap-1 sm:flex">
          <Button
            size={"icon"}
            className={iconClassName}
            onClick={() => editor.chain().focus().undo().run()}
            disabled={!editor.can().chain().focus().undo().run()}
          >
            <UndoIcon size={16} />
          </Button>
          <Button
            size={"icon"}
            className={iconClassName}
            onClick={() => editor.chain().focus().redo().run()}
            disabled={!editor.can().chain().focus().redo().run()}
          >
            <RedoIcon size={16} />
          </Button>
        </div>
      )}
    </div>
  );
};
interface TiptapControlledProps {
  value?: Content | undefined;
}

const TiptapControlled = ({ value }: TiptapControlledProps) => {
  const { editor } = useCurrentEditor();
  useEffect(() => {
    if (value && value !== editor?.getHTML()) {
      editor?.commands.setContent(value);
    }
  }, [value]);
  return null;
};

const Tiptap = ({
  editorProps,
  hideUndoRedo,
  value,
  ...props
}: React.ComponentPropsWithoutRef<typeof EditorProvider> &
  TiptapControlledProps & {
    hideUndoRedo?: boolean;
  }) => {
  return (
    <EditorProvider
      extensions={extensions}
      content={content}
      slotBefore={<MenuBar hideUndoRedo={hideUndoRedo} />}
      editorProps={{
        ...editorProps,
        attributes: {
          ...editorProps?.attributes,
          class: cn(
            "prose-sm sm:prose min-h-[80px] w-full rounded-b-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            // @ts-expect-error For some reason, type is not in it
            editorProps?.attributes?.class
          ),
        },
      }}
      {...props}
    >
      <TiptapControlled value={value} />
    </EditorProvider>
  );
};

export default Tiptap;
