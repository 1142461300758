import { AutomationsConfiguration } from "@/components/Automations/AutomationsConfiguration";

export const AutomationsPage = () => {
  return (
    <div className="flex-start mx-auto mb-20 flex h-full w-full max-w-screen-xl flex-col overflow-hidden p-5 text-justify">
      <h1 className="text-3xl font-bold">Automations</h1>
      <AutomationsConfiguration />
    </div>
  );
};
