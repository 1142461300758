import { formatDate } from "@/lib/utils";

export interface DateHeadingProps {
  date: Date;
}

export const DateHeading = ({ date }: DateHeadingProps) => {
  return (
    <div
      className={
        "mx-auto flex-row items-center justify-center rounded-md bg-primary p-1 text-white"
      }
    >
      <div className="mx-4 text-center font-bold">{formatDate(date, true)}</div>
    </div>
  );
};
