import { NotebookPenIcon } from "lucide-react";
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/Base/Popover";
import { useStore } from "@/stores/useStore";
import { Card } from "@/types/v2";
import { Notes } from "./Notes";
import { Badge } from "@/components/Base/Badge";
import { cn } from "@/lib/utils";

export interface NotesPopoverProps {
  cardData: Card;
  disabled?: boolean;
}
export const NotesPopover = ({ cardData, disabled }: NotesPopoverProps) => {
  const shopId = useStore((s) => s.shopId);
  const [open, setOpen] = useState(false);

  if (!shopId) return null;
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger
        disabled={disabled && (cardData.notes?.length === 0 || !cardData.notes)}
        className={cn(
          "z-5 relative cursor-pointer rounded-full hover:bg-white/50",
          disabled &&
            (cardData.notes?.length === 0 || !cardData.notes) &&
            "cursor-default opacity-50"
        )}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <NotebookPenIcon size={14} />
        {cardData.notes && cardData.notes.length > 0 && (
          <Badge
            className="absolute right-0 top-0 h-2 w-2"
            variant={"accent"}
            type={"indicator"}
          />
        )}
      </PopoverTrigger>
      <PopoverContent
        onMouseDown={(e) => e.stopPropagation()}
        className="flex flex-col gap-2 bg-muted p-2"
      >
        {shopId && (
          <Notes
            shopId={shopId}
            cardData={cardData as Card}
            disabled={disabled}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};
