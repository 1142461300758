import { card } from "@/models/cards";
import { formatArrayAsString } from "./utils";
import { auth } from "@/services/auth";
import { ActivityFeed, Card, CardFieldKeys, Employee, Shop } from "@/types/v2";
import shortUUID from "short-uuid";
import { Employee as EmployeeV1 } from "@/types/v1";
import { httpsCallables } from "@/models/httpsCallables";

/**
 * Removes a card by updating its status to "manuallyClosed" and then
 * moves the card using an HTTPS callable function.
 * @param {string} shopId - The unique identifier for the shop.
 * @param {string} cardId - The unique identifier for the card.
 * @returns {Promise<any>} A promise that resolves with the result of the HTTPS callable moveCard function.
 */
export async function removeCard(shopId: string, cardId: string) {
  await card.update(shopId, cardId, { status: "manuallyClosed" });
  return await httpsCallables.moveCard({ shopId, cardId });
}

/**
 * Formats the given labels. If a single string is provided, it returns the string.
 * If an array of strings is provided, it formats the array as a single concatenated string.
 * @param {string|string[]} labels - The labels to be formatted. This can be a single string or an array of strings.
 * @returns {string} The formatted string.
 */
export function formatLabels(labels: string | string[]) {
  if (typeof labels === "string") {
    return labels;
  }
  return formatArrayAsString(labels);
}

/**
 * Adds a new note to a card identified by `shopId` and `cardId`. If `cardData` is not provided,
 * it fetches the card data first. The note is appended to the card's existing notes.
 * @param {string} shopId - The unique identifier of the shop.
 * @param {string} note - The content of the note to be added.
 * @param {string} cardId - The unique identifier of the card to which the note will be added.
 * @param {Card} [cardData] - Optional. The card data object. If not provided, it will be fetched.
 * @returns {Promise<void>} - A promise that resolves when the note has been added.
 */
export async function addNote(
  shopId: string,
  note: string,
  cardId: string,
  cardData?: Card
) {
  const uid = auth.currentUser?.uid;
  if (!uid) return;
  if (!cardData) {
    cardData = await card.get(shopId, cardId);
  }
  if (!cardData) return;
  const notes = cardData.notes ?? [];
  notes.push({
    id: shortUUID().generate().toString(),
    text: note,
    createdAt: new Date(),
    createdBy: uid,
    updatedAt: new Date(),
    updatedBy: uid,
  });
  return card.update(shopId, cardId, { notes: notes });
}

/**
 * Deletes a note from a card in a shop.
 * @param {string} shopId - The unique identifier of the shop.
 * @param {string} noteId - The unique identifier of the note to be deleted.
 * @param {string} cardId - The unique identifier of the card containing the note.
 * @param {Card} [cardData] - Optional card data, if already available.
 * @returns {Promise<void>} A promise indicating the completion of the note deletion.
 */
export async function deleteNote(
  shopId: string,
  noteId: string,
  cardId: string,
  cardData?: Card
) {
  if (!cardData) {
    cardData = await card.get(shopId, cardId);
  }
  if (!cardData) return;
  const notes = cardData.notes ?? [];
  const newNotes = notes.filter((note) => note.id !== noteId);
  return card.update(shopId, cardId, { notes: newNotes });
}

/**
 * Updates an existing note associated with a specific card in a specific shop.
 * @param {string} shopId - The unique identifier of the shop.
 * @param {string} noteId - The unique identifier of the note to be updated.
 * @param {string} note - The new text/content of the note.
 * @param {string} cardId - The unique identifier of the card associated with the note.
 * @param {Card} [cardData] - Optional card data to be used for the update. If not provided, it will be fetched.
 * @returns {Promise<void>} A promise that resolves when the note is successfully updated.
 */
export async function updateNote(
  shopId: string,
  noteId: string,
  note: string,
  cardId: string,
  cardData?: Card
) {
  const uid = auth.currentUser?.uid;
  if (!uid) return;
  if (!cardData) {
    cardData = await card.get(shopId, cardId);
  }
  if (!cardData) return;
  const notes = cardData.notes ?? [];
  const updatedNotes = notes.map((n) =>
    n.id === noteId
      ? {
          ...n,
          text: note,
          updatedAt: new Date(),
          updatedBy: uid,
        }
      : n
  );
  return card.update(shopId, cardId, { notes: updatedNotes });
}

/**
 * Formats technician names based on the provided technician IDs.
 * @param {string | number | string[]} technicianIds - The ID or IDs of the technicians whose names need to be formatted.
 * @param {Array<Employee | EmployeeV1>} employees - The list of employee objects where each object can be of type Employee or EmployeeV1.
 * @returns {string} - A formatted string with the technician names.
 */
export function formatTechnicianNames(
  technicianIds: string | number | string[],
  employees: (Employee | EmployeeV1)[]
) {
  const linkedTechnicianType = typeof technicianIds;
  if (linkedTechnicianType === "string") {
    const employee = employees?.find((emp) => `${emp.id}` === technicianIds);
    if (!employee) return "no valid technician";
    return `${employee?.firstName} ${employee?.lastName}`;
  }
  if (linkedTechnicianType === "number") {
    const employee = employees?.find((emp) => emp.id === technicianIds);
    return `${employee?.firstName} ${employee?.lastName}`;
  }

  return formatArrayAsString(
    employees
      ?.filter((emp) => (technicianIds as string[]).includes(`${emp.id}`))
      .map((emp) => `${emp?.firstName} ${emp?.lastName}`)
  );
}

/**
 * Cleanses the input by converting `undefined` or `null` values to an empty string.
 * For non-null/undefined input, it returns the input converted to a string.
 * @param {string | number | null | undefined} input - The input value to cleanse.
 * @returns {string} - The cleansed string.
 */
export function cleanseUndefined(input: string | number | null | undefined) {
  if (!input) return "";
  return `${input}`;
}

/**
 * Retrieves the label for a specified card field section from the shop's card configuration.
 * @param {Shop} shop - The shop object containing card configuration.
 * @param {CardFieldKeys} section - The section of the card field to get the label for.
 * @returns {string} - The label of the card field section.
 */
export function getCardFieldLabel(shop: Shop, section: CardFieldKeys) {
  return shop.cardConfig.cardContents[section];
}

/**
 * Retrieves a specific section of a card based on the given parameters.
 * @param {Card | ActivityFeed} card - The card or activity feed object from which to extract the section.
 * @param {Shop} shop - The shop configuration containing card content settings.
 * @param {CardFieldKeys} section - The specific section key to retrieve from the card.
 * @returns {string | undefined} The content of the specified card section, or undefined if the section is not found.
 */
export function getCardSection(
  card: Card | ActivityFeed,
  shop: Shop,
  section: CardFieldKeys
) {
  const field = shop.cardConfig.cardContents[section];

  if (card.source === "manual") {
    return card[section];
  }
  switch (field) {
    case "Customer Last Name":
      return cleanseUndefined(card.customer.lastName);
    case "Key Tag":
      return cleanseUndefined(card.keyTag);
    case "Customer Name":
      return `${cleanseUndefined(card.customer.firstName)} ${cleanseUndefined(card.customer.lastName)}`;
    case "Year, Make, Model of vehicle":
      return `${cleanseUndefined(card.vehicle.year)} ${cleanseUndefined(card.vehicle.make)} ${cleanseUndefined(card.vehicle.model)}`;
    case "YY Model":
      return `${cleanseUndefined(card.vehicle.year).slice(-2)} ${cleanseUndefined(card.vehicle.model)}`;
    case "RO Number":
      return `${cleanseUndefined(card.cardNumber)}`;
    case "RO Number - Tag":
      return card.keyTag
        ? `${cleanseUndefined(card.cardNumber)} - ${cleanseUndefined(card.keyTag)}`
        : `${cleanseUndefined(card.cardNumber)}`;
    default:
      return;
  }
}
