import { Employee, EmployeeRole } from "@/types/v2";
import { useStore } from "@/stores/useStore";
import { DataTable } from "@/components/Base/Datatable";
import {
  DatatableEmployee,
  employeeColumns,
} from "@/components/Employees/Employees.columns";
import { employee as employeeActions } from "@/models/employee";
import { user as userActions } from "@/models/user";
import { useEffect, useState } from "react";
import { Skeleton } from "@/components/Base/Skeleton";
import { captureException } from "@/services/sentry";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Base/Tooltip";
import { Button } from "@/components/Base/Button";
import { PencilIcon, TrashIcon } from "lucide-react";
import { DeleteEmployeeDialog } from "@/components/Employees/DeleteEmployee.dialog";
import { EmployeeDialog } from "@/components/Employees/Employee.dialog";

export const EmployeesTable = () => {
  const employees = useStore((s) => s.employees);
  const shopId = useStore((s) => s.shopId);
  const [data, setData] = useState<DatatableEmployee[]>([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogAction, setDialogAction] = useState<"update" | "delete">(
    "delete"
  );
  const [activeEmployee, setActiveEmployee] = useState<Employee>();
  const [employeeDialogOpen, setEmployeeDialogOpen] = useState(false);

  useEffect(() => {
    if (employees) {
      Promise.all(
        employees.map(async (e): Promise<DatatableEmployee> => {
          const authUser = await userActions
            .getId(e.email)
            .catch(() => undefined);
          return {
            ...e,
            authAccountStatus: !authUser
              ? "doesNotExist"
              : authUser.data.disabled
                ? "inactive"
                : "active",
          };
        })
      )
        .then((res) => setData(res))
        .finally(() => setLoading(false));
    }
  }, [employees, shopId]);

  const actions = (employee: Employee) => {
    return [
      (employee._creationSource === "client" || !employee._creationSource) && (
        <TooltipProvider key={"update"}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleEdit(employee)}
                variant="ghost"
                id="edit_employee"
              >
                <PencilIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>Update this team member.</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
      (employee._creationSource === "client" || !employee._creationSource) && (
        <TooltipProvider key={"delete"}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                onClick={() => handleDelete(employee)}
                variant="ghost"
                id="delete_employee"
              >
                <TrashIcon />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              Delete this team member. Note this cannot be undone
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ),
    ];
  };

  const handleDelete = (employee: Employee) => {
    setDialogAction("delete");
    setActiveEmployee(employee);
    setEmployeeDialogOpen(true);
  };

  const handleEdit = (employee: Employee) => {
    setDialogAction("update");
    setActiveEmployee(employee);
    setEmployeeDialogOpen(true);
  };

  if (!data || !shopId || loading) {
    return (
      <div className="flex flex-col gap-4">
        <Skeleton className="h-12 w-24 self-end" />
        <Skeleton className="h-24 w-full" />
        <div className="flex flex-row justify-end gap-4">
          <Skeleton className="h-12 w-24" />
          <Skeleton className="h-12 w-24" />
        </div>
      </div>
    );
  }

  const onVisibilityChange = (id: string, visible: boolean) => {
    return employeeActions.toggleVisibility(shopId, id, visible);
  };

  const onActivateAccount = async (id: string) => {
    const employee = data.find((e) => e.id === id);
    if (!employee) return;
    setIsSubmitting(true);
    try {
      const authUser = await userActions.createAuthAccount(
        employee.email,
        employee.firstName,
        employee.lastName
      );

      await employeeActions.updateEmployeeRole(shopId, id, "viewOnly");

      setData((prevState) =>
        prevState.map((e) =>
          e.id === id && authUser.data.email === employee.email
            ? { ...e, authAccountStatus: "active" }
            : e
        )
      );

      setIsSubmitting(false);
    } catch (e) {
      captureException(e);
      setIsSubmitting(false);
    }
  };

  const onDeactivateAccount = async (id: string) => {
    const employee = data.find((e) => e.id === id);
    if (!employee) return;
    setIsSubmitting(true);
    try {
      await employeeActions.updateEmployeeRole(shopId, id);

      const authUser = await userActions.deactivateAuthAccount(employee.email);

      setData((prevState) =>
        prevState.map((e) =>
          e.id === id && authUser.data.email === employee.email
            ? { ...e, authAccountStatus: "inactive" }
            : e
        )
      );

      setIsSubmitting(false);
    } catch (e) {
      captureException(e);
      setIsSubmitting(false);
    }
  };

  const onRoleChange = async (id: string, role: EmployeeRole) => {
    setIsSubmitting(true);
    return employeeActions
      .updateEmployeeRole(shopId, id, role)
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      {activeEmployee && dialogAction === "delete" && (
        <DeleteEmployeeDialog
          shopId={shopId}
          employee={activeEmployee}
          open={employeeDialogOpen}
          setOpen={setEmployeeDialogOpen}
        />
      )}
      {activeEmployee && dialogAction === "update" && (
        <EmployeeDialog
          shopId={shopId}
          open={employeeDialogOpen}
          setOpen={setEmployeeDialogOpen}
          initialData={activeEmployee}
          title={"Edit Team Member"}
          description={
            "Change the team member's information below to update what is shown in Shop Hero"
          }
          submitTitle={"Save"}
          cancelTitle={"Cancel"}
        />
      )}
      <DataTable
        data={data}
        columns={employeeColumns({
          actions: actions,
          onVisibilityChange,
          loading: isSubmitting,
          onActivateAccount,
          onDeactivateAccount,
          onRoleChange,
        })}
      />
    </>
  );
};
