import { AppAnalytics, AppAnalyticsDoc } from "@/types/v2/AppAnalytics";
import { generic } from "@/models/generic";
import shortUUID from "short-uuid";
import { KEYS, localStorageManager } from "@/services/localStorage";
import { config } from "@/config";
import { auth } from "@/services/auth";
import { format } from "date-fns";

export const AppAnalyticsRepository = {
  currentId: () => format(new Date(), "yyyyMMdd"),
  path: (shopId: string) => `shops/${shopId}/appAnalytics`,
  getAnalytics: (shopId: string) => {
    const analyticId = AppAnalyticsRepository.currentId();
    return generic.get<AppAnalyticsDoc>(
      analyticId,
      AppAnalyticsRepository.path(shopId)
    );
  },
  createAnalytics: (shopId: string, analytics: AppAnalytics) => {
    const analyticId = AppAnalyticsRepository.currentId();
    return generic.create<AppAnalyticsDoc>(
      { id: analyticId, appAnalytics: [analytics] },
      `${AppAnalyticsRepository.path(shopId)}/${AppAnalyticsRepository.currentId()}`
    );
  },
  updateAnalytics: (shopId: string, analytics: AppAnalytics[]) => {
    return generic.update<AppAnalyticsDoc>(
      { appAnalytics: analytics },
      `${AppAnalyticsRepository.path(shopId)}/${AppAnalyticsRepository.currentId()}`
    );
  },
  pushAnalytics: async (shopId: string, analytics: AppAnalytics) => {
    const analyticData = await AppAnalyticsRepository.getAnalytics(shopId);
    if (!analyticData) {
      return AppAnalyticsRepository.createAnalytics(shopId, analytics);
    }
    analyticData.appAnalytics.push(analytics);
    return AppAnalyticsRepository.updateAnalytics(
      shopId,
      analyticData.appAnalytics
    );
  },
  generateBrowserId: () => {
    const browserId = shortUUID.generate();
    localStorageManager.setItem(KEYS.BROWSER_ID, browserId);
    return browserId;
  },
  getBrowserId: () => {
    const browserId = localStorageManager.getItem(KEYS.BROWSER_ID);
    if (!browserId) {
      return AppAnalyticsRepository.generateBrowserId();
    }
    return browserId;
  },
  generateCurrentAnalytics: (
    numberSubscribedDocuments: number,
    subscribedDocuments: { [x: string]: number }
  ): AppAnalytics => {
    return {
      browserId: AppAnalyticsRepository.getBrowserId(),
      createdAt: new Date(),
      appVersion: `${config.version}-${config.build}-${config.commitHash}`,
      numSubscribedDocuments: numberSubscribedDocuments,
      subscribedDocuments,
      uid: auth.currentUser?.uid ?? "",
    };
  },
  logAnalytics: async (
    shopId: string,
    numberSubscribedDocuments: number,
    subscribedDocuments: { [x: string]: number }
  ) => {
    const analytics = AppAnalyticsRepository.generateCurrentAnalytics(
      numberSubscribedDocuments,
      subscribedDocuments
    );
    await AppAnalyticsRepository.pushAnalytics(shopId, analytics);
    localStorageManager.setItem(KEYS.ANALYTICS_TIMESTAMP, new Date().getTime());
    return;
  },
};
