import * as Yup from "yup";
import { Formik } from "formik";
import { Button } from "@/components/Base/Button";
import { FormikInput } from "@/components/Common/form/FormikInput";
import { CreateEmployeeDto, Employee } from "@/types/v2";

export type EmployeeFormProps = {
  onSubmit?: (values: CreateEmployeeDto) => Promise<void> | void;
  onCancel?: () => void;
  initialData?: Employee;
  submitTitle?: string;
  cancelTitle?: string;
};

export const EmployeeForm = ({
  onSubmit,
  onCancel,
  initialData,
  cancelTitle,
  submitTitle,
}: EmployeeFormProps) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Enter a valid email")
      .required("Email is required"),
  });

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <div className="flex max-h-96 w-full flex-col gap-2">
      <Formik
        initialValues={{
          firstName: initialData?.firstName ?? "",
          lastName: initialData?.lastName ?? "",
          email: initialData?.email ?? "",
          schemaVersion: 2,
          sourceLastUpdated: new Date(),
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (onSubmit) {
            return onSubmit(values);
          }
        }}
      >
        {({ handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <FormikInput
                name={"firstName"}
                type={"text"}
                label={"First Name"}
                placeholder={"First Name"}
              />
              <FormikInput
                name={"lastName"}
                type={"text"}
                label={"Last Name"}
                placeholder={"Last Name"}
              />
              <FormikInput
                name={"email"}
                type={"text"}
                label={"Email"}
                placeholder={"Email"}
              />
            </div>

            <div className="mt-5 flex flex-row justify-end">
              <Button
                type="button"
                className="mr-5 mt-5"
                variant={"secondary"}
                onClick={handleCancel}
              >
                {cancelTitle ?? "Cancel"}
              </Button>
              <Button
                type="submit"
                className="mt-5"
                variant={"accent"}
                disabled={!isValid}
              >
                {submitTitle ?? "Create"}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
