import { Button } from "@/components/Base/Button";
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated";
import { shop } from "@/models/shop";

const DebugPage = () => {
  useIsAuthenticated();
  const handleListShops = () => {
    shop.listShops().then((res) => console.log(res));
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold">Debug Page</h1>
      <div className="flex flex-wrap gap-2">
        <Button onClick={handleListShops} variant="secondary">
          List Shops
        </Button>
        <Button onClick={handleListShops} variant="secondary">
          List Shops for Current Uer
        </Button>
      </div>
    </div>
  );
};

export default DebugPage;
