import {
  CardMenu,
  CardMenuProps,
} from "@/components/Dashboard/components/Card/Card.Menu";
import { CardTechSALabel, CardTechSALabelProps } from "./Card.TechSALabel";

export interface CardSidebarProps extends CardMenuProps, CardTechSALabelProps {}

export const CardSidebar = ({
  cardData,
  shopId,
  employees,
  disabled,
}: CardSidebarProps) => {
  return (
    <div className="absolute right-0.5 top-0.5 flex flex-col items-center justify-start">
      <CardTechSALabel cardData={cardData} employees={employees} />
      <CardMenu
        cardId={cardData.id}
        shopId={shopId}
        cardData={cardData}
        disabled={disabled}
      />
    </div>
  );
};
