import { Container, Employee } from "@/types/v2";
import { Button } from "@/components/Base/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Base/Select";
import { PlusIcon, X } from "lucide-react";
import { Label } from "@/components/Base/Label";
import { Tooltip } from "@/components/Common/Tooltip";
import { getEmployeeName } from "@/lib/employees";
import { Spinner } from "@/components/Common/Spinner";
import { z, ZodObject, ZodRawShape } from "zod";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Base/Form";
import { Input } from "@/components/Base/Input";

export interface ContainerFormProps<T> {
  containerData?: T;
  onSubmit: (container: T) => Promise<void>;
  onCancel?: () => void;
  employees?: Employee[];
  labels?: string[];
  loading?: boolean;
  containers?: Container[];
}

const shape: ZodRawShape = {};

export type ContainerFormValues = Pick<Container, "displayName"> & {
  id?: string;
  linkedTechnicianId?: string[];
  linkedSourceLabel?: string[];
};

const defaultValues: ContainerFormValues = {
  displayName: "",
  linkedSourceLabel: [],
  linkedTechnicianId: [],
};

export const ContainerForm = <T extends ContainerFormValues>({
  containerData,
  onSubmit,
  onCancel,
  employees,
  labels,
  loading,
  containers,
}: ContainerFormProps<T>) => {
  const formDefaultValues = {
    ...defaultValues,
    ...(containerData as T),
    displayName: containerData?.displayName ?? containerData?.id ?? "",
  };

  const existingContainers = containers?.map((c) =>
    (c.displayName ?? c.id).toLowerCase()
  );

  const ContainerSchema: ZodObject<typeof shape> = z.object({
    id: z.string().optional(),
    displayName: z
      .string()
      .min(1, { message: "A name for the container is required" })
      .refine(
        (name) => {
          // If editing existing container with same name, allow it
          if (containerData?.displayName?.toLowerCase() === name) {
            return true;
          }
          // Otherwise check if name already exists
          return !existingContainers?.includes(name.toLowerCase());
        },
        {
          message:
            "This container name already exists. Please choose a different name.",
        }
      ),
    linkedSourceLabel: z.array(z.string()),
    linkedTechnicianId: z.array(z.string()),
    ...shape,
  });

  const { control, handleSubmit, watch, formState, ...form } = useForm<
    z.infer<typeof ContainerSchema>
  >({
    resolver: zodResolver(ContainerSchema),
    defaultValues: formDefaultValues,
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "linkedTechnicianId",
  });

  // Watch the current values to disable the add button when needed
  const watchedTechnicianIds = watch("linkedTechnicianId") as string[];
  const hasEmptySelection = watchedTechnicianIds?.some((id) => id === "");

  const {
    fields: linkedSourceLabelField,
    append: linkedSourceLabelAppend,
    remove: linkedSourceLabelRemove,
    update: linkedSourceLabelUpdate,
  } = useFieldArray({
    control,
    name: "linkedSourceLabel",
  });

  // Watch the current values to disable the add button when needed
  const watchedSourceLabels = watch("linkedSourceLabel") as string[];
  const hasEmptySourceLabelSelection = watchedSourceLabels?.some(
    (id) => id === ""
  );

  const handleCancelForm = () => {
    if (onCancel) onCancel();
  };

  const handlesSubmitForm = async (values: T) => {
    if (onSubmit)
      return onSubmit({
        ...values,
        linkedTechnicianId: values.linkedTechnicianId?.filter(
          (id) => id !== ""
        ),
        linkedSourceLabel: values.linkedSourceLabel?.filter(
          (label) => label !== ""
        ),
      });
  };

  return (
    <Form
      control={control}
      watch={watch}
      formState={formState}
      handleSubmit={handleSubmit}
      {...form}
    >
      <form
        onSubmit={handleSubmit((data) => handlesSubmitForm(data as T))}
        onReset={handleCancelForm}
        className={"space-y-4"}
      >
        <FormField
          render={({ field }) => (
            <FormItem>
              <FormLabel>Container Name</FormLabel>
              <FormControl>
                <Input
                  disabled={formState.isSubmitting}
                  placeholder="Enter a new container"
                  {...field}
                  className="text-primary"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
          name={"displayName"}
          control={control}
        />
        <div className={"grid grid-cols-2 gap-4"}>
          {employees && (
            <div className="flex flex-col gap-2">
              <Label>Linked Technicians</Label>
              {fields.map((field, index) => (
                <div
                  className="group flex flex-row items-center justify-between gap-4 rounded p-2 hover:bg-accent/20"
                  key={field.id}
                >
                  <Select
                    onValueChange={(value) => update(index, value)}
                    value={watchedTechnicianIds[index] || ""}
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Pick a Technician" />
                    </SelectTrigger>
                    <SelectContent>
                      {employees
                        .filter((emp) => !emp.hideInShopHero)
                        .map((emp) => (
                          <SelectItem key={emp.id} value={`${emp.id}`}>
                            {emp.firstName} {emp.lastName}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <Tooltip
                    message={`Remove ${getEmployeeName(watchedTechnicianIds[index], employees)}`}
                  >
                    <Button
                      size={"smIcon"}
                      onClick={() => remove(index)}
                      type={"button"}
                    >
                      <X />
                    </Button>
                  </Tooltip>
                </div>
              ))}
              <div className="flex w-full flex-row items-center gap-4">
                <Button
                  size={"smIcon"}
                  onClick={() => append("")}
                  type={"button"}
                  disabled={hasEmptySelection}
                >
                  <PlusIcon />
                </Button>
                <span>
                  Click to add {fields.length > 0 ? "another" : "a"} technician
                </span>
              </div>
            </div>
          )}
          {labels && (
            <div className="flex flex-col gap-2">
              <Label>Linked Source Labels</Label>
              {linkedSourceLabelField.map((field, index) => (
                <div
                  className="group flex flex-row items-center justify-between gap-4 rounded p-2 hover:bg-accent/20"
                  key={field.id}
                >
                  <Select
                    onValueChange={(value) =>
                      linkedSourceLabelUpdate(index, value)
                    }
                    value={watchedSourceLabels[index] || ""}
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Pick a Label" />
                    </SelectTrigger>
                    <SelectContent>
                      {labels.map((label) => (
                        <SelectItem key={label} value={`${label}`}>
                          {label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Tooltip message={`Remove ${watchedSourceLabels[index]}`}>
                    <Button
                      size={"smIcon"}
                      onClick={() => linkedSourceLabelRemove(index)}
                      type={"button"}
                    >
                      <X />
                    </Button>
                  </Tooltip>
                </div>
              ))}
              <div className="flex w-full flex-row items-center gap-4">
                <Button
                  size={"smIcon"}
                  onClick={() => linkedSourceLabelAppend("")}
                  type={"button"}
                  disabled={hasEmptySourceLabelSelection}
                >
                  <PlusIcon />
                </Button>
                <span>
                  Click to add{" "}
                  {linkedSourceLabelField.length > 0 ? "another" : "a"} label
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row justify-end gap-4">
          <Button type="reset" variant="destructive" disabled={loading}>
            Cancel
          </Button>
          <Button type="submit" disabled={loading}>
            {loading ? <Spinner size={"sm"} /> : "Submit"}
          </Button>
        </div>
      </form>
    </Form>
  );
};
