import { ActivityFeed } from "@/components/ActivityFeed/ActivityFeed";

export const ActivityFeedPage = () => {
  return (
    <div className="mx-auto mb-20 flex h-full max-w-screen-lg flex-col items-center overflow-hidden p-5 text-justify">
      <h1 className="text-3xl font-bold">Activity Feed</h1>
      <ActivityFeed />
    </div>
  );
};
