import { ColumnDef } from "@tanstack/react-table";
import { Fragment, ReactNode } from "react";
import { Employee, Layouts, DashboardLayout } from "@/types/v2";
import { TableHeader } from "@/components/Base/Table";

export type DatatableLayout = DashboardLayout & {};

interface ColumnProps {
  actions: (layout: DashboardLayout) => ReactNode[];
  loading?: boolean;
  employees?: Employee[];
  layouts?: Layouts;
}

const createActionsColumn = ({
  actions,
}: ColumnProps): ColumnDef<DatatableLayout> => ({
  accessorKey: "actions",
  header: "",
  cell: ({ row }) => {
    const item = row.original;
    const id = Object.keys(item)[0];
    return (
      <div className="flex gap-2">
        {actions(item).map((action, index) => (
          <Fragment key={`action-${id}-${index}`}>{action}</Fragment>
        ))}
      </div>
    );
  },
});

const layoutColumns = (props?: ColumnProps) => {
  const col: ColumnDef<DatatableLayout>[] = [
    {
      id: "name",
      accessorFn: (originalRow) => Object.keys(originalRow)[0],
      header: () => <TableHeader>Layout Name</TableHeader>,
      cell: ({ row }) => {
        const item = row.original;
        return <div>{Object.keys(item)[0]}</div>;
      },
    },
  ];

  if (props) {
    col.push(createActionsColumn(props));
  }
  return col;
};

export { layoutColumns };
