import { cn, formatArrayAsString } from "@/lib/utils";
import { CSSProperties, PropsWithChildren, forwardRef, useMemo } from "react";
import { ClassNameValue } from "tailwind-merge";
import { ContainerMenu } from "./ContainerMenu";
import { useStore } from "@/stores/useStore";
import { formatLabels } from "@/lib/cards";

export interface LayoutContainerProps extends PropsWithChildren {
  removeItem: (id: string) => void;
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
  style?: CSSProperties;
  className?: ClassNameValue;
}

const LayoutContainer = forwardRef<HTMLDivElement, LayoutContainerProps>(
  (
    { removeItem, style, className, children, id, x, y, w, h, ...restOfProps },
    ref
  ) => {
    const containers = useStore((s) => s.containers);
    const employees = useStore((s) => s.employees);

    const containerName = useMemo(
      () => containers.find((c) => c.id === id)?.displayName ?? id,
      [id, containers]
    );

    const technicianId = useMemo(
      () => containers.find((c) => c.id === id)?.linkedTechnicianId,
      [id, containers]
    );

    const sourceLabel = useMemo(
      () => containers.find((c) => c.id === id)?.linkedSourceLabel,
      [id, containers]
    );

    const technicians = useMemo(() => {
      if (
        typeof technicianId === "string" ||
        typeof technicianId === "number"
      ) {
        return employees?.filter((emp) => `${emp.id}` === technicianId);
      }
      return employees?.filter((emp) => technicianId?.includes(`${emp.id}`));
    }, [employees, technicianId]);

    return (
      <div
        style={{ ...style, backgroundColor: "#BFCED8" }}
        className={cn(className)}
        ref={ref}
        {...restOfProps}
      >
        <ContainerMenu
          containerId={id}
          containerName={containerName}
          removeItem={removeItem}
          technicianId={technicianId ? `${technicianId}` : undefined}
          sourceLabel={sourceLabel}
          removeOnly={id === "dispatch"}
        />
        <div>{containerName}</div>
        {technicians && technicians.length > 0 && (
          <div>
            {`Technician${technicians.length > 1 ? "s" : ""}: `}
            {formatArrayAsString(
              technicians.map(
                (technician) =>
                  `${technician?.firstName} ${technician.lastName}`
              )
            )}
          </div>
        )}
        {sourceLabel && sourceLabel.length > 0 && (
          <div>{`Label${
            typeof sourceLabel === "string"
              ? ""
              : sourceLabel.length > 1
                ? "s"
                : ""
          }: ${formatLabels(sourceLabel)}`}</div>
        )}
        {children}
      </div>
    );
  }
);

LayoutContainer.displayName = "LayoutContainer";

export default LayoutContainer;
