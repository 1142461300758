import { ActivityFeed, Shop } from "@/types/v2";
import { getCardFieldLabel, getCardSection } from "@/lib/cards";
import { firestoreToDateTime } from "@/lib/utils";
import { format } from "date-fns";
import { useUserQuery } from "@/hooks/queries/useUserQuery";
import { Spinner } from "@/components/Common/Spinner";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/Base/Avatar";
import { getUsersInitials } from "@/lib/users";
import { getIntegratorImage } from "@/lib/integrators";

export const ActivityFeedItem = ({
  item,
  shop,
}: {
  item: ActivityFeed;
  shop: Shop;
}) => {
  const createdAt = firestoreToDateTime(item.createdAt);
  const { data, isLoading } = useUserQuery(item.modifiedBy);

  const getIntegratorAvatar = () => {
    return getIntegratorImage(shop.integration ?? "manual");
  };

  return (
    <div className="rounded-md border bg-secondary p-2 text-primary">
      <div className="flex justify-start gap-4">
        <Avatar>
          {!isLoading && !data?.displayName && (
            <AvatarImage src={getIntegratorAvatar()} />
          )}
          <AvatarFallback className="bg-primary text-primary-foreground">
            {getUsersInitials(data?.displayName ?? shop.integration)}
          </AvatarFallback>
        </Avatar>
        <div className="text-left">
          {getCardFieldLabel(shop, "primaryField")}{" "}
          <span className="font-bold">
            {getCardSection(item, shop, "primaryField")}
          </span>
          {item.fromContainerName && item.toContainerName && (
            <>
              {" "}
              moved from{" "}
              <span className="font-bold">
                {item.fromContainerName}
              </span> to{" "}
              <span className="font-bold">{item.toContainerName}</span> by{" "}
            </>
          )}
          {!item.fromContainerName && item.toContainerName && (
            <>
              {" "}
              <span className="font-bold">Added</span> to board and{" "}
              <span className="font-bold">{item.toContainerName}</span> by{" "}
            </>
          )}
          {item.fromContainerName && !item.toContainerName && (
            <>
              {" "}
              <span className="font-bold">Removed</span> from board and{" "}
              <span className="font-bold">{item.fromContainerName}</span> by{" "}
            </>
          )}
          {isLoading ? (
            <span>
              <Spinner />
            </span>
          ) : data ? (
            data.displayName
          ) : item.modifiedBy === "system" ? (
            <span className="font-bold text-orange-500">Integration</span>
          ) : (
            <span>{item.modifiedBy}</span>
          )}
        </div>
        <div className="flex-grow" />
        <div className="w-24 text-right text-accent">
          {createdAt ? format(createdAt, "h:mm b") : ""}
        </div>
      </div>
    </div>
  );
};
