import { useState } from "react";
import { Button } from "../Base/Button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../Base/Dialog";
import { container } from "@/models/container";
import { useStore } from "@/stores/useStore";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../Base/Select";
import { PlusIcon, X } from "lucide-react";

interface LinkTechnicianDialogProps {
  containerId: string;
  technicianId?: string | string[];
  onClose: () => void;
}
export const LinkTechnicianDialog = ({
  containerId,
  technicianId,
  onClose,
}: LinkTechnicianDialogProps) => {
  const employees = useStore((s) => s.employees);
  const [open, setOpen] = useState(false);
  const [newTechnicianId, setNewTechnicianId] = useState(
    typeof technicianId === "string"
      ? [technicianId]
      : technicianId
        ? technicianId
        : []
  );

  const handleSave = () => {
    if (!newTechnicianId || newTechnicianId.length === 0) {
      container.deleteContainerTechnician(containerId);
    } else {
      container.updateContainerTechnician(containerId, newTechnicianId);
    }
    setOpen(false);
    if (onClose) onClose();
  };

  const handleCancel = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSelectTechnician = (technicianId: string, index: number) => {
    const newTechnicianIds = [...newTechnicianId];
    newTechnicianIds[index] = technicianId;
    setNewTechnicianId(newTechnicianIds);
  };

  const handleRemoveTechnician = (index: number) => {
    const newTechnicianIds = [...newTechnicianId];
    newTechnicianIds.splice(index, 1);
    setNewTechnicianId(newTechnicianIds);
  };

  const handleAddTechnician = () => {
    setNewTechnicianId([...newTechnicianId, ""]);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger asChild>
        <Button onClick={() => setOpen(true)}>Link to Technician</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Container Technician</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div>Please provide a new name in the box below.</div>
          {employees && (
            <div className="flex flex-col gap-2">
              {newTechnicianId?.map((id, index) => (
                <div
                  className="flex flex-row justify-start gap-4"
                  key={`${id}-${index}`}
                >
                  <Select
                    onValueChange={(value) =>
                      handleSelectTechnician(value, index)
                    }
                    value={id ?? ""}
                  >
                    <SelectTrigger className="w-48">
                      <SelectValue placeholder="Pick a Technician" />
                    </SelectTrigger>
                    <SelectContent>
                      {employees
                        .filter((emp) => !emp.hideInShopHero)
                        .map((emp) => (
                          <SelectItem key={emp.id} value={`${emp.id}`}>
                            {emp.firstName} {emp.lastName}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                  <Button
                    size={"icon"}
                    onClick={() => handleRemoveTechnician(index)}
                  >
                    <X />
                  </Button>
                </div>
              ))}
            </div>
          )}
          <div className="flex w-full flex-row items-center gap-4">
            <Button size={"icon"} onClick={() => handleAddTechnician()}>
              <PlusIcon />
            </Button>
            <span>Click to add another technician</span>
          </div>
          <div className="text-xs">
            Note that container technician changes are global and are effective
            immediately. If you want a custom link to a technician for this
            layout only, create a new container.
          </div>
        </div>
        <DialogFooter>
          <Button variant={"secondary"} onClick={handleCancel}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
