import { NotesHeader } from "./Notes.Header";
import { NotesMain, NotesMainProps } from "./Notes.Main";

type NotesProps = NotesMainProps;
export const Notes = ({ ...props }: NotesProps) => {
  return (
    <div className="flex min-w-64 max-w-md flex-col">
      <NotesHeader />
      <NotesMain {...props} />
    </div>
  );
};
