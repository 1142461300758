import { EnableDisableSwitch } from "@/components/Common/EnableDisableSwitch";
import { Heading } from "@/components/Common/Heading";
import { config } from "@/config";
import { useIsSuperAdmin } from "@/hooks/useIsSuperAdmin";
import { arrayRemove, arrayUnion } from "@/lib/utils";
import {
  FEATURE_FLAG,
  FEATURE_FLAG_OPTIONS,
  featureFlags,
} from "@/models/featureFlags";
import { useStore } from "@/stores/useStore";
import { FeatureFlags } from "@/types/v1";
import { useEffect, useState } from "react";

export const ShopConfigPage = () => {
  const [initialized, isSuperAdmin] = useIsSuperAdmin();
  const [features, setFeatures] = useState<FeatureFlags>();
  const shopData = useStore((s) => s.shop);
  const user = useStore((s) => s.user);

  useEffect(() => {
    if (initialized && isSuperAdmin) {
      featureFlags.list().then((data) => setFeatures(data));
    }
  }, [initialized, isSuperAdmin]);

  if (!initialized) return <div>Loading...</div>;
  if (!isSuperAdmin) return <div>403</div>;

  const enabledForAllUsers = (ff: FEATURE_FLAG) => {
    if (!features) return false;
    if (!features[ff]) return false;
    return features[ff].allUsers;
  };

  const enabledForShop = (ff: FEATURE_FLAG) => {
    if (!features) return false;
    if (!features[ff]) return false;
    if (!shopData) return false;
    return features[ff].testUsers.includes(shopData.id);
  };

  const handleConfigSwitch = async (enabled: boolean, ff: FEATURE_FLAG) => {
    if (!shopData) return false;

    let testUsers = features ? features[ff].testUsers : [];

    if (enabled) {
      testUsers = arrayUnion(shopData.id, testUsers);
    } else {
      testUsers = arrayRemove(shopData.id, testUsers);
    }

    await featureFlags.update(ff, {
      testUsers: testUsers,
      allUsers: features ? features[ff].allUsers : false,
    });

    // Mutually Exclusive
    const mutuallyExclusiveWith =
      FEATURE_FLAG_OPTIONS[ff].mutuallyExclusiveWith;

    if (mutuallyExclusiveWith) {
      console.log(`mutuallyExclusiveWith ${mutuallyExclusiveWith}`);
      testUsers = features ? features[mutuallyExclusiveWith].testUsers : [];
      // Opposite for the mutually exclusive
      if (enabled) {
        testUsers = arrayRemove(shopData.id, testUsers);
      } else {
        testUsers = arrayUnion(shopData.id, testUsers);
      }
      await featureFlags.update(mutuallyExclusiveWith, {
        testUsers: testUsers,
        allUsers: features ? features[mutuallyExclusiveWith].allUsers : false,
      });
    }

    // Exclusive With
    const exclusiveWith = FEATURE_FLAG_OPTIONS[ff].exclusiveWith;

    if (exclusiveWith) {
      console.log(`exclusiveWith ${exclusiveWith}`);
      testUsers = features ? features[exclusiveWith].testUsers : [];
      // Opposite for the exclusive, but no need to enable if disabling this one
      if (enabled) {
        testUsers = arrayRemove(shopData.id, testUsers);
      }
      await featureFlags.update(exclusiveWith, {
        testUsers: testUsers,
        allUsers: features ? features[exclusiveWith].allUsers : false,
      });
    }

    featureFlags.list().then((data) => setFeatures(data));
  };

  if (!shopData || user?.role !== "superAdmin") return <div>Loading...</div>;

  return (
    <div className="p-5">
      <Heading>Shop {shopData.name} Config</Heading>
      <div className="flex max-w-screen-md flex-col items-center gap-4 rounded-xl border border-primary-foreground p-4">
        {Object.keys(config.availableFeatureFlags)
          .filter((ff) =>
            features ? Object.keys(features).includes(ff) : false
          )
          .map((ff, index) => (
            <EnableDisableSwitch
              key={`${ff}-${index}`}
              label={FEATURE_FLAG_OPTIONS[ff as FEATURE_FLAG].label}
              enabled={
                enabledForAllUsers(ff as keyof typeof features) ||
                enabledForShop(ff as keyof typeof features)
              }
              onChange={(enabled) =>
                handleConfigSwitch(enabled, ff as FEATURE_FLAG)
              }
              disabled={
                enabledForAllUsers(ff as keyof typeof features) ||
                !FEATURE_FLAG_OPTIONS[ff as FEATURE_FLAG].canEnablePerUser
              }
            />
          ))}
      </div>
    </div>
  );
};
