import { ContainersTable } from "@/components/Layout/Containers.table";
import { useStore } from "@/stores/useStore";
import { Container } from "@/types/v2";
import { Button } from "@/components/Base/Button";
import { Link, useParams } from "react-router-dom";
import { container as containerRepository } from "@/models/container";
import { ContainerDialog } from "@/components/Layout/ContainerDialog";
import { PlusIcon } from "lucide-react";

export const LayoutContainersPage = () => {
  const { shopId } = useParams();
  const containers = useStore((s) => s.shop?.containers ?? []);
  const employees = useStore((s) => s.employees ?? []);
  const labels = useStore((s) => s.shop?.customLabels ?? []);
  const layouts = useStore((s) => s.shop?.layouts ?? []);
  const cards = useStore((s) => s.cards);

  const handleCreate = async (container: Partial<Container>) => {
    if (!container.displayName) return;
    await containerRepository.createContainer(
      container.displayName,
      container.linkedTechnicianId,
      container.linkedSourceLabel
    );
  };

  const handleEdit = async (container: Container) => {
    await containerRepository.updateContainer(container.id, container);
  };

  const handleDelete = async (container: Container) => {
    if (!shopId) return;
    await containerRepository.deleteContainer(container.id);
  };

  return (
    <div className="overflow-auto p-4">
      <div className="flex flex-row items-center justify-between">
        <Button variant="secondary">
          <Link to={`/${shopId}/layoutBuilder`}>Layout Builder</Link>
        </Button>
        <ContainerDialog
          employees={employees}
          labels={labels}
          onSubmit={handleCreate}
          triggerIcon={<PlusIcon className={"mr-2"} />}
          triggerVariant={"accent"}
        />
      </div>
      <ContainersTable
        containers={containers}
        employees={employees}
        layouts={layouts}
        labels={labels}
        cards={cards}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    </div>
  );
};
