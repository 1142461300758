import { useStore } from "@/stores/useStore";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "@/components/Common/Spinner";
import { UsersProvider } from "@/providers/Users.provider";
import { MessageBoard } from "@/components/MessageBoard/MessageBoard";

export const MessageBoardPage = () => {
  const { conversations, shopId, user, shop } = useStore();
  const { conversationId } = useParams();
  const conversation = useMemo(
    () => conversations.find((c) => c.id === conversationId),
    [conversations, conversationId]
  );
  if (!conversation || !user || !shopId) {
    return <Spinner />;
  }

  return (
    <UsersProvider>
      <div className="m-4 mx-auto flex w-full max-w-screen-md flex-grow-0 flex-col gap-4 overflow-y-hidden bg-accent p-4">
        <h1 className="text-3xl">
          {shop ? `${shop.name}'s ` : ""}Message Board
        </h1>
        <MessageBoard conversation={conversation} shopId={shopId} />
      </div>
    </UsersProvider>
  );
};
